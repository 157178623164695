import React, { useEffect, useState } from "react";
import MyPayHeader from "../../../components/MyPayHeader";
import GrossProration from "../../../components/GrossProration";
import PaySlips from "../../../components/PaySlips";
import PayPackage from "../../../components/PayPackage";
import axios from "axios";
import config from "../../../config";

function MyPay() {
  const [activeHeader, setActiveHeader] = useState("payPackage");
  const [salary, setSalary] = useState("");
  const [salaryConfig, setSalaryConfig] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  console.log(" salary in Parent : ", salary);
  console.log(" salaryConfig in Parent : ", salaryConfig);

  const { company } = JSON.parse(localStorage.getItem("user"));
  const employeeId = localStorage.getItem("employeeID");

  useEffect(() => {
    axios
      .get(
        `${config.API_BASE_URL}employees/companies/${company.company_id}/employees/${employeeId}/salary-breakdown`
      )
      .then((response) => {
        console.log("response config ", response.data.config);
        setSalaryConfig(response.data.config);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="main-wrapper">
      <div className="page-wrapper">
        <div className="content container-fluid">
          <MyPayHeader
            activeHeader={activeHeader}
            setActiveHeader={setActiveHeader}
            setSalary={setSalary}
          />
          {activeHeader === "payPackage" && (
            <PayPackage
              setSalary={setSalary}
              setActiveHeader={setActiveHeader}
            />
          )}
          {activeHeader === "grossProration" && (
            <GrossProration salary={salary} salaryConfig={salaryConfig} />
          )}
          {activeHeader === "paySlips" && <PaySlips />}
        </div>
      </div>
    </div>
  );
}

export default MyPay;
