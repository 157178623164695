import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import axios from "axios";
import config from "../../../config";
import {
  validateEmail,
  validatePhoneNumber,
  validatorName,
  formatDate,
} from "../../../validation";
import { Link, useNavigate, useParams } from "react-router-dom";

const EditEmployee = () => {
  const { id } = useParams();
  console.log("id---->", id);

  //   useEffect(()=>{
  //      const fetchData = async() =>{
  //          const res = await axios.get(`${config.API_BASE_URL}employees/${id}`);
  //          console.log("fetchData response" , res);
  //      }
  //      fetchData();
  //   },[id]);
  const [fieldOne, setFieldOne] = useState(true);
  const [fieldTwo, setFieldTwo] = useState();
  const [fieldThree, setFieldThree] = useState();
  const [fieldFour, setFieldFour] = useState();
  const [fieldFive, setFieldFive] = useState();
  const navigate = useNavigate();

  const FieldsetTwo = () => {
    // if(employeeData.first_name && employeeData.last_name && employeeData.personal_email && employeeData.number && employeeData.gender && employeeData.guardian_name && employeeData.marital_status && employeeData.date_of_birth && employeeData.nationality ){
    //   setFieldTwo(true);
    //   setFieldOne(false);

    // }else{
    //   setFieldOne(true);
    //   setFieldTwo(false);
    // }

    setFieldTwo(true);
    setFieldOne(false);

    setFieldThree(false);
    setFieldFour(false);
    setFieldFive(false);
  };

  const FieldsetThree = () => {
    if (
      addressData.current_address &&
      addressData.current_city &&
      addressData.current_country &&
      addressData.state &&
      addressData.current_pincode &&
      addressData.permanent_address &&
      addressData.permanent_city &&
      addressData.permanent_country &&
      addressData.permanent_pincode &&
      addressData.permanent_state
    ) {
      setFieldThree(true);
      setFieldTwo(false);
    } else {
      setFieldThree(false);
      setFieldTwo(true);
    }
    setFieldOne(false);
    setFieldFour(false);
    setFieldFive(false);
  };
  const FieldsetFour = () => {
    if (qualifications.length > 0) {
      setFieldThree(false);
      setFieldFour(true);
    } else {
      setFieldThree(true);
      setFieldFour(false);
    }
    setFieldOne(false);
    setFieldTwo(false);

    setFieldFive(false);
  };
  const FieldsetFive = () => {
    setFieldOne(false);
    setFieldTwo(false);
    setFieldThree(false);
    setFieldFour(false);
    setFieldFive(true);
  };
  const countrylist = [
    { value: 1, label: "Select Country" },
    { value: 2, label: "India" },
    { value: 3, label: "China" },
    { value: 4, label: "America" },
    { value: 5, label: "Japan" },
  ];
  const gender = [
    { value: 1, label: "Select Gender" },
    { value: 2, label: "Male" },
    { value: 3, label: "Female" },
  ];
  const slot = [
    { value: 1, label: "Select slot" },
    { value: 2, label: "Morning Shift" },
    { value: 3, label: "Evening Shift" },
  ];
  const marital_status = [
    { value: 1, label: "Select status" },
    { value: 2, label: "Single" },
    { value: 3, label: "Married" },
    { value: 4, label: "Divorced" },
    { value: 5, label: "Unmarried" },
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const [selectedDate1, setSelectedDate1] = useState(null);
  const [slotShift, setSlotShift] = useState(null);
  const [departmentID, setDepartmentID] = useState(null);
  const [designationID, setDesignationID] = useState(null);
  const [genderHandler, setGenderHandler] = useState(null);
  const [selectedDob, setSelectedDob] = useState(null);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const [maritalStatus, setMaritalStatus] = useState(null);
  const [marriedDate, setMarrieddate] = useState(null);
  const [selectedCurrentCountry, setSelectedCurrentCountry] = useState(null);
  const [selectedPermanentCountry, setSelectedPermanentCountry] =
    useState(null);

  const handleDateChange1 = (date) => {
    setSelectedDate1(formatDate(date));
    console.log("selected Date", selectedDate1);
  };
  const [employeeData, setEmployeeData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    personal_email: "",
    number: "",
    alternate_number: "",
    guardian_name: "",
    date_of_birth: "",
    gender: "",
    photo: null,
    company_id: "",
    marital_status: "",
    married_date: "",
    spouse_name: "",
    nationality: "",
    aadhar: null,
    pan: null,
    experience_letter: null,
    resignation_letter: null,
  });

  // useEffect(()=>{
  //   designationHandler(departmentID)
  // });

  const designationHandler = (id) => {
    console.log("id ->", id);
    axios
      .get(`${config.API_BASE_URL}department/designation/${id}`, configure)
      .then((res) => {
        const designation = res.data.result.map((des) => ({
          value: des.id,
          label: des.designation_name,
        }));
        console.log("designation", designation);
        setDesignation(designation);
      })
      .catch((err) => console.log(err));
  };

  const inputHandleChnage = (e) => {
    const { name, value, files } = e.target;
    // console.log("name" , name , "--->" , "value ------>" , value , "filees---------->" ,files[0])
    // if (
    //   name === "first_name" ||
    //   name === "last_name" ||
    //   name === "father_name"
    // ) {
    //   if (!validatorName(name)) {
    //     console.log(name, "->", "name validation failed");
    //     return;
    //   }
    // }
    // if (name === "email") {
    //   if (!validateEmail(value)) {
    //     console.log("email validation failed...");
    //     return;
    //   }
    // }
    // if (name === "mobile_number") {
    //   if (/^[0-9]*$/.test(value)) {
    //     const newEmployeeData = {
    //       ...employeeData,
    //       [name]: files ? files[0] : value,
    //       gender: genderHandler,
    //       date_of_birth: selectedDob,
    //       company_id: 1,
    //       marital_status: maritalStatus,
    //       married_date: marriedDate ? marriedDate : "",
    //     };
    //     setEmployeeData(newEmployeeData);
    //   }
    // }
    const user = localStorage.getItem("user");
    const userID = JSON.parse(user);
    const company_id = userID.company.company_id;
    setEmployeeData({
      ...employeeData,
      [name]: files ? files[0] : value,
      gender: genderHandler,
      date_of_birth: selectedDob ? selectedDob : employeeData.date_of_birth,
      company_id: company_id,
      marital_status: maritalStatus,
      married_date: marriedDate ? marriedDate : "",
    });
  };
  let token = localStorage.getItem("token");
  const configuration = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  const configure = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    axios
      .get(`${config.API_BASE_URL}department`, configure)
      .then((res) => {
        const departments = res.data.result.map((dept) => ({
          value: dept.id,
          label: dept.department_name,
        }));
        setDepartment(departments);
      })
      .catch((err) => console.log(err));

    console.log(department);
  }, []);

  //---------------------- address feild feild-2  handling--------------------------------
  const [addressData, setAddressData] = useState({
    current_id: "",
    permanent_id: "",
    employee_id: "",
    current_address: "",
    current_city: "",
    current_state: "",
    current_country: null,
    current_pincode: "",
    permanent_address: "",
    permanent_city: "",
    permanent_state: "",
    permanent_country: null,
    permanent_pincode: "",
    isSameAddress: false,
  });
  const [editAddress, setEditAddress] = useState([]);

  const addressDataHandler = (e) => {
    const { name, value } = e.target;
    setAddressData({
      ...addressData,
      [name]: value,
      // current_country: selectedCurrentCountry,
      // permanent_country: selectedPermanentCountry,
    });
  };

  const handleCheckBoxChanged = (e) => {
    const { checked } = e.target;
    if (checked) {
      setAddressData((prevData) => ({
        ...prevData,
        isSameAddress: checked,
        permanent_address: prevData.current_address,
        permanent_city: prevData.current_city,
        permanent_state: prevData.current_state,
        permanent_country: prevData.current_country,
        permanent_pincode: prevData.current_pincode,
      }));
    } else {
      setAddressData((prevData) => ({
        ...prevData,
        isSameAddress: checked,
        permanent_address: "",
        permanent_city: "",
        permanent_state: "",
        permanent_country: "",
        permanent_pincode: "",
      }));
    }
  };

  // ------------------- Employees Qualification --------------------------
  const [qualifications, setQualification] = useState([
    {
      employee_id: "",
      qualification: "",
      university: "",
      passing_year: "",
      percentage: "",
      document: null,
    },
  ]);
  const handleAddQualification = () => {
    setQualification([
      ...qualifications,
      {
        employee_id: "",
        qualification: "",
        university: "",
        passing_year: "",
        percentage: "",
        document: null,
      },
    ]);
  };
  const handleRoveQualification = (index) => {
    if (qualifications.length > 1) {
      const newQualification = qualifications.filter((_, i) => i !== index);
      setQualification(newQualification);
    }
  };

  const handleChangeQualification = (index, field, value) => {
    const newQualification = [...qualifications];
    newQualification[index][field] = value;
    setQualification(newQualification);
  };
  const handleFileChange = (index, field, file) => {
    const newQualification = [...qualifications];
    newQualification[index][field] = file;
    setQualification(newQualification);
  };

  //------------------------ Employees Employment Details --------------------------------
  const [selectedEndingDate, setSelectedEndingDate] = useState(null);
  const [selectedJoiningDate, setSelectedJoiningDate] = useState(null);
  const [employmentData, setEmployementData] = useState({
    offical_email: "",
    experience: "",
    slot: "",
    joining_date: "",
    department_id: "",
    designation_id: "",
    salary: "",
    cv: null,
  });

  const employmentDetailsHandler = (e) => {
    const { name, value, files } = e.target;
    setEmployementData({
      ...employmentData,
      [name]: files ? files[0] : value,
      slot: slotShift,
      department_id: departmentID,
      designation_id: designationID,
      joining_date: selectedDate1 ? selectedDate1 : employmentData.joining_date,
    });
  };
  //------------------------ Employee Account Details  --------------------------------------

  const [accountDetails, seAccountDetails] = useState({
    account_number: "",
    ifsc_code: "",
    account_holder_name: "",
    branch_name: "",
    bank_name: "",
    uan_number: "",
    passbook_photo: null,
  });
  const accountDetailsHandler = (e) => {
    const { name, value, files } = e.target;
    seAccountDetails({ ...accountDetails, [name]: files ? files[0] : value });
  };

  // ------------------ sublit handling-------------------------------
  const handleSbubmiteInBasics = (e) => {
    // if (
    //   fieldOne &&
    //   employeeData.first_name &&
    //   employeeData.last_name &&
    //   employeeData.personal_email &&
    //   employeeData.number &&
    //   employeeData.gender &&
    //   employeeData.guardian_name &&
    //   employeeData.marital_status &&
    //   employeeData.date_of_birth &&
    //   employeeData.nationality
    // ) {
    //   console.log("handling basucs info");
    e.preventDefault();
    FieldsetTwo();
    // }
  };
  const handleSbubmiteInaddress = (e) => {
    // console.log("clicling...");
    // console.log(addressData.current_pincode);
    // if (addressData.current_pincode) {
    e.preventDefault();
    console.log("handling address");
    FieldsetThree();
    setFieldTwo(false);
    setFieldThree(true);
    // }
  };
  const handleSubmitInQualification = (e) => {
    // console.log("the length is ", qualifications.length);
    // if (
    //   qualifications[0].qualification &&
    //   qualifications[0].passing_year &&
    //   qualifications[0].percentage &&
    //   qualifications[0].university
    // ) {
    e.preventDefault();
    FieldsetFour();
    setFieldThree(false);
    setFieldFour(true);
    // }
  };

  const handleSubmitInEmployment = (e) => {
    // if (
    //   employmentData.department_id &&
    //   employmentData.designation_id &&
    //   employmentData.joining_date &&
    //   employmentData.experience
    // ) {
    e.preventDefault();
    FieldsetFive();
    // }
  };

  const handlerSubmitInAccount = async (e) => {
    // if (
    //   accountDetails.account_holder_name &&
    //   accountDetails.account_number &&
    //   accountDetails.bank_name &&
    //   accountDetails.ifsc_code &&
    //   accountDetails.branch_name
    // ) {
    e.preventDefault();
    try {
      console.log("Employee Basics Data", employeeData);
      const personalData = new FormData();
      // ----- personal information ----------------
      for (const key in employeeData) {
        personalData.append(key, employeeData[key]);
      }
      console.log("Employee Basics Data", employeeData);
      const { data } = await axios.put(
        `${config.API_BASE_URL}employees/${id}`,
        personalData,
        configuration
      );

      console.log("appointment data ----->", data);
      // -------------------Address data submit handling---------------

      // if (data.result.insertId) {
      //   const id = data.result.insertId;
      const {
        permanent_id,
        current_id,
        employee_id,
        current_address,
        current_city,
        current_country,
        current_state,
        current_pincode,
        permanent_address,
        permanent_city,
        permanent_country,
        permanent_pincode,
        permanent_state,
        isSameAddress,
      } = addressData;
      console.log(addressData, "addressData");

      const currentAddressData = {
        id: current_id,
        employee_id: id,
        address: current_address,
        city: current_city,
        country: current_country,
        state: current_state,
        pincode: current_pincode,
        type: "current_address",
      };
      const permanentAddressData = {
        id: permanent_id,
        employee_id: id,
        address: permanent_address,
        city: permanent_city,
        country: permanent_country,
        state: permanent_state,
        pincode: permanent_pincode,
        type: "permanent_address",
      };
      const addressDatares = await axios.put(
        `${config.API_BASE_URL}employees/address/${id}`,
        [currentAddressData, permanentAddressData],
        configure
      );
      console.log("our address data ->", addressDatares);
      // ----------------qualification data  submit handler -----------
      const qualicatonFormData = new FormData();
      qualifications.forEach((qual, index) => {
        qualicatonFormData.append(`qualifications[${index}][employee_id]`, id);
        qualicatonFormData.append(
          `qualifications[${index}][qualification]`,
          qual.qualification
        );
        qualicatonFormData.append(
          `qualifications[${index}][university]`,
          qual.university
        );
        qualicatonFormData.append(
          `qualifications[${index}][passing_year]`,
          qual.passing_year
        );
        qualicatonFormData.append(
          `qualifications[${index}][percentage]`,
          qual.percentage
        );
        if (qual.document) {
          qualicatonFormData.append(
            `qualifications[${index}][document]`,
            qual.document
          );
        }
      });
      const qualificationData = await axios.put(
        `${config.API_BASE_URL}employees/qualification/${id}`,
        qualicatonFormData,
        configuration
      );
      console.log("qualification Data->", qualificationData);
      //     // --------------- Employment Data -----------------

      const emplymentformData = new FormData();
      console.log("emplyment dat -->", employmentData);
      for (const key in employmentData) {
        emplymentformData.append(key, employmentData[key]);
      }
      emplymentformData.append("employee_id", id);
      const employmentDatares = await axios.put(
        `${config.API_BASE_URL}employees/employment/${id}`,
        emplymentformData,
        configuration
      );
      console.log("employmentvv Data ->", employmentDatares);

      //     //-------------------  Account Data -------------------------
      const formData = new FormData();
      console.log("account details --->", accountDetails);
      for (const key in accountDetails) {
        formData.append(key, accountDetails[key]);
      }
      formData.append("employee_id", id);
      const AccountData = await axios.put(
        `${config.API_BASE_URL}employees/account/${id}`,
        formData,
        configuration
      );
      console.log("AcountData->", AccountData);
      if (AccountData.data.success === true) {
        console.log("navigation done");
        //  $('#add_employee').modal('hide');
        navigate("../employees");
      }
      // }
    } catch (err) {
      console.log("data not saved", err);
    }
    // }
  };

  const handleBack = (item) => {
    if (fieldTwo && item == "fieldOne") {
      setFieldOne(true);
      setFieldTwo(false);
    }
    if (
      fieldOne &&
      employeeData.first_name &&
      employeeData.last_name &&
      employeeData.personal_email &&
      employeeData.number &&
      employeeData.gender &&
      employeeData.guardian_name &&
      employeeData.marital_status &&
      employeeData.date_of_birth &&
      employeeData.nationality
    ) {
      setFieldOne(false);
      setFieldTwo(true);
    }
    if (fieldThree && item == "fieldTwo") {
      setFieldTwo(true);
      setFieldThree(false);
    }
    if (fieldThree && item == "fieldOne") {
      setFieldOne(true);
      setFieldThree(false);
    }
    if (fieldFour && item == "fieldThree") {
      setFieldThree(true);
      setFieldFour(false);
    }
    if (fieldFour && item == "fieldTwo") {
      setFieldTwo(true);
      setFieldFour(false);
    }
    if (fieldFour && item == "fieldOne") {
      setFieldOne(true);
      setFieldFour(false);
    }
    if (fieldFive && item == "fieldFour") {
      setFieldFour(true);
      setFieldFive(false);
    }
    if (fieldFive && item == "fieldThree") {
      setFieldThree(true);
      setFieldFive(false);
    }
    if (fieldFive && item == "fieldTwo") {
      setFieldTwo(true);
      setFieldFive(false);
    }
    if (fieldFive && item == "fieldOne") {
      setFieldOne(true);
      setFieldFive(false);
    }
  };

  // ----------------- Edit Api --------------------------------
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        `${config.API_BASE_URL}employees/${id}`,
        configure
      );
      console.log("employee details response-->", res);
      const response = res?.data?.result?.basicDetails;
      setEmployeeData({ ...response });
      console.log("response from employee Data", response);
      // setMaritalStatus(res?.data?.result?.basicDetails?.marital_status);
      // let addresses = res?.data?.result?.addresses;
      // setAddressData({
      //   current_id : addresses[0].id,
      //   employee_id: addresses[0].employee_id,
      //   current_address: addresses[0].address,
      //   current_city: addresses[0].city,
      //   current_state: addresses[0].state,
      //   current_country: addresses[0].country,
      //   current_pincode: addresses[0].pincode,
      //   permanent_id : addresses[1].id,
      //   permanent_address: addresses[1].address,
      //   permanent_city: addresses[1].city,
      //   permanent_state: addresses[1].state,
      //   permanent_country: addresses[1].country,
      //   permanent_pincode: addresses[1].pincode,
      // });
      // setQualification(res?.data?.result?.qualifications);
      // setEmployementData(res?.data?.result?.employmentDetails[0]);
      // seAccountDetails(res?.data?.result?.accountDetails[0]);
      // setDepartmentID(res?.data?.result?.employmentDetails[0]?.department_id);
    };

    fetchData();
    console.log("account details->", accountDetails);
  }, [id]);

  // ---------------Image rendering----------------------
  const [showPassbook, setShowPassbook] = useState(false);
  const [showCv, setShowCv] = useState(false);
  const [showphoto, setShowPhoto] = useState(false);
  const [showAddhar, setShowAddhar] = useState(false);
  const [showPa, setShowPan] = useState(false);
  const [showDocument, setShowDocument] = useState(0);
  const [departmentName, setDepartmentName] = useState("");
  const [designationName, setDesignationName] = useState("");
  const [showDoc, setShowDoc] = useState(false);
  const [showExperienceLetter, setShowExperienceLetter] = useState(false);
  const [showReileavingLetter, setShowRealeavingLetter] = useState(false);

  const inputChangeHandlerDepartment = (event, id) => {
    const { name, value } = event.target;
    console.log("value ---->", value, "id ---->", id);
    setDepartmentName(value);
    setDepartmentID(id);
  };

  const inputChangeHandlerDesignation = (event, id) => {
    const { name, value } = event.target;
    console.log("value ---->", value, "id ---->", id);
    setDesignationName(value);
    setDesignationID(id);
  };

  useEffect(() => {
    if (departmentID) {
      designationHandler(departmentID);
    }
  }, [departmentID]);
  // useEffect(()=>{
  //   if(employeeData.department_id){
  //     designationHandler(employeeData.department_id)
  //   }
  // }, [employmentData.department_id])

  const documentHandler = (index, showdoc) => {
    setShowDocument(index);
    setShowDoc(showdoc);
  };

  return (
    <>
      <div className="custom-modal  mt-5 " role="dialog">
        <div className="modal-dialog  ">
          <div
            className="modal-content ml-5"
            style={{ marginLeft: "auto", width: "80%" }}
          >
            <div className="modal-header">
              <h5 className="modal-title">Edit Employee</h5>
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="false">×</span>
              </button> */}
            </div>
            {/* ----------add--------------- */}
            <div
              className="add-details-wizard ml-5"
              style={{ margin: "auto", marginTop: "15px" }}
            >
              <ul
                id="progressbar"
                className="progress-bar-wizard ml-5"
                style={{ cursor: "pointer", border: "1px solid black" }}
              >
                <li
                  className={fieldOne ? "active" : ""}
                  onClick={() => handleBack("fieldOne")}
                >
                  <span>
                    <i class="fa-solid fa-user"></i>
                  </span>
                  <div className="multi-step-info">
                    <h6>Basic Info</h6>
                  </div>
                </li>
                <li
                  className={fieldTwo ? "active" : ""}
                  onClick={() => handleBack("fieldTwo")}
                >
                  <span>
                    <i class="fa-solid fa-location-dot"></i>
                  </span>
                  <div className="multi-step-info">
                    <h6>Address</h6>
                  </div>
                </li>
                <li
                  className={fieldThree ? "active" : ""}
                  onClick={() => handleBack("fieldThree")}
                >
                  <div className="multi-step-icon">
                    <span>
                      <i class="fa-solid fa-award"></i>
                    </span>
                  </div>
                  <div className="multi-step-info">
                    <h6>Qualification</h6>
                  </div>
                </li>
                <li
                  className={fieldFour ? "active" : ""}
                  onClick={() => handleBack("fieldFour")}
                >
                  <div className="multi-step-icon">
                    <span>
                      <i class="fa-solid fa-file-invoice"></i>
                    </span>
                  </div>
                  <div className="multi-step-info">
                    <h6>Employment Details</h6>
                  </div>
                </li>
                <li
                  className={fieldFive ? "active" : ""}
                  onClick={() => handleBack("fieldFive")}
                >
                  <div className="multi-step-icon">
                    <span>
                      <i class="fa-solid fa-address-card"></i>
                    </span>
                  </div>
                  <div className="multi-step-info">
                    <h6> Account Details</h6>
                  </div>
                </li>
              </ul>
            </div>
            {/* 
              ---------------close------------- */}
            <div className="modal-body">
              <fieldset
                id="first-field"
                style={{ display: fieldOne ? "block" : "none" }}
              >
                <form
                  // onSubmit={handleSubmit}
                  method="post"
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          First Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="first_name"
                          onChange={inputHandleChnage}
                          required
                          value={employeeData.first_name}
                          minLength="3"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Middle Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="middle_name"
                          onChange={inputHandleChnage}
                          value={employeeData.middle_name}
                          minLength="3"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="last_name"
                          onChange={inputHandleChnage}
                          value={employeeData.last_name}
                          required
                          minLength="3"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          name="personal_email"
                          onChange={inputHandleChnage}
                          value={employeeData.personal_email}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Gender <span className="text-danger">*</span>
                        </label>
                        {/* <Select
                          options={gender}
                          placeholder="Select"
                          styles={customStyles}
                          name="gender"
                          required
                          onChange={(gender) => setGenderHandler(gender.label)}
                        /> */}
                        <select
                          className="form-control"
                          styles={customStyles}
                          required
                          placeholder="Select"
                          name="gender"
                          value={employeeData.gender}
                          onChange={inputHandleChnage}
                          // onChange={(slot) => {
                          //     setSlotShift(slot.label);
                          //   }}
                        >
                          {gender.map((item) => (
                            <option value={item.label} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          DOB <span className="text-danger">*</span>
                        </label>
                        <div className="cal-icon">
                          <DatePicker
                            selected={
                              selectedDob
                                ? selectedDob
                                : employeeData.date_of_birth == null ? "":employeeData.date_of_birth
                            }
                            onChange={(dob) => setSelectedDob(formatDate(dob))}
                            className="form-control floating datetimepicker"
                            type="date"
                            dateFormat="dd-MM-yyyy"
                            name="date_of_birth"
                            required
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Guardian Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="guardian_name"
                          onChange={inputHandleChnage}
                          value={employeeData.guardian_name}
                          minLength="3"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Marital Status <span className="text-danger">*</span>
                        </label>
                        {/* <Select
                          options={marital_status}
                          placeholder="Select"
                          styles={customStyles}
                          required
                          //   value={maritalStatus ?  maritalStatus : employeeData.marital_status}
                          onChange={(slot) => {
                            setMaritalStatus(slot.label);
                          }}
                        /> */}

                        <select
                          className="form-control"
                          styles={customStyles}
                          required
                          placeholder="Select"
                          name="marital_status"
                          value={
                            employeeData.marital_status == null
                              ? ""
                              : employeeData.marital_status
                          }
                          onChange={inputHandleChnage}
                          // onChange={(slot) => {
                          //     setSlotShift(slot.label);
                          //   }}
                        >
                          {marital_status.map((item) => (
                            <option value={item.label} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {employeeData.marital_status == "Married" && (
                      <>
                        <div className="col-sm-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Spouse Name
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="spouse_name"
                              onChange={inputHandleChnage}
                              value={employeeData.spouse_name}
                              minLength="3"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Married Date
                            </label>
                            <div className="cal-icon">
                              <DatePicker
                                selected={
                                  marriedDate
                                    ? marriedDate
                                    : employeeData.married_date
                                }
                                // onChange={(date) =>
                                //   setMarrieddate(formatDate(date))
                                // }
                                className="form-control floating datetimepicker"
                                type="date"
                                dateFormat="dd-MM-yyyy"
                                name="married_date"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Phone <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="number"
                          onChange={inputHandleChnage}
                          value={employeeData.number}
                          required
                          maxLength="10"
                          minLength="10"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Alternate Number{" "}
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="alternate_number"
                          onChange={inputHandleChnage}
                          value={employeeData.alternate_number}
                          maxLength="10"
                          minLength="10"
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Nationality</label>
                        <input
                          className="form-control"
                          type="text"
                          name="nationality"
                          onChange={inputHandleChnage}
                          value={employeeData.nationality}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Photo</label>
                        <input
                          className="form-control"
                          type="file"
                          name="photo"
                          onChange={inputHandleChnage}
                        />
                        {employeeData.photo && (
                          <span className="ml-4 ">
                            {employeeData.photo?.name
                              ? employeeData.photo?.name
                              : employeeData.photo}
                          </span>
                        )}{" "}
                        <i
                          class="fa-solid fa-eye mt-2 "
                          onClick={() => setShowPhoto(!showphoto)}
                          style={{ marginLeft: "20px" }}
                        ></i>
                        {showphoto && (
                          <div>
                            <img
                              src={`${config.IMAGE_URL}${employeeData.photo}`}
                              alt="passbook_photo"
                              style={{ width: "200px", height: "100px" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Aadhar</label>
                        <input
                          className="form-control"
                          type="file"
                          name="aadhar"
                          onChange={inputHandleChnage}
                        />
                        {employeeData.aadhar && (
                          <span className="ml-4 ">
                            {employeeData.aadhar?.name
                              ? employeeData.aadhar?.name
                              : employeeData.aadhar}
                          </span>
                        )}{" "}
                        <i
                          class="fa-solid fa-eye mt-2 "
                          onClick={() => setShowAddhar(!showAddhar)}
                          style={{ marginLeft: "20px" }}
                        ></i>
                        {showAddhar && (
                          <div>
                            <img
                              src={`${config.IMAGE_URL}${employeeData.aadhar}`}
                              alt="addhar photo"
                              style={{ width: "200px", height: "100px" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">PAN</label>
                        <input
                          className="form-control"
                          type="file"
                          name="pan"
                          onChange={inputHandleChnage}
                        />
                        {employeeData.pan && (
                          <span className="ml-4 ">
                            {employeeData.pan?.name
                              ? employeeData.pan?.name
                              : employeeData.pan}
                          </span>
                        )}{" "}
                        <i
                          class="fa-solid fa-eye mt-2 "
                          onClick={() => setShowPan(!showPa)}
                          style={{ marginLeft: "20px" }}
                        ></i>
                        {showPa && (
                          <div>
                            <img
                              src={`${config.IMAGE_URL}${employeeData.pan}`}
                              alt="passbook_photo"
                              style={{ width: "200px", height: "100px" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="submit-section">
                    <div className="col-lg-12 text-end form-wizard-button">
                      <button
                        className="btn btn-primary submit-btn"
                        type="submit"
                        onClick={handleSbubmiteInBasics}
                      >
                        Save &amp; Next
                      </button>
                    </div>
                  </div>
                </form>
              </fieldset>

              <fieldset style={{ display: fieldTwo ? "block" : "none" }}>
                <form method="post">
                  <div className="contact-input-set">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Current Address
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="current_address"
                            value={addressData.current_address}
                            onChange={addressDataHandler}
                            required
                            minLength="5"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            City
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="current_city"
                            value={addressData.current_city}
                            onChange={addressDataHandler}
                            required
                            minLength="3"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            State <span className="text-danger"> *</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="current_state"
                            value={addressData.current_state}
                            onChange={addressDataHandler}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Country <span className="text-danger">*</span>
                          </label>

                          {/* <Select
                            required
                            className="select"
                            options={countrylist}
                            placeholder="Select"
                            styles={customStyles}
                            value={selectedCurrentCountry}
                            onChange={(country) => {
                              setSelectedCurrentCountry(country);
                            }}
                          /> */}
                          <select
                            className="form-control"
                            styles={customStyles}
                            required
                            placeholder="Select"
                            name="current_country"
                            value={addressData.current_country}
                            onChange={addressDataHandler}
                            // onChange={(slot) => {
                            //     setSlotShift(slot.label);
                            //   }}
                          >
                            {countrylist.map((item) => (
                              <option value={item.label} key={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Zipcode <span className="text-danger"> *</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="current_pincode"
                            value={addressData.current_pincode}
                            onChange={addressDataHandler}
                            required
                            maxLength="6"
                            minLength="6"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-block mb-3">
                          <input
                            type="checkbox"
                            onChange={handleCheckBoxChanged}
                          />{" "}
                          Permanent Address and Current Address are the same.
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Permanent Address
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="permanent_address"
                            value={addressData.permanent_address}
                            onChange={addressDataHandler}
                            disabled={addressData.isSameAddress}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            City
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="permanent_city"
                            value={addressData.permanent_city}
                            onChange={addressDataHandler}
                            disabled={addressData.isSameAddress}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            State <span className="text-danger"> *</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="permanent_state"
                            value={addressData.permanent_state}
                            onChange={addressDataHandler}
                            disabled={addressData.isSameAddress}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Country <span className="text-danger">*</span>
                          </label>

                          {/* <Select
                            required
                            className="select"
                            options={countrylist}
                            placeholder="Select"
                            styles={customStyles}
                            value={
                              addressData.isSameAddress == true
                                ? selectedCurrentCountry
                                : selectedPermanentCountry
                            }
                            onChange={(country) =>
                              setSelectedPermanentCountry(country)
                            }
                            isdisabled={addressData.isSameAddress}
                          /> */}

                          <select
                            className="form-control"
                            styles={customStyles}
                            required
                            placeholder="Select"
                            name="permanent_country"
                            value={
                              addressData.isSameAddress == true
                                ? addressData.current_country
                                : addressData.permanent_country
                            }
                            onChange={addressDataHandler}
                            // onChange={(slot) => {
                            //     setSlotShift(slot.label);
                            //   }}
                          >
                            {countrylist.map((item) => (
                              <option value={item.label} key={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Zipcode <span className="text-danger"> *</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="permanent_pincode"
                            value={addressData.permanent_pincode}
                            onChange={addressDataHandler}
                            disabled={addressData.isSameAddress}
                            maxLength="6"
                            minLength="6"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="submit-section">
                    <div className="col-lg-12 text-end form-wizard-button">
                      <button
                        className="btn btn-primary submit-btn"
                        type="button"
                        style={{ marginRight: "10px" }}
                        onClick={() => handleBack("fieldOne")}
                      >
                        Previous
                      </button>

                      <button
                        className="btn btn-primary submit-btn"
                        type="submit"
                        onClick={handleSbubmiteInaddress}
                      >
                        Save &amp; Next
                      </button>
                    </div>
                  </div>
                </form>
              </fieldset>

              <fieldset style={{ display: fieldThree ? "block" : "none" }}>
                <form>
                  <div className="contact-input-set">
                    <div className="row">
                      <div className="col-lg-12 text-end form-wizard-button">
                        <button
                          className="btn btn-primary wizard-next-btn"
                          type="button"
                          onClick={handleAddQualification}
                        >
                          Add More Qualification
                        </button>
                      </div>
                    </div>
                    {qualifications.map((qual, index) => (
                      <div className="row">
                        <div className="row">
                          <div className="col-lg-12 text-end form-wizard-button mt-2">
                            <i
                              class="fa-solid fa-trash"
                              style={{ color: "red", fontSize: "18px" }}
                              onClick={() => handleRoveQualification(index)}
                            ></i>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Qualification
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="qualification"
                              required
                              value={qual.qualification}
                              onChange={(e) =>
                                handleChangeQualification(
                                  index,
                                  "qualification",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              University / Board
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="university"
                              required
                              value={qual.university}
                              onChange={(e) =>
                                handleChangeQualification(
                                  index,
                                  "university",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Passing Year
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="passing_year"
                              required
                              value={qual.passing_year}
                              onChange={(e) =>
                                handleChangeQualification(
                                  index,
                                  "passing_year",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              percentage <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="percentage"
                              required
                              value={qual.percentage}
                              onChange={(e) =>
                                handleChangeQualification(
                                  index,
                                  "percentage",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Document <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              name="document"
                              onChange={(e) =>
                                handleFileChange(
                                  index,
                                  "document",
                                  e.target.files[0]
                                )
                              }
                            />

                            {
                              <span className="ml-4 ">
                                {qual.document?.name
                                  ? qual.document?.name
                                  : qual.document}
                              </span>
                            }
                            <i
                              class="fa-solid fa-eye mt-2 "
                              onClick={() => documentHandler(index, !showDoc)}
                              style={{ marginLeft: "20px" }}
                            ></i>
                            {showDocument == index && showDoc && (
                              <div>
                                <img
                                  src={`${config.IMAGE_URL}${employmentData.cv}`}
                                  alt="passbook_photo"
                                  style={{ width: "200px", height: "100px" }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        {qualifications.length > 1 && <hr />}
                      </div>
                    ))}
                    <div className="row mb-5">
                      <div className="col-md-12 mt-5 mb-3">
                        <h3>Experience</h3>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Experience Letter
                          </label>
                          <input
                            className="form-control"
                            type="file"
                            name="experience_letter"
                            onChange={inputHandleChnage}
                          />
                          {employeeData.experience_letter && (
                            <span className="ml-4 ">
                              {employeeData.experience_letter?.name
                                ? employeeData.experience_letter?.name
                                : employeeData.experience_letter}
                            </span>
                          )}
                          <i
                            class="fa-solid fa-eye mt-2 "
                            onClick={() =>
                              setShowExperienceLetter(!showExperienceLetter)
                            }
                            style={{ marginLeft: "20px" }}
                          ></i>
                          {showExperienceLetter && (
                            <div>
                              <img
                                src={`${config.IMAGE_URL}${employeeData.experience_letter}`}
                                alt="passbook_photo"
                                style={{ width: "200px", height: "100px" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Relieving Letter
                          </label>
                          <input
                            className="form-control"
                            type="file"
                            name="resignation_letter"
                            onChange={inputHandleChnage}
                          />
                          {employeeData.resignation_letter && (
                            <span className="ml-4 ">
                              {employeeData.resignation_letter?.name
                                ? employeeData.resignation_letter?.name
                                : employeeData.resignation_letter}
                            </span>
                          )}
                          <i
                            class="fa-solid fa-eye mt-2 "
                            onClick={() =>
                              setShowRealeavingLetter(!showReileavingLetter)
                            }
                            style={{ marginLeft: "20px" }}
                          ></i>
                          {showReileavingLetter && (
                            <div>
                              <img
                                src={`${config.IMAGE_URL}${employeeData.resignation_letter}`}
                                alt="passbook_photo"
                                style={{ width: "200px", height: "100px" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 text-end form-wizard-button">
                        <button
                          className="btn btn-primary submit-btn"
                          type="button"
                          style={{ marginRight: "10px" }}
                          onClick={() => handleBack("fieldTwo")}
                        >
                          Previous
                        </button>
                        <button
                          className="btn btn-primary submit-btn"
                          type="submit"
                          onClick={handleSubmitInQualification}
                        >
                          Save &amp; Next
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </fieldset>

              <fieldset style={{ display: fieldFour ? "block" : "none" }}>
                <form>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Offical Email</label>
                        <input
                          className="form-control"
                          type="email"
                          name="offical_email"
                          onChange={employmentDetailsHandler}
                          value={employmentData.offical_email}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Joining Date <span className="text-danger">*</span>
                        </label>
                        <div className="cal-icon">
                          <DatePicker
                            selected={
                              selectedDate1
                                ? selectedDate1
                                : employmentData.joining_date
                            }
                            onChange={handleDateChange1}
                            className="form-control floating datetimepicker"
                            type="date"
                            dateFormat="dd-MM-yyyy"
                            name="joining_date"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Slot <span className="text-danger">*</span>
                        </label>
                        {/* <Select
                          selected ={employmentData.slot}
                          options={slot}
                          placeholder="Select"
                          styles={customStyles}
                          required

                          onChange={(slot) => {
                            setSlotShift(slot.label);
                          }}
                        /> */}
                        <select
                          className="form-control"
                          styles={customStyles}
                          required
                          placeholder="Select"
                          name="slot"
                          value={employmentData.slot}
                          onChange={employmentDetailsHandler}
                          // onChange={(slot) => {
                          //     setSlotShift(slot.label);
                          //   }}
                        >
                          {slot.map((item) => (
                            <option value={item.label} key={item.value}>
                              {" "}
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Experience</label>
                        <input
                          className="form-control"
                          type="text"
                          name="experience"
                          onChange={employmentDetailsHandler}
                          value={employmentData.experience}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Department <span className="text-danger">*</span>
                        </label>
                        {/* <Select
                          options={department}
                          placeholder="Select"
                          styles={customStyles}
                          required
                          onChange={(department) =>
                            setDepartmentID(department.value)
                          }
                        /> */}
                        <select
                          name="department_id"
                          className="form-control"
                          styles={customStyles}
                          required
                          placeholder="select department"
                          onChange={(event) =>
                            inputChangeHandlerDepartment(
                              event,
                              event.target.selectedOptions[0].getAttribute(
                                "data-id"
                              )
                            )
                          }
                          value={
                            departmentName
                              ? departmentName
                              : employmentData.department_name
                          }
                        >
                          {department.map((department) => (
                            <option
                              value={department.label}
                              key={department.value}
                              data-id={department.value}
                            >
                              {department.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Designation <span className="text-danger">*</span>
                        </label>
                        {/* <Select
                          options={designation}
                          placeholder="Select"
                          styles={customStyles}
                          required
                          onChange={(designation) =>
                            setDesignationID(designation.value)
                          }
                        /> */}

                        <select
                          name="designation_id"
                          className="form-control"
                          styles={customStyles}
                          required
                          placeholder="select designation"
                          onChange={(event) =>
                            inputChangeHandlerDesignation(
                              event,
                              event.target.selectedOptions[0].getAttribute(
                                "data-id"
                              )
                            )
                          }
                          value={
                            designationName
                              ? designationName
                              : employmentData.designation_name
                          }
                        >
                          {designation.map((designation) => (
                            <option
                              value={designation.label}
                              key={designation.value}
                              data-id={designation.value}
                            >
                              {designation.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Salary</label>
                        <input
                          className="form-control"
                          type="text"
                          name="salary"
                          onChange={employmentDetailsHandler}
                          value={employmentData.salary}
                          required
                          maxLength="10"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">CV</label>
                        <input
                          className="form-control"
                          type="file"
                          name="cv"
                          onChange={employmentDetailsHandler}
                        />
                        {employmentData.cv && (
                          <span className="ml-4 ">
                            {employmentData.cv?.name
                              ? employmentData.cv?.name
                              : employmentData.cv}
                          </span>
                        )}{" "}
                        <i
                          class="fa-solid fa-eye mt-2 "
                          onClick={() => setShowCv(!showCv)}
                          style={{ marginLeft: "20px" }}
                        ></i>
                        {showCv && (
                          <div>
                            <img
                              src={`${config.IMAGE_URL}${employmentData.cv}`}
                              alt="passbook_photo"
                              style={{ width: "200px", height: "100px" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="submit-section">
                    <div className="col-lg-12 text-end form-wizard-button">
                      <button
                        className="btn btn-primary submit-btn"
                        type="button"
                        style={{ marginRight: "10px" }}
                        onClick={() => handleBack("fieldThree")}
                      >
                        Previous
                      </button>

                      <button
                        className="btn btn-primary submit-btn"
                        type="submit"
                        onClick={handleSubmitInEmployment}
                      >
                        Save &amp; Next
                      </button>
                    </div>
                  </div>
                </form>
              </fieldset>

              <fieldset style={{ display: fieldFive ? "block" : "none" }}>
                <form method="post" onSubmit={handlerSubmitInAccount}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Account Holder Name{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="account_holder_name"
                          onChange={accountDetailsHandler}
                          value={accountDetails.account_holder_name}
                          required
                          minLength="3"
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Account Number
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="account_number"
                          onChange={accountDetailsHandler}
                          value={accountDetails.account_number}
                          required
                          maxLength="16"
                          minLength="11"
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          IFSC Code <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="ifsc_code"
                          onChange={accountDetailsHandler}
                          value={accountDetails.ifsc_code}
                          required
                          maxLength="11"
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Bank Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="bank_name"
                          onChange={accountDetailsHandler}
                          value={accountDetails.bank_name}
                          required
                          minLength="5"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Branch Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="branch_name"
                          onChange={accountDetailsHandler}
                          value={accountDetails.branch_name}
                          required
                          minLength="5"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Passbok Picture <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          name="passbook_photo"
                          onChange={accountDetailsHandler}
                        />
                        {accountDetails.passbook_photo && (
                          <span className="ml-4 ">
                            {accountDetails?.passbook_photo?.name
                              ? accountDetails.passbook_photo?.name
                              : accountDetails?.passbook_photo}
                          </span>
                        )}{" "}
                        <i
                          class="fa-solid fa-eye mt-2 "
                          onClick={() => setShowPassbook(!showPassbook)}
                          style={{ marginLeft: "20px" }}
                        ></i>
                        {showPassbook && (
                          <div>
                            <img
                              src={`${config.IMAGE_URL}${accountDetails.passbook_photo}`}
                              alt="passbook_photo"
                              style={{ width: "200px", height: "100px" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <hr />

                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Provident Fund (UAN Number)
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="uan_number"
                          onChange={accountDetailsHandler}
                          required
                          maxLength="16"
                          value={accountDetails.uan_number}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="submit-section">
                    <div className="col-lg-12 text-end form-wizard-button">
                      <button
                        className="btn btn-primary submit-btn"
                        type="button"
                        style={{ marginRight: "10px" }}
                        onClick={() => handleBack("fieldFour")}
                      >
                        Previous
                      </button>

                      {/* <Link to = "../employees" > */}
                      <button
                        className="btn btn-primary submit-btn"
                        type="submit"
                      >
                        Submit
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </form>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditEmployee;
