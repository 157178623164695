import axios from "axios";
import config from "../config";

export const getAllCompanies = async () => {
  try {
    const response = await axios.get(`${config.API_BASE_URL}api/companies`);
    return response.data;
  } catch (error) {
    console.error("Error fetching companies:", error);
    throw error;
  }
};
