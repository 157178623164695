import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import Breadcrumbs from "../../../components/Breadcrumbs";
import SearchBox from "../../../components/SearchBox";
import AddOverTime from "../../../components/modelpopup/AddOverTime";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import { getAllExpenses } from "../../../services/reimbursementService";
import { formatDateInTimezone } from "../../../utils/formatDateInTimezone";
import axios from "axios";
import config from "../../../config";
import { useNavigate } from "react-router-dom";

const Reimbursement = () => {
  const [expenses, setExpenses] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [appliedReimbursement, setAppliedReimbursement] = useState("");
  const [noOfReimbursementForApproval, setNoOfReimbursementForApproval] =
    useState("");
  const [showNames, setShowNames] = useState(false);
  const [activeStat, setActiveStat] = useState("Applied Reimbursement");
  const [acknowledgeAttachment, setAcknowledgeAttachment] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(0);
  const [reimbursementId, setReimbursementId] = useState(null);
  const [acknowledge, setAcknowledge] = useState(false);

  console.log(' acknowledge : ',acknowledge);

  const employeeId = localStorage.getItem("employeeID");

  const navigate = useNavigate();

  const fetchExpenses = async () => {
    console.log(' handlerApprovalStatus fetchExpenses calling');
    try {
      setLoading(true);
      const data = await getAllExpenses(employeeId);
      console.log('data.filter((item) => item.status !== 0) : ',data)
      console.log(' handlerApprovalStatus fetchExpenses data : ',data);
      setExpenses(data.filter((item) => item.status !== 0));
      setAppliedReimbursement(data.filter((item) => item.status !== 0).length);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExpenses();
  }, []);

  useEffect(() => {
    handlerApprovalStatus();
  }, []);

  const statsData = [
    {
      title: "Applied Reimbursement",
      value: appliedReimbursement,
    },
    {
      title: "Reimbursement for Approval",
      value: noOfReimbursementForApproval,
    },
    // {
    //   title: "Rejected",
    //   value: 5,
    // },
  ];

  const deleteReimbursement = (id, e) => {
    e.preventDefault();
    console.log("deleteReimbursement : ", id);
    axios
      .put(`${config.API_BASE_URL}api/reimbursement-delete/${id}`)
      .then(() => navigate("../reimbursement"));
  };

  const reimbursementStatus = [
    { value: 2, label: "Approved" },
    { value: 3, label: "Rejected" },
  ];

  const reimbursementStatusIdHandler = async (id, reimbursement_id ) => {
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}api/reimbursementsForApprova/${reimbursement_id}`,
        {
          approver_id: employeeId,
          status_id: id,
        }
      );
      if(response.status==200){
        setCurrentStatus(id);
        handlerApprovalStatus("Reimbursement for Approval");
      }
      console.log("reimbursementStatusIdHandler : response : ", response)

    } catch (error) {
      console.error("Error updating reimbursement:", error);
    }
  };


  const columns = [
    // {
    //   title: "#",
    //   // dataIndex: "id",
    //   render: (text, record, index) => index + 1,
    //   sorter: (a, b) => a.id.length - b.id.length,
    // },
    {
      title: "Title",
      dataIndex: "expense_type",
      render: (text, record) => <span className="table-avatar">{text}</span>,
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Claimed on",
      dataIndex: "created_at",
      render: (text, record) => formatDateInTimezone(record?.created_at),
      sorter: (a, b) => a.otdate.length - b.otdate.length,
    },
    {
      title: "Expense date",
      dataIndex: "expense_date",
      render: (text, record) => formatDateInTimezone(record.expense_date),
      sorter: (a, b) => a.othours.length - b.othours.length,
    },
    {
      title: "Amount claimed",
      dataIndex: "expense_amount",
      sorter: (a, b) => a.othours.length - b.othours.length,
    },
    ...(showNames ? [{
      title: "Status",
      dataIndex: "updated_status",
      sorter: (a, b) => a.ottype.length - b.ottype.length,
     render: (text, record) => (
        <span  style={{color : record.updated_status === 1 ? "rgb(255 223 0)" : record.updated_status === 2 ? "green": "red" }}>{record.updated_status === 1 ? "Pending" : record.updated_status === 2 ? "Approved" : "Rejected"}</span>
      )
    }] : [{
      title: "Status",
      dataIndex: "expense_status",
      sorter: (a, b) => a.ottype.length - b.ottype.length,
     render: (text, record) => (
        <span  style={{color : record.expense_status === 1 ? "rgb(255 223 0)" : record.expense_status === 2 ? "green": "red" }}>{record.expense_status === 1 ? "Pending" : record.expense_status === 2 ? "Approved" : "Rejected"}</span>
      )
    }]),
    {
      title: "Action",
      render: (record) => (
        <div className="dropdown dropdown-action text-end">
          {!showNames?<Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>:<Link
            to="#"
            className="dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#exampleModalFullscreen"
            onClick={(e)=>{
              e.preventDefault();
              setCurrentStatus(record?.updated_status);
              setReimbursementId(record?.id)
              setAcknowledgeAttachment(record?.attachment);
            }}
          >
            <i className="fa-regular fa-eye"/>Attachment 
          </Link>}
          {
            !showNames?<div className="dropdown-menu dropdown-menu-right">
            {/* <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#exampleModalFullscreen"
            >
              <i className="fa-regular fa-eye" /> Uploaded attachment
            </Link> */}
            <Link
              className="dropdown-item"
              to="#"
              onClick={(e) => {
                deleteReimbursement(record.id, e);
              }}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>:<div className="dropdown-menu dropdown-menu-right">
              {/* {reimbursementStatus.map((reimbursement, index) => (
                <a
                  className="dropdown-item"
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("record for id of reimbursement : ", record.id);
                    reimbursementStatusIdHandler(reimbursement.value, record.id );
                  }}
                >
                  <i
                    className={`far fa-dot-circle ${
                      reimbursement.label == "Approved"
                        ? "text-success"
                        : " text-danger"
                    } `}
                  />{" "}
                  {reimbursement.label}
                </a>
              ))} */} 
            </div>
          }
          
        </div>
      ),
    },
  ];

  if (showNames) {
    columns.unshift(
      {
        title: "First Name",
        dataIndex: "first_name",
        render: (text) => <span>{text}</span>,
        sorter: (a, b) => a.first_name.length - b.first_name.length,
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
        render: (text) => <span>{text}</span>,
        sorter: (a, b) => a.last_name.length - b.last_name.length,
      }
    );
  }

  const handlerApprovalStatus = async (approve) => {
    console.log(' handlerApprovalStatus call ');
    setExpenses([]);
    console.log(' handlerApprovalStatus expenses : ',expenses);
    try {
      const employeeID = localStorage.getItem("employeeID");
      const response = await axios.get(
        `${config.API_BASE_URL}api/reimbursementsForApproval/${employeeID}`
      );
      const approvalData = response.data.result;

      const response2 = await axios.get(
        `${config.API_BASE_URL}api/reimbursement-for-approval/${employeeID}`
      );

      const approvalData2 = response2.data.result;

      if (approve === "Reimbursement for Approval") {

        let combinedData = [];

        if (approvalData.length > 0) {
          combinedData = [...combinedData, ...approvalData];
        }
        if (approvalData2.length > 0) {
          combinedData = [...combinedData, ...approvalData2];
        }

        if (combinedData.length > 0) {
          console.log(' handlerApprovalStatus combinedData : ',combinedData);
          setExpenses(combinedData);
          console.log('combinedData : ',combinedData);
        }
        setActiveStat("Reimbursement for Approval");
        // console.log("Reimbursement data", approvalData);
        setShowNames(true);
      } else {
        console.log(' handlerApprovalStatus calling else fetchExpenses : ',expenses);
        setExpenses([]);
        setShowNames(false);
        fetchExpenses();
        let combinedData = [];
      
        if (approvalData.length > 0) {
          combinedData = [...combinedData, ...approvalData];
        }
        if (approvalData2.length > 0) {
          combinedData = [...combinedData, ...approvalData2];
        }
        setNoOfReimbursementForApproval(combinedData.length);
        setActiveStat("Applied Reimbursement");
      }
    } catch (err) {
      console.log("err",err);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}

          <Breadcrumbs
            maintitle="Reimbursement"
            title="Dashboard"
            subtitle="reimbursement"
            // modal="#add_overtime"
            name="Create Expenses"
            page='create-expense'
            action='create'
          />

          <div
            className="modal fade"
            id="exampleModalFullscreen"
            tabIndex={-1}
            aria-labelledby="exampleModalFullscreenLabel"
            aria-hidden="true"
            style={{ display: "none", zIndex: "99999" }}
          >
            <div className="modal-dialog modal-fullscreen">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="exampleModalFullscreenLabel">
                    Uploaded Attachment
                  </h4>
                  <button
                    style={{
                      color:'red',
                      border:'0px',
                    backgroundColor:'transparent',
                    fontSize:'24px'
                  }}
                    type="button"
                    onClick={()=>{
                      setAcknowledge(false)}}
                    data-bs-dismiss="modal"
                  >
                    <i class="far fa-window-close"></i>
                  </button>
                </div>
                <div className="modal-body d-flex justify-content-center">
                  <img
                    // style={{ width: "50%",  }}
                    src={`${config.IMAGE_URL}${acknowledgeAttachment}`}
                    alt="attachment"
                  />
                </div>
                <div className="modal-footer">
                  <div style={{cursor:'pointer', display:'flex', alignItems:'baseline', gap:'4px'}}><input type='checkbox' checked={acknowledge || currentStatus!==1} onClick={(e)=>{setAcknowledge(e.target.checked)}}/>
                  <label>I have reviewed and acknowledge this attachment.</label></div>
                  
                  {acknowledge&&<div className="dropdown action-label text-center">
                    <Link
                      className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                      to="#"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i
                        className={
                          currentStatus === 1
                            ? "far fa-dot-circle text-info"
                            : currentStatus === 2
                            ? "far fa-dot-circle text-success"
                            : "far fa-dot-circle text-danger"
                        }
                      />{" "}
                      {currentStatus === 1
                            ? "Pending"
                            : currentStatus === 2
                            ? "Approved"
                            : "Rejected"}
                    </Link>

                    <div className="dropdown-menu dropdown-menu-right">
                      <Link className="dropdown-item" to="#">
                        <i className="far fa-dot-circle text-info" /> Pending
                      </Link>
                      <Link className="dropdown-item" to="#" onClick={(e)=>{
                          e.preventDefault();
                          reimbursementStatusIdHandler(2,reimbursementId)
                      }}>
                        <i className="far fa-dot-circle text-success" />{" "}
                        Approve
                      </Link>
                      <Link className="dropdown-item" to="#" onClick={(e)=>{
                          e.preventDefault();
                          reimbursementStatusIdHandler(3,reimbursementId)
                      }}>
                        <i className="far fa-dot-circle text-danger" /> Reject
                      </Link>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row d-flex ">
            {statsData.map((data, index) => (
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3" key={index}>
                <div
                  className="stats-info "
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    console.log(' handlerApprovalStatus going to call ')
                    setExpenses([]);
                    handlerApprovalStatus(data.title);
                  }}
                >
                  <h6 className={data.title === activeStat ? "text-info" : ""}>
                    {data.title}
                  </h6>
                  <h4>{data.value}</h4>
                </div>
              </div>
            ))}
          </div>
          {/* /Overtime Statistics */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  className="table-striped"
                  columns={columns}
                  dataSource={expenses}
                  rowKey={(record) => record.id}
                   scroll={{ x: true }} 
                  tableLayout="auto"
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      {/* <AddOverTime /> */}
      <DeleteModal Name="Delete Overtime" />
    </>
  );
};

export default Reimbursement;
