import React, { useState } from "react";
import { set } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import config from "../../../../src/config";
import axios from "axios";
import * as Yup from "yup";
import "../../../assets/css/createExpense.css";
import createCustomSchema from "../../../fieldValidator/fieldValidator";

function CreateExpense() {
  const validationSchema = Yup.object({
    expense_amount: createCustomSchema("number", {
      message: "Enter reimbursement amount",
    }),
    expense_type: createCustomSchema("dropdown", {
      message: "Select expense type",
    }),
    attachment: createCustomSchema("file", {
      message: "Upload invoice",
    }),
    expense_date: createCustomSchema("date", {
      message: "Enter expense date",
    }),
    // merchants: createCustomSchema("text", {
    //   message: "Enter merchants name",
    // }),
    // invoice_number: createCustomSchema("alphanumeric", {
    //   message: "Enter invoice number",
    // }),
    currency: createCustomSchema("dropdown", { message: "Select currency" }),
  });

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const options = {
    selectType: [
      { value: "food_beverage", label: "Food & Beverage" },
      { value: "travel_transportation", label: "Travel & Transportation" },
      { value: "accommodation", label: "Accommodation" },
      { value: "office_supplies", label: "Office Supplies" },
      { value: "entertainment", label: "Entertainment" },
      { value: "miscellaneous", label: "Miscellaneous" },
      { value: "internet_phone", label: "Internet & Phone" },
      { value: "medical", label: "Medical" },
      { value: "training_development", label: "Training & Development" },
      { value: "client_meetings", label: "Client Meetings" },
      { value: "postage_courier", label: "Postage & Courier" },
      { value: "subscriptions", label: "Subscriptions" },
      //create table
    ],
    currency: [{ value: "inr", label: "INR" }], // create table
  };

  const navigate = useNavigate();

  const [expense, setExpense] = useState({
    employee_id: "",
    expense_type: "",
    expense_date: null,
    expense_amount: null,
    // invoice_number: "",
    currency: "",
    // merchants: "",
    attachment: null,
  });

  console.log(" expense testing : ", expense);

  const [expenseError, setExpenseError] = useState({});

  const employeeID = localStorage.getItem("employeeID");

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setExpense({ ...expense, [name]: value, employee_id: employeeID });
  };

  const handleFileChange = (e) => {
    console.log("e.target : ", e.target.files);
    const { name, files } = e.target;
    const selectedFile = files[0];
    setExpense({ ...expense, [name]: selectedFile });
  };

  const handleDropDownChange = (key, selectedOption) => {
    setExpense((prevState) => ({
      ...prevState,
      [key]: selectedOption.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(expense, { abortEarly: false });
      console.log("Form submit : ", expense);
      console.log(" expense : after validation", expense);

      // navigate("/admin-dashboard");

      const data = new FormData();
      Object.keys(expense).forEach((key) => {
        console.log("key, expense[key] : ", key, expense[key]);
        data.append(key, expense[key]);
      });

      axios
        .post(`${config.API_BASE_URL}api/reimbursement`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Expense created successfully:", response.data);
          navigate("../reimbursement");
        })
        .catch((error) => {
          console.error("There was an error creating the expense!", error);
        });
    } catch (error) {
      const newError = {};

      // console.log("yup error : ", error.inner);

      error.inner.forEach((err) => {
        newError[err.path] = err.message;
      });
      setExpenseError(newError);
      console.log("yup error : ", newError);
    }
  };

  const onSubmitYup = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(expense, { abortEarly: false });
      console.log("Form submit : ", expense);
    } catch (error) {
      const newError = {};

      // console.log("yup error : ", error.inner);

      error.inner.forEach((err) => {
        newError[err.path] = err.message;
      });
      setExpenseError(newError);
      console.log("yup error : ", newError);
    }
  };

  return (
    <div className="page-wrapper create-expense">
      <div className="content container-fluid">
        <div className="card-body">
          <form onSubmit={onSubmit}>
            {/* <form onSubmit={onSubmitYup}> */}
            <h4 className="card-title">Create New Expense</h4>
            <div className="row mt-4">
              <div className="col-md-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">Select Expense Type</label>
                  <Select
                    // required
                    options={options.selectType}
                    placeholder="Select Type"
                    styles={customStyles}
                    onChange={(selectedOption) =>
                      handleDropDownChange("expense_type", selectedOption)
                    }
                  />
                  {expenseError.expense_type && (
                    <span className="text-danger">
                      *{expenseError.expense_type}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">Currency and Amount</label>
                  <div className="input-block mb-3">
                    <div className="input-group">
                      <Select
                        // required
                        options={options.currency}
                        placeholder="Currency"
                        styles={customStyles}
                        onChange={(selectedOption) =>
                          handleDropDownChange("currency", selectedOption)
                        }
                      />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Amount"
                        name="expense_amount"
                        value={expense.expense_amount}
                        onChange={handleOnChange}
                        // required
                      />
                    </div>
                    {expenseError.currency && (
                      <span
                        className="text-danger"
                        style={{ marginRight: "10px" }}
                      >
                        *{expenseError.currency}
                      </span>
                    )}
                    {expenseError.expense_amount && (
                      <span className="text-danger">
                        *{expenseError.expense_amount}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">Expense Date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="expense_date"
                    value={expense.expense_date}
                    onChange={handleOnChange}
                    // required
                  />
                  {expenseError.expense_date && (
                    <span className="text-danger">
                      *{expenseError.expense_date}
                    </span>
                  )}
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">Merchant</label>
                  <input
                    type="text"
                    className="form-control"
                    name="merchants"
                    value={expense.merchants}
                    onChange={handleOnChange}
                    // required
                  />
                  {expenseError.merchants && (
                    <span className="text-danger">
                      *{expenseError.merchants}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">Invoice Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="invoice_number"
                    value={expense.invoice_number}
                    onChange={handleOnChange}
                    // required
                  />
                  {expenseError.invoice_number && (
                    <span className="text-danger">
                      *{expenseError.invoice_number}
                    </span>
                  )}
                </div>
              </div> */}

              <div className="col-md-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">Upload attachment</label>
                  <input
                    type="file"
                    name="attachment"
                    className="form-control"
                    onChange={handleFileChange}
                    // required
                  />
                  {expenseError.attachment && (
                    <span className="text-danger">
                      *{expenseError.attachment}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="text-end">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateExpense;
