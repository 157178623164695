import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import axios from "axios";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import SearchBox from "../../../components/SearchBox";
import DepartmentModal from "../../../components/modelpopup/DepartmentModal";
import { base_url } from "../../../base_urls";
import config from "../../../config";
import ProtectedButton from "../../../Routes/Approuter/ProtectedButton";
// import { rolesConfig } from "../../../Routes/rolesConfig";
import { useRole} from "../../../context/RoleContext";

const Department = () => {
  const [paginationValue , setPaginationValue] = useState(10)
  const [users, setUsers] = useState([]);

  // useEffect(() => {
  //   axios
  //     .get(base_url + "/api/department.json")
  //     .then((res) => setUsers(res.data));
  // }, []);

const company = localStorage.getItem("user");
const companyDetail = JSON.parse(company);
const companyID = companyDetail.company.company_id;
const [departmentID , setDepartmentID] = useState("");
const [renderState , setRenderState] = useState(false);


  useEffect(()=>{
    axios.get(`${config.API_BASE_URL}department/${companyID}`).then((res)=>{
      if(res?.data?.success == true){
        console.log("response" , res?.data?.result)
          setUsers(res?.data?.result);
      }
    }).catch((err)=>console.log(err))
  },[renderState])
  const userElements = users.map((user, index) => ({
    key: index + 1,
    id: user.id,
    department: user.department_name,
  }));
  console.log("userElement" , userElements);
  const handledepartment = (id)=>{
    setDepartmentID(id);
  }

  const filterDepartment = userElements.filter((item,index)=> item.id == departmentID);
  console.log("flterDepartment", filterDepartment);

  const handleDeleteDepartment  = async(id) =>{
    try{
      const res = await axios.delete(`${config.API_BASE_URL}department/deleteDepartment/${id}`);
      console.log("Updating delete Response" , res);
    }catch(err){
      console.log(err);
    }
  }

  const { role,rolesConfig } = useRole();

  const rolePermissions = rolesConfig[role]?.pages['departments'] || [];
  
  const columns = [
    {
      title: "#",
      dataIndex: "key",
      sorter: (a, b) => a.key.length - b.key.length,
      width: "30%",
    },
    {
      title: "Department Name",
      dataIndex: "department",
      sorter: (a, b) => a.department.length - b.department.length,
      width: "60%",
    },
    ...(rolePermissions.includes('edit','delete')
      ? [
        {
          title:"Action",
          className: "text-end",
          render: (record) => (
            
            <div className="dropdown dropdown-action text-end">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="material-icons">more_vert</i>
              </Link>
              <div className="dropdown-menu dropdown-menu-right">
              <ProtectedButton page="departments" action="edit">
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#edit_department"
                  onClick={()=>handledepartment(record.id)}
                >
                  <i className="fa fa-pencil m-r-5"  /> Edit
                </Link>
                </ProtectedButton>
                <ProtectedButton page="departments" action="delete">
                <Link
                  className="dropdown-item"
                  to="#"
                  // data-bs-toggle="modal"
                  // data-bs-target="#delete"
                  onClick={()=>handleDeleteDepartment(record.id)}
                >
                  <i className="fa fa-trash m-r-5" /> Delete
                </Link>
                </ProtectedButton>
              </div>
            </div>
          ),
          // sorter: (a, b) => a.length - b.length,
          width: "10%",
      
    }
        ]
      : []),
  ];
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Department"
            title="Dashboard"
            subtitle="Department"
            modal="#add_department"
            name="Add Department"
            page='departments'
            action='create'
          />
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox setPaginationValue= {setPaginationValue}/>
                <Table
                  columns={columns}
                  dataSource={userElements?.length > 0 ? userElements : []}
                  className="table-striped"
                  rowKey={(record) => record.id}
                  pagination={{ pageSize: paginationValue }}
                  scroll={{ x: true }} 
                  tableLayout="auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DepartmentModal  filterDepartment ={filterDepartment} departmentID={departmentID} companyID={companyID} setRenderState ={setRenderState}/>
      <DeleteModal Name="Delete Department" />
    </>
  );
};

export default Department;
