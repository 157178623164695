/* eslint-disable no-unused-expressions */

import { Table } from "antd";
import { AddHoliday } from "../../../components/modelpopup/AddHoliday";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import Breadcrumbs from "../../../components/Breadcrumbs";
import config from "../../../config";
import { formatDateInTimezone } from "../../../utils/formatDateInTimezone";
import { companyID } from './../../../auth';

const Holidays = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const company_id = companyID();
    const date = new Date();
    const currentYear = date.getFullYear();
    axios
      .get(`${config.API_BASE_URL}holidays/${company_id}/${currentYear}`)
      .then((res) => setUsers(res.data))
      .catch((err) => console.log(err));
  }, []);

  const userElements = users.map((user, index) => ({
    key: index,
    id: index + 1,
    Title: user.occasion,
    HolidayDate: formatDateInTimezone(user.date),
    Day: user.day,
  }));

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Title",
      dataIndex: "Title",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.Title.length - b.Title.length,
    },
    {
      title: "HolidayDate",
      dataIndex: "HolidayDate",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.HolidayDate.length - b.HolidayDate.length,
    },
    {
      title: "Day",
      dataIndex: "Day",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.Day.length - b.Day.length,
    },
    // {
    //   title: "Action",
    //   render: () => (
    //     <div className="dropdown dropdown-action ">
    //       <Link
    //         to="#"
    //         className="action-icon dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i className="material-icons">more_vert</i>
    //       </Link>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <Link
    //           className="dropdown-item"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#edit_holiday"
    //         >
    //           <i className="fa fa-pencil m-r-5" /> Edit
    //         </Link>
    //         <Link
    //           className="dropdown-item"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#delete"
    //         >
    //           <i className="fa-regular fa-trash-can m-r-5" /> Delete
    //         </Link>
    //       </div>
    //     </div>
    //   ),
    //   sorter: true,
    // },
  ];

  const location = useLocation();

  console.log(
    'location.pathname.includes("leave-dashboard")" : ',
    location.pathname.includes("leave-dashboard"),
    " : ",
    location.pathname
  );

  return (
    <>
      <div
        className={
          location.pathname.includes("leave-dashboard") ? "" : "page-wrapper"
        }
      >
        {/* Page Content */}
        <div
          className={
            location.pathname.includes("leave-dashboard")
              ? ""
              : "content container-fluid"
          }
        >
          {!location.pathname.includes("leave-dashboard") && (
            <Breadcrumbs
              maintitle="Holidays"
              title="Dashboard"
              subtitle="Holidays"
              // modal="#add_holiday"
              // name="Add Holiday"
            />
          )}

          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={userElements?.length > 0 ? userElements : []}
                  className="table-striped"
                  rowKey={(record) => record.id}
                   scroll={{ x: true }} 
                  tableLayout="auto"
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      <AddHoliday />
      <DeleteModal Name="Delete Holiday" />
    </>
  );
};

export default Holidays;
