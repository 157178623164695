import React, { useState, useRef, useCallback } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./crop";
import { FiSave, FiPlusCircle, FiUpload, FiUser } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import axios from "axios";
import config from "../config";
import { useLocation, useNavigate } from "react-router-dom";
import DropZone from "./DropZone";

let token = localStorage.getItem("token");

const ImageCropper = ({
  setIsCropView,
  isCropView,
  croppedImage,
  setCroppedImage,
  employeeId,
  setEditedTrue,
  editTrue,
  setShowUploadOptions,
  showUploadOptions,
}) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImageFile, setCroppedImageFile] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImageFile = await getCroppedImg(imageSrc, croppedAreaPixels);
      setCroppedImageFile(croppedImageFile);
      setCroppedImage(URL.createObjectURL(croppedImageFile));
      setIsCropView(false);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, setIsCropView]);

  const onFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.addEventListener("load", () => setImageSrc(reader.result));
      setShowUploadOptions(false);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const updatePhoto = (id) => {
    try {
      const profilePhoto = new FormData();
      profilePhoto.append("photo", croppedImageFile);

      axios
        .put(
          `${config.API_BASE_URL}employees/updateEmployeePhoto/${id}`,
          profilePhoto,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          console.log("Profile Photo Updated");
          setEditedTrue(!editTrue);
          navigate(`../${location.pathname}`);
          // console.log("route : ", location.pathname);
        })
        .catch((err) => {
          console.error("Error while updating Profile photo : ", err);
        });
    } catch (error) {
      console.log("Getting error while updating Profile photo : ", error);
    }
  };

  const handleDrop = (acceptedFiles) => {
    console.log(" acceptedFiles ", acceptedFiles[0]);
    if (acceptedFiles && acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.addEventListener("load", () => setImageSrc(reader.result));
      setShowUploadOptions(false);
    }
  };

  return (
    <div className="profile-photo-editor">
      {imageSrc && !croppedImage && !showUploadOptions ? (
        <div className="crop-container">
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            cropShape="round"
            showGrid={false}
          />
        </div>
      ) : (
        !(croppedImage && !isCropView) &&
        showUploadOptions && <DropZone onDrop={handleDrop} />
      )}
      <div className="d-flex gap-2" style={{ width: "100%" }}>
        {isCropView && showUploadOptions && (
          <div
            className="upload-section save-button"
            onClick={handleUploadClick}
            style={{ width: "100%" }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={onFileChange}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
            <FiUpload size={22} style={{ cursor: "pointer" }} />
            {imageSrc && !croppedImage ? "Change picture" : "Upload picture"}
          </div>
        )}

        {imageSrc && !croppedImage && !showUploadOptions && (
          <button
            className="save-button flex-grow-1"
            onClick={showCroppedImage}
          >
            <CgProfile size={22} style={{ cursor: "pointer" }} />
            Preview
          </button>
        )}
      </div>
      {croppedImage && !isCropView && (
        <>
          <div className="cropped-image-preview">
            <img
              src={croppedImage}
              alt="Cropped"
              style={{ borderRadius: "50%", marginTop: "5px" }}
            />
            {/* <button onClick={() => updatePhoto(employeeId)}>Update</button> */}
          </div>
          <div className="full-width-button-container mt-2">
            <button
              type="button"
              className="btn btn-primary full-width-button"
              onClick={() => updatePhoto(employeeId)}
            >
              Update profile
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageCropper;
