import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import AddDesingnationModelPopup from "../../../components/modelpopup/AddDesingnationModelPopup";
import SearchBox from "../../../components/SearchBox";
import { base_url } from "../../../base_urls";
import config from './../../../config';
import { companyID } from "../../../auth";
import ProtectedButton from "../../../Routes/Approuter/ProtectedButton";
import { useRole } from "../../../context/RoleContext";

const Designation = () => {
  const [paginationValue , setPaginationValue] = useState(10)
  const [users, setUsers] = useState([]);
  const [department , setDepartment] = useState([]);
  const [filteredData , setFilteredData] = useState([]);
  const [deletedID , setDeletedID] = useState("");
  const company_id = companyID();

  const { role,rolesConfig } = useRole();

  const rolePermissions = rolesConfig[role]?.pages['designations'] || [];
  


  useEffect(() => {
    
    axios
      .get(`${config.API_BASE_URL}department/department-designation/${company_id}`)
      .then((res) => setUsers(res?.data?.result)).catch(err=>console.log(err))
  }, []);

  useEffect(()=>{
    
    axios.get(`${config.API_BASE_URL}department/${company_id}`).then((res)=>setDepartment( res?.data?.result)).catch((err)=> console.log(err));
  },[])

  const userElements = users.map((user, index) => ({
    key:  user.designation_id,
    id: index + 1,
    department: user.department_name,
    designation: user.designation_name,
  }));


  const handleEditDesignation = (id)=>{
   const data = users.filter((item, _)=> item.designation_id == id);
   setFilteredData( data);
  }


 const handleDeleteDesignation = async(id)=>{
  console.log("deleted id" , id);
  setDeletedID(id);
  try{
    const res = await axios.delete(`${config.API_BASE_URL}department/deleteDesignation/${id}`);
    console.log("Updating delete Response" , res);
  }catch(err){
    console.log(err);
  }
 }
 
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id.length - b.id.length,
      width: "10%",
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.department.length - b.department.length,
      width: "40%",
    },
    {
      title: "Designation",
      dataIndex: "designation",
      sorter: (a, b) => a.designation.length - b.designation.length,
      width: "40%",
    },

    ...(rolePermissions.includes('edit','delete')
      ? [
        {
          title: "Action",
          className: "text-end",
          render: (record) => (
            <div className="dropdown dropdown-action text-end">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="material-icons">more_vert</i>
              </Link>
              <div className="dropdown-menu dropdown-menu-right">
              <ProtectedButton page="designations" action="edit">
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#edit_designation"
                  onClick={()=>handleEditDesignation(record.key)}
                  
                >
                  <i className="fa fa-pencil m-r-5"/> Edit
                </Link>
                </ProtectedButton>
                <ProtectedButton page="designations" action="delete">
                <Link
                  className="dropdown-item"
                  to="#"
                  // data-bs-toggle="modal"
                  // data-bs-target="#delete"
                  onClick={()=>
                    handleDeleteDesignation(record.key)}
                >
                  <i className="fa fa-trash m-r-5"  /> Delete
                </Link>
                </ProtectedButton>
              </div>
            </div>
          ),
          // sorter: (a, b) => a.length - b.length,
          width: "10%",
        }
        ]
      : []),
  ];

  const designation = department.map((item ,index)=>{
    return ({
      value:item.id,
      label:item.department_name,
    })
  })


  
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Designations"
            title="Dashboard"
            subtitle="Designations"
            modal="#add_designation"
            name="Add  Designation"
            page='designations'
            action='create'
          />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox  setPaginationValue = {setPaginationValue}/>
                <Table
                  columns={columns}
                  dataSource={userElements?.length > 0 ? userElements : []}
                  className="table-striped"
                  rowKey={(record) => record.id}
                  pagination={{ pageSize: paginationValue}}
                   scroll={{ x: true }} 
                  tableLayout="auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddDesingnationModelPopup designation = {designation} company_id ={company_id} filteredData={filteredData}/>
      <DeleteModal Name="Delete Designation" />
    </div>
  );
};

export default Designation;
