import axios from "axios";
import config from "../config";

export const getAllExpenses = async (id) => {
  try {
    const response = await axios.get(
      `${config.API_BASE_URL}api/reimbursements/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching expenses:", error);
    throw error;
  }
};
