import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../../../config";

const SalaryStructures = () => {
  const [grossSalary, setGrossSalary] = useState(0);
  const [salaryData, setSalaryData] = useState({
    basicSalary: 0,
    houseRentAllowance: 0,
    flexibleComponents: 0,
    medicalAllowance: 0,
    statutoryBonus: 0,
    otherAllowance: 0,
  });

  useEffect(() => {
    fetchGrossSalary();
  }, []);

  const fetchGrossSalary = async () => {
    try {
      const employeeId = 1; 
      const response = await axios.get(`${config.API_BASE_URL}api/get-gross-salary/${employeeId}`);
      const data = response.data;

      setGrossSalary(data.grossSalary);
      calculateSalaryComponents(data.grossSalary);
    } catch (error) {
      console.error("Error fetching gross salary:", error);
    }
  };

  const calculateSalaryComponents = (grossSalary) => {
    const houseRentAllowance = 3000;
    const flexibleComponents = 0;
    const medicalAllowance = 375;
    const statutoryBonus = 1500;
    const otherAllowance = 1125;

    const basicSalary = grossSalary - (houseRentAllowance + flexibleComponents + medicalAllowance + statutoryBonus + otherAllowance);

    setSalaryData({
      basicSalary,
      houseRentAllowance,
      flexibleComponents,
      medicalAllowance,
      statutoryBonus,
      otherAllowance,
    });
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h4 className="payslip-title">Salary Structure</h4>
                <div className="row">
                  <div className="col-lg-12 m-b-20">
                    <h4 className="m-b-10"><strong>Earnings</strong></h4>
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td><strong>Basic Salary</strong> <span className="float-end">${salaryData.basicSalary.toFixed(2)}</span></td>
                        </tr>
                        <tr>
                          <td><strong>House Rent Allowance</strong> <span className="float-end">${salaryData.houseRentAllowance.toFixed(2)}</span></td>
                        </tr>
                        <tr>
                          <td><strong>Flexible Components</strong> <span className="float-end">${salaryData.flexibleComponents.toFixed(2)}</span></td>
                        </tr>
                        <tr>
                          <td><strong>Medical Allowance</strong> <span className="float-end">${salaryData.medicalAllowance.toFixed(2)}</span></td>
                        </tr>
                        <tr>
                          <td><strong>Statutory Bonus</strong> <span className="float-end">${salaryData.statutoryBonus.toFixed(2)}</span></td>
                        </tr>
                        <tr>
                          <td><strong>Other Allowance</strong> <span className="float-end">${salaryData.otherAllowance.toFixed(2)}</span></td>
                        </tr>
                        <tr>
                          <td><strong>Gross Salary</strong> <span className="float-end">${grossSalary.toFixed(2)}</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryStructures;
