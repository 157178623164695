export const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export function capitalizeWords(str) {
  return str?.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
}

export const attendanceByColor = (punchIn, punchOut, date) => {

  console.log("response from perticular data attendance", punchIn , punchOut , date);
  const office_start_time = "09:00:00";
  const office_end_time = "17:00:00";
  const office_is_flexible = 0;
  const office_flexible_hours = null;
  const flexible_range = office_flexible_hours * 60 * 60 * 1000;

  const officeStartTime = new Date(`${date}T${office_start_time}`);
  const officeEndTime = new Date(`${date}T${office_end_time}`);

  const timeDiffMs = officeEndTime - officeStartTime;
  const companyWorkingHours = timeDiffMs / (1000 * 60 * 60);
  const midWorkingHours = companyWorkingHours / 2;
  // if (!punchIn) {
  //   return `#e0462b`;
  // }
  if(punchIn== null && punchOut== null){
    return `#e0462b`
  }
  if (punchIn) {
    const punchInTime = new Date(punchIn);
    let punchOutTime = punchOut ? new Date(punchOut) : null;

    const punchInDate = punchInTime.toISOString().split("T")[0];
    const punchOutDate = punchOutTime
      ? punchOutTime.toISOString().split("T")[0]
      : null;

    const currentDate = new Date().toISOString().split("T")[0];
    if (!punchOutTime && punchInDate !== currentDate) {
      return "#e0462b";
    }
    if (punchOutTime && punchOutDate === currentDate) {
      return "#0000FF";
    }

    if (!punchOutTime) {
      punchOutTime = new Date();
      const currentTimeDate = punchOutTime.toISOString().split("T")[0];
      if (punchInDate === date && currentTimeDate === date) {
        return "#0000FF";
      } else {
        return "red";
      }
    }

    const workedTimeDiffMs = punchOutTime - punchInTime;
    const workedHours = workedTimeDiffMs / (1000 * 60 * 60);

    if (office_is_flexible === 1) {
      if (
        punchInTime <= officeStartTime &&
        punchInTime >= officeStartTime - flexible_range
      ) {
        const expectedEndTime = new Date(
          punchInTime.getTime() + companyWorkingHours * 60 * 60 * 1000
        );
        if (punchOutTime >= expectedEndTime) {
          return "#2aad40";
        } else if (workedHours >= midWorkingHours) {
          return "#e0e327";
        } else {
          return "#e0462b";
        }
      } else if (punchInTime > officeStartTime) {
        if (punchInTime - officeStartTime <= flexible_range) {
          const expectedEndTime = new Date(
            punchInTime.getTime() + companyWorkingHours * 60 * 60 * 1000
          );

          const workedHours = (punchOutTime - punchInTime) / (1000 * 60 * 60);

          if (workedHours >= companyWorkingHours) {
            return "#2aad40";
          } else if (workedHours >= midWorkingHours) {
            return "#e0e327";
          } else {
            return "#e0462b";
          }
        } else {
          const expectedEndTime = new Date(
            punchInTime.getTime() + companyWorkingHours * 60 * 60 * 1000
          );
          const workedHours = (punchOutTime - punchInTime) / (1000 * 60 * 60);

          if (workedHours >= companyWorkingHours) {
            return "#2aad40";
          } else if (workedHours >= midWorkingHours) {
            return "#e0e327";
          } else {
            return "#e0462b";
          }
        }
      }
    } else {
      if (punchInTime <= officeStartTime) {
        const expectedEndTime = new Date(officeEndTime.getTime());
        if (punchOutTime >= expectedEndTime) {
          return "#2aad40";
        } else if (workedHours >= midWorkingHours) {
          return "#e0e327";
        } else {
          return "#e0462b";
        }
      } else if (
        punchInTime > officeStartTime &&
        punchInTime - officeStartTime <= 10 * 60 * 1000
      ) {
        const expectedEndTime = new Date(officeEndTime.getTime());
        if (punchOutTime >= expectedEndTime) {
          return "#2aad40";
        } else if (workedHours >= midWorkingHours) {
          return "#e0e327";
        } else {
          return "#e0462b";
        }
      } else if (punchInTime > officeStartTime) {
        if (
          workedHours >= midWorkingHours &&
          punchInTime - officeStartTime >= 10 * 60 * 1000
        ) {
          return "#e0e327"; // Yellow for more than mid-working hours
        } else {
          return "#e0462b"; // Red for less than mid-working hours
        }
      } else {
        return "#e0462b";
      }
    }
  }

  return null;
};


export const attendanceStatusForHideApproval = (punchIn, punchOut, date) => {
  console.log("response from perticular data attendance", punchIn , punchOut , date);

  // Define office timings
  const office_start_time = "09:00:00";
  const office_end_time = "17:00:00";
  const office_is_flexible = 0; // Flexible hours flag
  const office_flexible_hours = null; // If flexible hours are enabled
  const flexible_range = office_flexible_hours * 60 * 60 * 1000;

  // Parse office start and end time with the provided date
  const officeStartTime = new Date(`${date}T${office_start_time}`);
  const officeEndTime = new Date(`${date}T${office_end_time}`);

  const timeDiffMs = officeEndTime - officeStartTime;
  const companyWorkingHours = timeDiffMs / (1000 * 60 * 60); // Convert ms to hours
  const midWorkingHours = companyWorkingHours / 2; // Mid working hours threshold

  // If no punchIn provided, return false
  if (!punchIn) {
    return false;
  }

  const punchInTime = new Date(punchIn);
  let punchOutTime = punchOut ? new Date(punchOut) : null;

  const punchInDate = punchInTime.toISOString().split("T")[0];
  const punchOutDate = punchOutTime ? punchOutTime.toISOString().split("T")[0] : null;
  const currentDate = new Date().toISOString().split("T")[0];

  // If punchIn date is not today and no punchOut, return false (incomplete attendance)
  if (!punchOutTime && punchInDate !== currentDate) {
    return false;
  }

  // If punchOut is today but punchOut is missing, return false (still in office)
  if (punchOutTime && punchOutDate === currentDate) {
    return false;
  }

  // If punchOut is missing but the punchIn is today, return false
  if (!punchOutTime) {
    punchOutTime = new Date();
    const currentTimeDate = punchOutTime.toISOString().split("T")[0];
    if (punchInDate === date && currentTimeDate === date) {
      return false;
    } else {
      return false;
    }
  }

  // Calculate the worked hours
  const workedTimeDiffMs = punchOutTime - punchInTime;
  const workedHours = workedTimeDiffMs / (1000 * 60 * 60); // Convert ms to hours

  if (office_is_flexible === 1) {
    // Handle flexible office hours
    if (
      punchInTime <= officeStartTime &&
      punchInTime >= officeStartTime - flexible_range
    ) {
      const expectedEndTime = new Date(
        punchInTime.getTime() + companyWorkingHours * 60 * 60 * 1000
      );
      if (punchOutTime >= expectedEndTime) {
        return true; // Green color equivalent
      } else if (workedHours >= midWorkingHours) {
        return false;
      } else {
        return false;
      }
    } else if (punchInTime > officeStartTime) {
      const expectedEndTime = new Date(
        punchInTime.getTime() + companyWorkingHours * 60 * 60 * 1000
      );
      if (workedHours >= companyWorkingHours) {
        return true; // Green color equivalent
      } else if (workedHours >= midWorkingHours) {
        return false;
      } else {
        return false;
      }
    }
  } else {
    // Handle non-flexible office hours
    if (punchInTime <= officeStartTime) {
      const expectedEndTime = new Date(officeEndTime.getTime());
      if (punchOutTime >= expectedEndTime) {
        return true; // Green color equivalent
      } else if (workedHours >= midWorkingHours) {
        return false;
      } else {
        return false;
      }
    } else if (
      punchInTime > officeStartTime &&
      punchInTime - officeStartTime <= 10 * 60 * 1000
    ) {
      const expectedEndTime = new Date(officeEndTime.getTime());
      if (punchOutTime >= expectedEndTime) {
        return true; // Green color equivalent
      } else if (workedHours >= midWorkingHours) {
        return false;
      } else {
        return false;
      }
    } else if (punchInTime > officeStartTime) {
      if (
        workedHours >= midWorkingHours &&
        punchInTime - officeStartTime >= 10 * 60 * 1000
      ) {
        return false; // Yellow color equivalent
      } else {
        return false; // Red color equivalent
      }
    }
  }

  // Default return false
  return false;
};



export const calculateTotalPresentDays = (attendanceData) => {
  let totalPresentDays = 0;

  attendanceData.forEach((entry) => {
    const { punchIn, punchOut, date , approved_full_day} = entry;

    // Check if attendance status function returns true (indicating full attendance)

   if(approved_full_day === 1){
    totalPresentDays++;
   }
   else{
    console.log("totalPresentDays" , punchIn , punchOut, date)
    const isPresent = attendanceByColor(punchIn, punchOut, date);
    
    if (isPresent ==='#2aad40') {
      totalPresentDays++;
    }else if( isPresent === '#e0e327'){
      totalPresentDays += 0.5;
    }
   }
  });

  console.log("totalPresentDays" , totalPresentDays);

  return totalPresentDays;
};

export function removeWeekends(dates) {
  return dates.filter(dateStr => {
      const date = new Date(dateStr);
      const day = date.getDay();
      // getDay() returns 0 for Sunday and 6 for Saturday
      return day !== 0 && day !== 6;
  });
}



export const calculateTotalAbsentDaysFromMonth=(attendanceData)=>{
  console.log("totalAbsentDays" , attendanceData)
  let totaltAbsentDate=[];
  attendanceData.forEach((entry) => {
    const { punch_Out, punch_In, date , marked_full_day_attendance} = entry;
    console.log("totalAbsentDays" , punch_In , punch_Out, date)
    const isPresent = attendanceByColor(punch_In, punch_Out, date);
 
    if (isPresent ==='#e0462b' && !marked_full_day_attendance ==1) {
      totaltAbsentDate.push(date);
    }else if( isPresent === '#e0e327' && !marked_full_day_attendance ==1){
      totaltAbsentDate.push(date);
    }
   console.log("totalAbsentDays" , totaltAbsentDate); 
  });
  return totaltAbsentDate;

}

export function removeHolidays(attendanceDates, holidays) {
  const holidayDates = new Set(holidays.map(holiday => {
      const holidayDate = new Date(holiday.date);
      return holidayDate.toLocaleDateString('en-CA'); 
  }));

  return attendanceDates.filter(date => !holidayDates.has(date));
}

export function formatToEnCA(dateString) {
  const date = new Date(dateString);
  const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
  };
  return date.toLocaleDateString('en-CA', options);
}

export function filterAttendanceDates(attendanceDates, leaves) {
  // Helper function to get an array of dates between two dates
  const getDateRange = (startDate, endDate) => {
      let dates = [];
      let currentDate = new Date(startDate);
      const end = new Date(endDate);

      while (currentDate <= end) {
          dates.push(formatToEnCA(currentDate.toISOString())); // Push in YYYY-MM-DD format
          currentDate.setDate(currentDate.getDate() + 1);
      }
      console.log("date datas" ,dates )
      return dates;
  };

  // Get all leave dates where leave_status is 2
  let leaveDates = new Set();
  leaves.forEach(leave => {
      if (leave.leave_status === 2) {
          const start = new Date(leave.start_date);
          const end = new Date(leave.end_date);
          const range = getDateRange(start, end);
          range.forEach(date => leaveDates.add(date));
      }
  });

  // Filter attendance dates
  const filteredDates = attendanceDates.filter(date => !leaveDates.has(date));

  return filteredDates;
}

