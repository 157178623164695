import axios from 'axios';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useAxiosInterceptor = () => {
  const navigate = useNavigate();
  const location = useLocation();
//   console.log("location" , location);

  useEffect(() => {
    const responseInterceptor = axios?.interceptors?.response?.use(
      (response) => response,
      (error) => {
        console.error('Error response:', error?.response); // Add logging for debugging
        if (error?.response && error?.response?.status === 401) {
          // Token expired or unauthorized
          console.log('Token expired or unauthorized'); // Add logging for debugging
          localStorage.removeItem('token');
          localStorage.removeItem('token_expiration');
          localStorage.removeItem('user');
          localStorage.removeItem('invalidUser');
          localStorage.removeItem('companyName');
          localStorage.removeItem('employeeID');
          navigate('../');
        }
        return Promise.reject(error);
      }
    );

    // Cleanup interceptor on component unmount
    return () => {
      axios?.interceptors?.response?.eject(responseInterceptor);
    };
  }, [location?.pathname]);
};

export default useAxiosInterceptor;
