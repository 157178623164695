import React, { createContext, useContext, useEffect, useState } from 'react';
// import { rolesConfig } from '../Routes/rolesConfig';
import { getUserPermissions } from '../services/permissionServices';

const RoleContext = createContext();

export const RoleProvider = ({ children, role }) => {

  const [rolesConfig, setRolesConfig] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log('rolesConfigNit : ',rolesConfig);
  
  let role_id = localStorage.getItem("employee_role");

  const permissions = rolesConfig[role];

  console.log(' permissions : ',permissions);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const data = await getUserPermissions(role_id);
        setRolesConfig(data.rolesConfig);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch permissions');
        setLoading(false);
      }
    };

    fetchPermissions();
  }, [role_id]);

  return (
    <RoleContext.Provider value={{ role, permissions,rolesConfig }}>
      {children}
    </RoleContext.Provider>
  );
};

export const useRole = () => useContext(RoleContext);
