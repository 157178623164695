import { Navigate } from "react-router-dom";
import { rolePermissions } from "../rolePermissions";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
// import { rolesConfig } from "../rolesConfig";
import { useRole } from "../../context/RoleContext";

const ProtectedRoute = ({ element, path }) => {

    const roleId = localStorage.getItem("employee_role");
    const [userRole, setUserRole] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const { rolesConfig } = useRole();

    console.log(' userRole : ', userRole);

  useEffect(()=>{
    axios.get(`${config.API_BASE_URL}employeeRole/${roleId}`)
    .then((res)=> setUserRole(res?.data[0].role_name))
    .catch(err=>console.log('Employee role error : ',err))
    .finally(()=>{console.log('Employee Role Wise Api call completed.');
        setIsLoading(false);
    });
  },[roleId])

  if(isLoading){
    return null;
  }
  // else if (rolePermissions[userRole]?.includes(path)) {
  else if (rolesConfig[userRole]?.pages[path]) {
    return element;
  }else{
    return <Navigate to="/error-403" />;
  }

};

export default ProtectedRoute;