import React, { useEffect, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css"; // Core styles
import "@react-pdf-viewer/default-layout/lib/styles/index.css"; // Layout styles
import "../../../assets/css/imageModal.css";

const PdfPreviewer = ({ viewPdfFile, closeModalHandler }) => {
  const [file, setFile] = useState(null);

  const onFileChange = (viewPdfFile) => {
    setFile(null);

    if (viewPdfFile) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        setFile(e.target.result); // Load the new file after resetting
      };
      reader.readAsDataURL(viewPdfFile);
    }
  };

  useEffect(() => {
    onFileChange(viewPdfFile);
  }, [viewPdfFile]);

  return (
    <div className="modal-overlay modal-overlay-handle" >
      <span
        className="close-button close-button-handler"
        onClick={closeModalHandler}
      >
        &times;
      </span>
      <div
        className="modal-content modal-content-handler"
        onClick={(e) => e.stopPropagation()}
        style={{width: "100%"}}
      >
        {file ? (
          <div style={{height: "500px", marginTop: "20px" }}>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
              <Viewer
                fileUrl={file}
                style={{ width: "100%", height: "100%" }} // Responsive width and height
              />
            </Worker>
          </div>
        ) : (
          <p>Please select a PDF to preview</p>
        )}
      </div>
    </div>
  );
};

export default PdfPreviewer;
