import React, { useState } from "react";
import { Link } from "react-router-dom";
import AttendenceModelPopup from "../../../components/modelpopup/AttendenceModelPopup";
import User from "../../../assets/img/icons/user3.png";
import axios from "axios";
import config from "../../../config";
// import "../../../assets/css/pagination.css"
import SearchBox from "./../../../components/SearchBox";
import { attendanceByColor, attendanceStatusForHideApproval } from "../../../utils/basicFunctions";
const TableAvatar = ({
  uniqueDatesForMonth,
  transformedData,
  paginationValue,
  paginatedData,
  setRenderAttendanceData,
}) => {
  const [employeeAttendanceDetail, setEmployeeAttendanceDetail] = useState({});
  const [activityDetail, setActivityDetail] = useState([]);
  const [currentDate, setCurrentDate] = useState("");
  const [noDetails, setNoDetails] = useState([]);

  // const [pageSize, setPageSize] = useState(10); // Rows per page

  const extractDayFromDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    return day;
  };

  const [colorCode, setColorCode] = useState("");
  const [isHiddenApprovalButton, setIsHiddenApprovalButton] = useState(false);
  const employeeAttendanceHandler = async (date, employee_id, color , first_name , last_name , isHideAprroval) => {
    setNoDetails([]);
    setEmployeeAttendanceDetail({});
    setActivityDetail([]);
    setColorCode(color);
    setCurrentDate(date);
    setIsHiddenApprovalButton(isHideAprroval);
    const response = await axios.get(
      `${config.API_BASE_URL}api/attendance/attendace/${employee_id}/${date}`
    );
    console.log("response data of absent employee" ,response );
    if (response?.data?.result?.length > 0) {
      setEmployeeAttendanceDetail({...response?.data?.result ,first_name, last_name });
    } else {
      setNoDetails((prev) => [...prev, date, employee_id , first_name , last_name]);
    }
    if (response?.data?.results?.length > 0) {
      console.log("activity detail s  2", response?.data?.results);
      setActivityDetail(response?.data?.results);
    }
  };

  console.log("activity details noDetails", noDetails);

  const transformActivityLog = (activityLog) => {
    const groupedData = activityLog.reduce((acc, curr) => {
      const found = acc.find((item) => item.employee_id === curr.employee_id);
      if (found) {
        found.activityStatus.push({
          activity_time: curr.activity_time,
          activity_type: curr.activity_type,
        });
      } else {
        acc.push({
          employee_id: curr.employee_id,
          activityStatus: [
            {
              activity_time: curr.activity_time,
              activity_type: curr.activity_type,
            },
          ],
        });
      }
      return acc;
    }, []);

    return groupedData;
  };

  // Transform the activity log
  const activityTransformed = transformActivityLog(activityDetail);

  console.log("activity transformed", paginatedData);

  return (
    <>
      <div>
        <table className="table table-striped custom-table table-nowrap mb-0">
          <thead>
            <tr>
              <th className="sticky-column">Employee</th>
              {uniqueDatesForMonth?.map((entry, index) => (
                <th key={index}>{extractDayFromDate(entry)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {
              paginatedData.length > 0 &&
                paginatedData?.map((data, index) => (
                  <tr>
                    <td key={index} className="sticky-column">
                      <h2 className="table-avatar">
                        <span className="avatar avatar-xs">
                          <img
                            alt=""
                            src={
                              data?.photo
                                ? ` ${config.IMAGE_URL}${data?.photo}`
                                : User
                            }
                          />
                        </span>
                        <>
                          {data.first_name} {data.last_name}
                        </>
                      </h2>
                    </td>
                    {data.dateDescription.map((item, index) => {
                      return (
                        <td key={index}>
                          {(item.status == "Absent") ? (
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#attendance_info"
                              onClick={() =>
                                employeeAttendanceHandler(
                                  item.date,
                                  data.employee_id,
                                  item?.color,
                                  data?.first_name,
                                  data?.last_name,
                                  item?.isHideAprroval
                                )
                              }
                            >
                              <i className="fa fa-close text-danger" />
                            </Link>
                          ) : item.status == "Present" ? (
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#attendance_info"
                              onClick={() =>
                                employeeAttendanceHandler(
                                  item.date,
                                  data.employee_id,
                                  item?.color,
                                  data?.first_name,
                                  data?.last_name,
                                  item?.isHideAprroval
                                )
                              }
                            >
                              <i
                                className="fa fa-check"
                                style={{
                                  color: `${
                                    item?.approved_full_day == 1
                                      ? "#2aad40"
                                      : item?.color
                                      ? item?.color
                                      : ""
                                  }`,
                                }}
                              />
                            </Link>
                          ) : (
                            <span>..</span>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))
              //  :   <tr><div className="" style={{zIndex:"1000"}}>No Attendance  Found</div></tr>
            }
          </tbody>
        </table>

        <AttendenceModelPopup
          activityTransformed={activityTransformed}
          employeeAttendanceDetail={employeeAttendanceDetail}
          currentDate={currentDate}
          activityDetail={activityDetail}
          colorCode={colorCode}
          setEmployeeAttendanceDetail={setEmployeeAttendanceDetail}
          setActivityDetail={setActivityDetail}
          setColorCode={setColorCode}
          setCurrentDate={setCurrentDate}
          setRenderAttendanceData={setRenderAttendanceData}
          noDetails={noDetails}
          setNoDetails={setNoDetails}
          isHiddenApprovalButton = {isHiddenApprovalButton}
        />
      </div>

     

      
      
    </>
  );
};
export default TableAvatar;
