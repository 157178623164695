export const  companyData = [
    {
        id: 1,
        company_name: "Inox Software",
        colour: "blue",
    },
    // {
    //     id: 2,
    //     company_name: "Inox Software",
    //     colour: "maroon",
    // },
    // {
    //     id: 1,
    //     company_name: "Inox Software",
    //     colour: "orange",
    // }
]