import axios from "axios";
import config from "../config";

export const getAllIncreament = async (id) => {
  try {
    const response = await axios.get(
      `${config.API_BASE_URL}mypays/employee/${id}/increments`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Increament:", error);
    throw error;
  }
};
