import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  avatar1,
  avatar19,
  avatar20,
  avatar21,
  company_icon_01,
  profileuploadimg,
} from "../../Routes/ImagePath";
import Select from "react-select";
import axios from "axios";
import config from "../../config";
import * as Yup from "yup";
import createCustomSchema from "../../fieldValidator/fieldValidator";

const CompaniesModal = ({
  company,
  setCompany,
  addresses,
  setAddresses,
  setIsModalOpen,
  isModalOpen,
}) => {
  const options = {
    allFieldsOptions: [
      { value: "allFields", label: "All Fields" },
      { value: "contact", label: "Contact" },
      { value: "company", label: "Company" },
    ],
    selectType: [
      { value: "Private Limited Company", label: "Private Limited Company" },
      { value: "Public Limited Company", label: "Public Limited Company" },
      { value: "Sole Proprietorship", label: "Sole Proprietorship" },
      { value: "One Person Company", label: "One Person Company" },
      { value: "Partnership", label: "Partnership" },
      {
        value: "Limited Liability Partnership",
        label: "Limited Liability Partnership",
      },
      { value: "Joint-Venture Company", label: "Joint-Venture Company" },
      {
        value: "Non-Government Organization",
        label: "Non-Government Organization",
      },
    ],
    selectOptions: [
      { value: "select", label: "Select" },
      { value: "hendry", label: "Hendry" },
      { value: "guillory", label: "Guillory" },
      { value: "jami", label: "Jami" },
    ],
    lastNameOptions: [
      { value: "select", label: "Select" },
      { value: "collins", label: "Collins" },
      { value: "konopelski", label: "Konopelski" },
      { value: "adams", label: "Adams" },
    ],
    anotherSelectOptions: [
      { value: "select", label: "Select" },
      { value: "barryCuda", label: "Barry Cuda" },
      { value: "tressaWexler", label: "Tressa Wexler" },
    ],
    communicationOptions: [
      { value: "select", label: "Select" },
      { value: "email", label: "Email" },
      { value: "call", label: "Call" },
      { value: "skype", label: "Skype" },
    ],
    currencyOptions: [
      { value: "select", label: "Select" },
      { value: "dollar", label: "$" },
      { value: "euro", label: "€" },
    ],
    languageOptions: [
      { value: "select", label: "Select" },
      { value: "english", label: "English" },
      { value: "french", label: "French" },
    ],
    countryOptions: [{ value: "India", label: "India" }],

    stateOptions: [
      { value: "andhra-pradesh", label: "Andhra Pradesh" },
      { value: "arunachal-pradesh", label: "Arunachal Pradesh" },
      { value: "assam", label: "Assam" },
      { value: "bihar", label: "Bihar" },
      { value: "chhattisgarh", label: "Chhattisgarh" },
      { value: "goa", label: "Goa" },
      { value: "gujarat", label: "Gujarat" },
      { value: "haryana", label: "Haryana" },
      { value: "himachal-pradesh", label: "Himachal Pradesh" },
      { value: "jammu-kashmir", label: "Jammu and Kashmir" },
      { value: "jharkhand", label: "Jharkhand" },
      { value: "karnataka", label: "Karnataka" },
      { value: "kerala", label: "Kerala" },
      { value: "madhya-pradesh", label: "Madhya Pradesh" },
      { value: "maharashtra", label: "Maharashtra" },
      { value: "manipur", label: "Manipur" },
      { value: "meghalaya", label: "Meghalaya" },
      { value: "mizoram", label: "Mizoram" },
      { value: "nagaland", label: "Nagaland" },
      { value: "odisha", label: "Odisha" },
      { value: "punjab", label: "Punjab" },
      { value: "rajasthan", label: "Rajasthan" },
      { value: "sikkim", label: "Sikkim" },
      { value: "tamil-nadu", label: "Tamil Nadu" },
      { value: "telangana", label: "Telangana" },
      { value: "tripura", label: "Tripura" },
      { value: "uttar-pradesh", label: "Uttar Pradesh" },
      { value: "uttarakhand", label: "Uttarakhand" },
      { value: "west-bengal", label: "West Bengal" },
    ],
  };

  const address = {
    address: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
  };

  const [fieldOne, setFieldOne] = useState(true);
  const [fieldTwo, setFieldTwo] = useState();
  const [fieldThree, setFieldThree] = useState();
  const [fieldFour, setFieldFour] = useState();
  const [companyError, setCompanyError] = useState("");
  const [errors, setErrors] = useState({
    presentAddress: {},
    permanentAddress: {},
  });
  const [image, setImage] = useState(null);
  const [companyId, setCompanyId] = useState("");
  const navigate = useNavigate();

  console.log(" companyId : ", companyId);

  // const [company, setCompany] = useState({
  //   company_logo: "",
  //   company_name: "",
  //   company_type: "",
  //   company_registration_number: "",
  //   date_of_incorporation: "",
  //   gst_number: "",
  //   pan: "",
  //   cin: "",
  //   tan: "",
  //   llp: "",
  //   phone: "",
  //   email: "",
  //   updated_by: "",
  //   created_by: "",
  // });

  // const [addresses, setAddresses] = useState({
  //   presentAddress: {
  //     ...address,
  //   },
  //   permanentAddress: {
  //     ...address,
  //   },
  //   isSame: false,
  // });

  console.log(" company logo : ", company);

  console.log(
    "addresses.presentAddress : ",
    addresses.presentAddress,
    " : ",
    "addresses.permanentAddress : ",
    addresses.permanentAddress
  );

  useEffect(() => {
    setCompany({
      ...company,
      created_by: localStorage.getItem("employeeID"),
      updated_by: localStorage.getItem("employeeID"),
    });
  }, []);

  const presentAddressOnchange = (type, field, value) => {
    setAddresses((prev) => {
      const updatedPresentAddress = {
        ...prev.presentAddress,
        [field]: value,
      };
      return {
        ...prev,
        presentAddress: updatedPresentAddress,
        permanentAddress: prev.isSame
          ? updatedPresentAddress
          : prev.permanentAddress,
      };
    });

    validateField(type, field, value);
  };

  const permanentAddressOnchange = (e) => {
    const { name, value } = e.target;
    setAddresses((prev) => ({
      ...prev,
      permanentAddress: {
        ...prev.permanentAddress,
        [name]: value,
      },
    }));
  };

  const handleAddressChange = (type, field, value) => {
    setAddresses((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [field]: value,
      },
    }));

    validateField(type, field, value);
  };

  const validateField = async (type, field, value) => {
    try {
      await validationSchemaAddress.validateAt(field, { [field]: value });
      setErrors((prevState) => {
        const updatedState = { ...prevState };
        if (updatedState[type]) {
          const { [field]: deletedKey, ...rest } = updatedState[type];
          updatedState[type] = rest;
        }
        return updatedState;
      });
    } catch (error) {
      setErrors((prevState) => ({
        ...prevState,
        [type]: {
          ...prevState[type],
          [field]: error.message,
        },
      }));
    }
  };

  const sameAddress = (e) => {
    const { checked } = e.target;
    setAddresses((prev) => ({
      ...prev,
      isSame: checked,
      permanentAddress: checked ? prev.presentAddress : { ...address },
    }));
  };

  const handleDropDownChange = (type, field) => (selectedOption) => {
    setAddresses((prev) => {
      const updatedAddress = {
        ...prev[type],
        [field]: selectedOption.value,
      };
      return {
        ...prev,
        [type]: updatedAddress,
        ...(prev.isSame && type === "presentAddress"
          ? { permanentAddress: updatedAddress }
          : {}),
      };
    });
    validateField(type, field, selectedOption.value);
  };

  const FieldsetOne = () => {
    setFieldOne(true);
    setFieldTwo(false);
    setFieldThree(false);
    setFieldFour(false);
  };

  const FieldsetTwo = () => {
    setFieldOne(false);
    setFieldTwo(true);
    setFieldThree(false);
    setFieldFour(false);
  };

  const FieldsetThree = () => {
    setFieldOne(false);
    setFieldTwo(false);
    setFieldThree(true);
    setFieldFour(false);
  };
  const FieldsetFour = () => {
    setFieldOne(false);
    setFieldTwo(false);
    setFieldThree(false);
    setFieldFour(true);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setCompany({ ...company, [name]: value });
  };

  const handleChange = (selectedOption) => {
    setCompany({
      ...company,
      company_type: selectedOption.value,
    });
  };

  const onSubmit = () => {
    console.log("company : ", company);

    const data = new FormData();
    Object.keys(company).forEach((key) => {
      console.log("key, company[key] : ", key, company[key]);
      data.append(key, company[key]);
    });

    axios
      .post(`${config.API_BASE_URL}api/company`, data)
      .then((response) => {
        console.log("Company created successfully:", response.data);
        setCompanyId(response.data.data.insertId);
        FieldsetTwo();
      })
      .catch((error) => {
        console.error("There was an error creating the company!", error);
      });
  };

  const onUpdate = (id) => {
    console.log("company : ", company);

    const data = new FormData();
    Object.keys(company).forEach((key) => {
      console.log("key, company[key] : ", key, company[key]);
      data.append(key, company[key]);
    });

    axios
      .put(`${config.API_BASE_URL}api/company-update/${id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          // Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Company updated successfully:", response.data);
        setCompanyId(response.data.data.insertId);
        FieldsetTwo();
      })
      .catch((error) => {
        console.error("There was an error creating the company!", error);
      });
  };

  const handleFileChange = (e) => {
    console.log("e.target : ", e.target.files);
    const { name, files } = e.target;
    const selectedFile = files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
    // setExpense({ ...expense, [name]: selectedFile });
    setCompany({
      ...company,
      [name]: selectedFile,
    });
  };

  const addressSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${config.API_BASE_URL}api/company-info/${companyId}`,
        addresses
      );
      console.log("Data submitted:", res.data);
      setIsModalOpen(false);
      navigate("../companies");
    } catch (err) {
      console.error("Error submitting data:", err);
    }
  };

  const validationSchema = Yup.object({
    company_logo: createCustomSchema("file", {
      message: "Upload company logo",
    }),
    company_name: createCustomSchema("text", {
      min: 3,
      message: "Enter Company name",
    }),
    company_type: createCustomSchema("dropdown"),
    company_registration_number: createCustomSchema("alphanumeric"),
    date_of_incorporation: createCustomSchema("date"),
    gst_number: createCustomSchema("alphanumeric"),
    pan: createCustomSchema("alphanumeric"),
    tan: createCustomSchema("alphanumeric"),
    cin: createCustomSchema("alphanumeric"),
    // llp: createCustomSchema("alphanumeric"),
    phone: createCustomSchema("phone"),
    email: createCustomSchema("email"),
    // address: createCustomSchema("address", { message: "Enter address" }),
    // city: createCustomSchema("text", {
    //   message: "Enter city name",
    //   min: 3,
    //   max: 6,
    // }),
    // pincode: createCustomSchema("number", { message: "Enter pincode", min: 6 }),
    // state: createCustomSchema("dropdown", { message: "Select state" }),
    // country: createCustomSchema("dropdown", { message: "Select country" }),
  });

  const validationSchemaAddress = Yup.object({
    address: createCustomSchema("address", { message: "Enter address" }),
    city: createCustomSchema("text", {
      message: "Enter city name",
      min: 3,
      max: 6,
    }),
    pincode: createCustomSchema("number", { message: "Enter pincode", min: 6 }),
    state: createCustomSchema("dropdown", { message: "Select state" }),
    country: createCustomSchema("dropdown", { message: "Select country" }),
  }); //fix me

  const addressValidationSchema = Yup.object().shape({
    presentAddress: Yup.object().shape({
      address: createCustomSchema("address", { message: "Enter address" }),
      city: createCustomSchema("text", { message: "Enter city name", min: 3 }),
      state: createCustomSchema("dropdown", { message: "Select state" }),
      pincode: createCustomSchema("number", {
        message: "Enter pincode",
        min: 6,
        max: 6,
      }),
      country: createCustomSchema("dropdown", { message: "Select country" }),
    }),
    permanentAddress: Yup.object().shape({
      address: createCustomSchema("address", { message: "Enter address" }),
      city: createCustomSchema("text", { message: "Enter city name", min: 3 }),
      state: createCustomSchema("dropdown", { message: "Select state" }),
      pincode: createCustomSchema("number", {
        message: "Enter pincode",
        min: 6,
        max: 6,
      }),
      country: createCustomSchema("dropdown", { message: "Select country" }),
    }),
  });

  const handleAddress = async (e) => {
    e.preventDefault();

    try {
      await addressValidationSchema.validate(addresses, { abortEarly: false });
      // setErrors({});
      // Form is valid, proceed with submission
      console.log("Form is valid", addresses);
      addressSubmit(e);
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        const path = error.path.split(".");
        if (path.length === 2) {
          validationErrors[path[0]] = {
            ...validationErrors[path[0]],
            [path[1]]: error.message,
          };
        }
      });

      console.log("Address validationErrors", validationErrors);

      setErrors(validationErrors);
    }
  };

  // console.log("Address Form submit error : ", errors);

  const onSubmitYup = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(company, { abortEarly: false });
      console.log("Form submit : ", company);
      setCompanyError("");

      if (company?.id) {
        console.log("Going for update");
        onUpdate(company.id);
      } else {
        onSubmit();
      }
    } catch (error) {
      const newError = {};
      error.inner.forEach((err) => {
        newError[err.path] = err.message;
      });
      setCompanyError(newError);
      console.log("yup error : ", newError);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {/* Add Company */}
      <div
        // className="modal custom-modal fade custom-modal-two modal-padding"
        className={`modal custom-modal fade custom-modal-two modal-padding ${
          isModalOpen ? "show d-block" : ""
        }`}
        id="add_company"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header header-border justify-content-between p-0">
              <h5 className="modal-title">Add New Company</h5>
              <button
                type="button"
                className="btn-close position-static"
                // data-bs-dismiss="modal"
                // aria-label="Close"
                onClick={closeModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="add-details-wizard">
                <ul id="progressbar" className="progress-bar-wizard">
                  <li
                    className={fieldOne ? "active" : ""}
                    onClick={() => FieldsetOne()}
                    style={{ cursor: "pointer" }}
                  >
                    <span>
                      <i className="la la-user-tie" />
                    </span>
                    <div className="multi-step-info">
                      <h6>Basic Info</h6>
                    </div>
                  </li>
                  <li
                    className={fieldTwo ? "active" : ""}
                    // onClick={() => FieldsetTwo()}
                    style={{ cursor: "pointer" }}
                  >
                    <span>
                      <i className="la la-map-marker" />
                    </span>
                    <div className="multi-step-info">
                      <h6>Address</h6>
                    </div>
                  </li>
                  {/* <li className={fieldThree ? "active" : ""}>
                    <div className="multi-step-icon">
                      <span>
                        <i className="la la-icons" />
                      </span>
                    </div>
                    <div className="multi-step-info">
                      <h6>Social Profiles</h6>
                    </div>
                  </li>
                  <li className={fieldFour ? "active" : ""}>
                    <div className="multi-step-icon">
                      <span>
                        <i className="la la-images" />
                      </span>
                    </div>
                    <div className="multi-step-info">
                      <h6>Access</h6>
                    </div>
                  </li> */}
                </ul>
              </div>
              <div className="add-info-fieldset">
                <fieldset
                  id="first-field"
                  style={{ display: fieldOne ? "block" : "none" }}
                >
                  {/* <form onSubmit={onSubmit}> */}
                  <form onSubmit={onSubmitYup}>
                    <div className="form-upload-profile">
                      <h6 className>
                        Profile Image <span> *</span>
                      </h6>
                      <div className="profile-pic-upload">
                        <div className="profile-pic">
                          <span>
                            <img
                              src={image ? image : profileuploadimg}
                              alt="Img"
                            />
                          </span>
                        </div>
                        <div className="employee-field">
                          <div className="mb-0">
                            <div className="image-upload mb-0">
                              <input
                                type="file"
                                name="company_logo"
                                onChange={handleFileChange}
                                style={{ cursor: "pointer" }}
                                //
                              />
                              <div className="image-uploads">
                                <h4>Upload</h4>
                              </div>
                            </div>
                          </div>
                          {/* <div className="img-reset-btn">
                            <Link to="#">Reset</Link>
                          </div> */}
                          {companyError.company_logo && (
                            <span className="text-danger">
                              *{companyError.company_logo}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="contact-input-set">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Company Name
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="company_name"
                              value={company.company_name}
                              onChange={handleOnChange}
                            />
                            {companyError.company_name && (
                              <span className="text-danger">
                                * {companyError.company_name}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                              <label className="col-form-label">
                                Email <span className="text-danger"> *</span>
                              </label>
                              {/* <div className="status-toggle small-toggle-btn d-flex align-items-center">
                            <span className="me-2 label-text">Option</span>
                            <input type="checkbox" id="user2" className="check" defaultChecked />
                            <label htmlFor="user2" className="checktoggle" />
                          </div> */}
                            </div>
                            <input
                              className="form-control"
                              type="email"
                              name="email"
                              value={company.email}
                              onChange={handleOnChange}
                            />
                            {companyError.email && (
                              <span className="text-danger">
                                * {companyError.email}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Contact Number
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="phone"
                              value={company.phone}
                              onChange={handleOnChange}
                            />
                            {companyError.phone && (
                              <span className="text-danger">
                                *{companyError.phone}
                              </span>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Phone Number 2{" "}
                              <span className="text-danger"> *</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              CIN Number
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="cin"
                              value={company.cin}
                              onChange={handleOnChange}
                            />
                            {companyError.cin && (
                              <span className="text-danger">
                                *{companyError.cin}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Date of incorporation
                            </label>
                            <span className="text-danger"> *</span>
                            <input
                              className="form-control"
                              type="date"
                              name="date_of_incorporation"
                              value={company.date_of_incorporation}
                              onChange={handleOnChange}
                            />
                            {companyError.date_of_incorporation && (
                              <span className="text-danger">
                                * {companyError.date_of_incorporation}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              GST number
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="gst_number"
                              value={company.gst_number}
                              onChange={handleOnChange}
                            />
                            {companyError.gst_number && (
                              <span className="text-danger">
                                *{companyError.gst_number}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                              <label className="col-form-label">
                                PAN number{" "}
                                <span className="text-danger"> *</span>
                              </label>
                              {/* <div className="status-toggle small-toggle-btn d-flex align-items-center">
                            <span className="me-2 label-text">Option</span>
                            <input type="checkbox" id="user2" className="check" defaultChecked />
                            <label htmlFor="user2" className="checktoggle" />
                          </div> */}
                            </div>
                            <input
                              className="form-control"
                              type="text"
                              name="pan"
                              value={company.pan}
                              onChange={handleOnChange}
                            />
                            {companyError.pan && (
                              <span className="text-danger">
                                *{companyError.pan}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              TAN number
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="tan"
                              value={company.tan}
                              onChange={handleOnChange}
                            />
                            {companyError.tan && (
                              <span className="text-danger">
                                *{companyError.tan}
                              </span>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Phone Number 2{" "}
                              <span className="text-danger"> *</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Registration Number
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="company_registration_number"
                              value={company.company_registration_number}
                              onChange={handleOnChange}
                            />
                            {companyError.company_registration_number && (
                              <span className="text-danger">
                                *{companyError.company_registration_number}
                              </span>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Reviews <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={options.allFieldsOptions}
                              placeholder="All Fields"
                            />
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Company Type
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={options.selectType}
                              placeholder="Select Type"
                              // value={company.company_type}
                              onChange={handleChange}
                            />
                            {companyError.company_type && (
                              <span className="text-danger">
                                *{companyError.company_type}
                              </span>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Tags <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-tags form-control"
                              id="inputBox"
                              type="text"
                              data-role="tagsinput"
                              name="Label"
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-md-6">
                          <div className="input-block mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                              <label className="col-form-label">
                                Deals <span className="text-danger">*</span>
                              </label>
                              <Link to="#" className="add-new">
                                <i className="la la-plus-circle me-2" />
                                Add New
                              </Link>
                            </div>
                            <Select
                              options={options.selectOptions}
                              placeholder="Select Options"
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-lg-4 col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Industry <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={options.lastNameOptions}
                              placeholder="Last Name Options"
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-lg-4 col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Source <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={options.anotherSelectOptions}
                              placeholder="Another Select Options"
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-lg-4 col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Contact <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={options.communicationOptions}
                              placeholder="Communication Options"
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Currency <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={options.currencyOptions}
                              placeholder="Currency Options"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Language <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={options.languageOptions}
                              placeholder="Language Options"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              About Company
                              <span className="text-danger">*</span>
                            </label>
                            <textarea
                              className="form-control"
                              rows={5}
                              defaultValue={""}
                            />
                          </div>
                        </div> */}
                        <div className="col-lg-12 text-end form-wizard-button">
                          {/* <button
                            className="button btn-lights reset-btn"
                            type="reset"
                          >
                            Reset
                          </button> */}
                          <button
                            className="btn btn-primary wizard-next-btn"
                            type="submit"
                            // onClick={() => {
                            //   // FieldsetTwo();
                            //   onSubmit();
                            // }}
                          >
                            Save &amp; Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </fieldset>
                <fieldset style={{ display: fieldTwo ? "block" : "none" }}>
                  {/* <form onSubmit={(e) => handleAddressSubmit(e)}> */}
                  <form onSubmit={(e) => handleAddress(e)}>
                    <div className="contact-input-set">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Present Address
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="address"
                              value={addresses.presentAddress.address}
                              // onChange={presentAddressOnchange}
                              onChange={(e) =>
                                presentAddressOnchange(
                                  "presentAddress",
                                  "address",
                                  e.target.value
                                )
                              }
                            />
                            {errors.presentAddress.address && (
                              <span className="text-danger">
                                {errors.presentAddress.address}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              City <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="city"
                              value={addresses.presentAddress.city}
                              onChange={(e) =>
                                presentAddressOnchange(
                                  "presentAddress",
                                  "city",
                                  e.target.value
                                )
                              }
                            />
                            {errors.presentAddress.city && (
                              <span className="text-danger">
                                {errors.presentAddress.city}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              State
                              <span className="text-danger"> *</span>
                            </label>
                            <Select
                              options={options.stateOptions}
                              placeholder="Select state"
                              onChange={handleDropDownChange(
                                "presentAddress",
                                "state"
                              )}
                            />
                            {errors.presentAddress.state && (
                              <span className="text-danger">
                                {errors.presentAddress.state}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Country <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={options.countryOptions}
                              placeholder="Select country"
                              onChange={handleDropDownChange(
                                "presentAddress",
                                "country"
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Pin code <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="pincode"
                              value={addresses.presentAddress.pincode}
                              onChange={(e) =>
                                presentAddressOnchange(
                                  "presentAddress",
                                  "pincode",
                                  e.target.value
                                )
                              }
                            />
                            {errors.presentAddress.pincode && (
                              <span className="text-danger">
                                {errors.presentAddress.pincode}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        style={{ cursor: "pointer" }}
                        onClick={sameAddress}
                      />
                      <label style={{ marginLeft: "10px", color: "orange" }}>
                        Permanent address and present address is same.
                      </label>
                    </div>
                    {!addresses.isSame && (
                      <div className="contact-input-set">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Permanent Address
                                <span className="text-danger"> *</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="address"
                                value={addresses.permanentAddress.address}
                                // onChange={permanentAddressOnchange}
                                onChange={(e) =>
                                  handleAddressChange(
                                    "permanentAddress",
                                    "address",
                                    e.target.value
                                  )
                                }
                              />
                              {errors.permanentAddress.address && (
                                <span className="text-danger">
                                  *{errors.permanentAddress.address}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                City <span className="text-danger"> *</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="city"
                                value={addresses.permanentAddress.city}
                                onChange={(e) =>
                                  handleAddressChange(
                                    "permanentAddress",
                                    "city",
                                    e.target.value
                                  )
                                }
                              />
                              {errors.permanentAddress.city && (
                                <span className="text-danger">
                                  {errors.permanentAddress.city}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                State
                                <span className="text-danger"> *</span>
                              </label>
                              <Select
                                options={options.stateOptions}
                                placeholder="Select state"
                                onChange={handleDropDownChange(
                                  "permanentAddress",
                                  "state"
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Country <span className="text-danger">*</span>
                              </label>
                              <Select
                                options={options.countryOptions}
                                placeholder="Select country"
                                onChange={handleDropDownChange(
                                  "permanentAddress",
                                  "country"
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Pin code <span className="text-danger"> *</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="pincode"
                                value={addresses.permanentAddress.pincode}
                                onChange={(e) =>
                                  handleAddressChange(
                                    "permanentAddress",
                                    "pincode",
                                    e.target.value
                                  )
                                }
                              />
                              {errors.permanentAddress.pincode && (
                                <span className="text-danger">
                                  {errors.permanentAddress.pincode}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-lg-12 text-end form-wizard-button">
                      <button
                        className="btn btn-primary wizard-next-btn"
                        type="submit"
                        // onClick={(e) => handleAddressSubmit(e)}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Company */}
      {/* Edit Company */}
      <div
        className="modal custom-modal fade custom-modal-two modal-padding"
        id="edit_company"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header header-border justify-content-between p-0">
              <h5 className="modal-title">Edit Company</h5>
              <button
                type="button"
                className="btn-close position-static"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="add-details-wizard">
                <ul id="progressbar2" className="progress-bar-wizard">
                  <li className={fieldOne ? "active" : ""}>
                    <span>
                      <i className="la la-user-tie" />
                    </span>
                    <div className="multi-step-info">
                      <h6>Basic Info</h6>
                    </div>
                  </li>
                  <li className={fieldTwo ? "active" : ""}>
                    <span>
                      <i className="la la-map-marker" />
                    </span>
                    <div className="multi-step-info">
                      <h6>Address</h6>
                    </div>
                  </li>
                  <li className={fieldThree ? "active" : ""}>
                    <div className="multi-step-icon">
                      <span>
                        <i className="la la-icons" />
                      </span>
                    </div>
                    <div className="multi-step-info">
                      <h6>Social Profiles</h6>
                    </div>
                  </li>
                  <li className={fieldFour ? "active" : ""}>
                    <div className="multi-step-icon">
                      <span>
                        <i className="la la-images" />
                      </span>
                    </div>
                    <div className="multi-step-info">
                      <h6>Access</h6>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="add-info-fieldset">
                <fieldset
                  id="edit-first-field"
                  style={{ display: fieldOne ? "block" : "none" }}
                >
                  <form action="/company">
                    <div className="form-upload-profile">
                      <h6 className>
                        Profile Image <span> *</span>
                      </h6>
                      <div className="profile-pic-upload">
                        <div className="profile-pic">
                          <span>
                            <img src={company_icon_01} alt="Img" />
                          </span>
                        </div>
                        <div className="employee-field">
                          <div className="mb-0">
                            <div className="image-upload mb-0">
                              <input type="file" />
                              <div className="image-uploads">
                                <h4>Upload</h4>
                              </div>
                            </div>
                          </div>
                          <div className="img-reset-btn">
                            <Link to="#">Reset</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="contact-input-set">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Company Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue="NovaWaveLLC"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                              <label className="col-form-label">
                                Email <span className="text-danger"> *</span>
                              </label>
                              <div className="status-toggle small-toggle-btn d-flex align-items-center">
                                <span className="me-2 label-text">Option</span>
                                <input
                                  type="checkbox"
                                  id="user3"
                                  className="check"
                                  defaultChecked
                                />
                                <label
                                  htmlFor="user3"
                                  className="checktoggle"
                                />
                              </div>
                            </div>
                            <input
                              className="form-control"
                              type="email"
                              defaultValue="Robertson@example.com"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Phone Number 1
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue="+1 875455453"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Phone Number 2{" "}
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue="+1 895455450"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Fax <span className="text-danger"> *</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">Website</label>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue="Admin Website"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Reviews <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={options.sortOptions}
                              placeholder="Sort Options"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Owner <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={options.selectOptions}
                              placeholder="Select Options"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Tags <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-tags form-control"
                              id="inputBox2"
                              type="text"
                              data-role="tagsinput"
                              name="Label"
                              // defaultValue="Label, Label"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                              <label className="col-form-label">
                                Deals <span className="text-danger">*</span>
                              </label>
                              <Link to="#" className="add-new">
                                <i className="la la-plus-circle me-2" />
                                Add New
                              </Link>
                            </div>
                            <Select
                              options={options.lastNameOptions}
                              placeholder="Last Name Options"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Industry <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={options.anotherSelectOptions}
                              placeholder="Another Select Options"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Source <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={options.anotherSelectOptions}
                              placeholder="Another Select Options"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Contact <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={options.communicationOptions}
                              placeholder="Communication Options"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Currency <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={options.currencyOptions}
                              placeholder="Currency Options"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Language <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={options.languageOptions}
                              placeholder="Language Options"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              About Company
                              <span className="text-danger">*</span>
                            </label>
                            <textarea
                              className="form-control"
                              rows={5}
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 text-end form-wizard-button">
                          <button
                            className="button btn-lights reset-btn"
                            type="reset"
                          >
                            Reset
                          </button>
                          <button
                            className="btn btn-primary wizard-next-btn"
                            type="button"
                            onClick={FieldsetTwo}
                          >
                            Save &amp; Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </fieldset>
                <fieldset style={{ display: fieldTwo ? "block" : "none" }}>
                  <form action="/company">
                    <div className="contact-input-set">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Street Address
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue="38 Simpson Stree"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              City <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue="Rock Island"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              State <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue="USA"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Country <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={options.languageOptions}
                              placeholder="Language Options"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Zipcode <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue={65}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 text-end form-wizard-button">
                          <button
                            className="button btn-lights reset-btn"
                            type="reset"
                          >
                            Reset
                          </button>
                          <button
                            className="btn btn-primary wizard-next-btn"
                            type="button"
                            onClick={FieldsetThree}
                          >
                            Save &amp; Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </fieldset>
                <fieldset style={{ display: fieldThree ? "block" : "none" }}>
                  <form action="/company">
                    <div className="contact-input-set">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">Facebook</label>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue="Darlee Robertson"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">Twitter</label>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue="Darlee Robertson"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">Linkedin</label>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue="Darlee Robertson"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">Skype</label>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue="Darlee Robertson"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">Whatsapp</label>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue="Darlee Robertson"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">Instagram</label>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue="Darlee_Robertson"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-block mb-3">
                            <Link to="#" className="add-new">
                              <i className="la la-plus-circle me-2" />
                              Add New
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-12 text-end form-wizard-button">
                          <button
                            className="button btn-lights reset-btn"
                            type="reset"
                          >
                            Reset
                          </button>
                          <button
                            className="btn btn-primary wizard-next-btn"
                            type="button"
                            onClick={FieldsetFour}
                          >
                            Save &amp; Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </fieldset>
                <fieldset style={{ display: fieldFour ? "block" : "none" }}>
                  <form action="/company">
                    <div className="contact-input-set">
                      <div className="input-blocks add-products">
                        <label className="mb-3">Visibility</label>
                        <div className="access-info-tab">
                          <ul
                            className="nav nav-pills"
                            id="pills-tab2"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <span
                                className="custom_radio mb-0"
                                id="pills-public-tab2"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-public2"
                                role="tab"
                                aria-controls="pills-public2"
                                aria-selected="true"
                              >
                                <input
                                  type="radio"
                                  className="form-control"
                                  name="public"
                                  defaultChecked
                                />
                                <span className="checkmark" /> Public
                              </span>
                            </li>
                            <li className="nav-item" role="presentation">
                              <span
                                className="custom_radio mb-0"
                                id="pills-private-tab2"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-private2"
                                role="tab"
                                aria-controls="pills-private2"
                                aria-selected="false"
                              >
                                <input
                                  type="radio"
                                  className="form-control"
                                  name="private"
                                />
                                <span className="checkmark" /> Private
                              </span>
                            </li>
                            <li className="nav-item" role="presentation">
                              <span
                                className="custom_radio mb-0 active"
                                id="pills-select-people-tab2"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-select-people2"
                                role="tab"
                                aria-controls="pills-select-people2"
                                aria-selected="false"
                              >
                                <input
                                  type="radio"
                                  className="form-control"
                                  name="select-people"
                                />
                                <span className="checkmark" /> Select People
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="tab-content" id="pills-tabContent2">
                        <div
                          className="tab-pane fade"
                          id="pills-public2"
                          role="tabpanel"
                          aria-labelledby="pills-public-tab2"
                        ></div>
                        <div
                          className="tab-pane fade"
                          id="pills-private2"
                          role="tabpanel"
                          aria-labelledby="pills-private-tab2"
                        ></div>
                        <div
                          className="tab-pane fade show active"
                          id="pills-select-people2"
                          role="tabpanel"
                          aria-labelledby="pills-select-people-tab2"
                        >
                          <div className="people-select-tab">
                            <h3>Select People</h3>
                            <div className="select-people-checkbox">
                              <label className="custom_check">
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="people-profile">
                                  <img src={avatar19} alt="Img" />
                                  <Link to="#">Darlee Robertson</Link>
                                </span>
                              </label>
                            </div>
                            <div className="select-people-checkbox">
                              <label className="custom_check">
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="people-profile">
                                  <img src={avatar20} alt="Img" />
                                  <Link to="#">Sharon Roy</Link>
                                </span>
                              </label>
                            </div>
                            <div className="select-people-checkbox">
                              <label className="custom_check">
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="people-profile">
                                  <img src={avatar21} alt="Img" />
                                  <Link to="#">Vaughan</Link>
                                </span>
                              </label>
                            </div>
                            <div className="select-people-checkbox">
                              <label className="custom_check">
                                <input type="checkbox" />
                                <span className="checkmark" />
                                <span className="people-profile">
                                  <img src={avatar1} alt="Img" />
                                  <Link to="#">Jessica</Link>
                                </span>
                              </label>
                            </div>
                            <div className="select-confirm-btn">
                              <Link to="#" className="btn danger-btn">
                                Confirm
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5 className="mb-3">Status</h5>
                      <div className="status-radio-btns d-flex mb-3">
                        <div className="people-status-radio">
                          <input
                            type="radio"
                            className="status-radio"
                            id="test4"
                            name="radio-group"
                            defaultChecked
                          />
                          <label htmlFor="test4">Active</label>
                        </div>
                        <div className="people-status-radio">
                          <input
                            type="radio"
                            className="status-radio"
                            id="test5"
                            name="radio-group"
                          />
                          <label htmlFor="test5">Private</label>
                        </div>
                        <div className="people-status-radio">
                          <input
                            type="radio"
                            className="status-radio"
                            id="test6"
                            name="radio-group"
                          />
                          <label htmlFor="test6">Inactive</label>
                        </div>
                      </div>
                      <div className="col-lg-12 text-end form-wizard-button">
                        <button
                          className="button btn-lights reset-btn"
                          type="reset"
                        >
                          Reset
                        </button>
                        <button className="btn btn-primary" type="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Company */}
      {/* Success Company */}
      <div className="modal custom-modal fade" id="success_msg" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon">
                  <i className="la la-building" />
                </div>
                <h3>Company Created Successfully!!!</h3>
                <p>View the details of Company</p>
                <div className="col-lg-12 text-center form-wizard-button">
                  <Link
                    to="#"
                    className="button btn-lights"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </Link>
                  <Link to="/company-details" className="btn btn-primary">
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Success Company */}
      {/* Delete Company */}
      <div
        className="modal custom-modal fade"
        id="delete_company"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon bg-danger">
                  <i className="la la-trash-restore" />
                </div>
                <h3>Are you sure, You want to delete</h3>
                <p>Company ”NovaWaveLLC” from your Account</p>
                <div className="col-lg-12 text-center form-wizard-button">
                  <Link
                    to="#"
                    className="button btn-lights"
                    data-bs-dismiss="modal"
                  >
                    Not Now
                  </Link>
                  <Link
                    to="/company"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Okay
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Delete Company */}
      {/* Export */}
      <div
        className="modal custom-modal fade modal-padding"
        id="export"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header header-border justify-content-between p-0">
              <h5 className="modal-title">Export</h5>
              <button
                type="button"
                className="btn-close position-static"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <form action="/companies">
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <h5 className="mb-3">Export</h5>
                      <div className="status-radio-btns d-flex">
                        <div className="people-status-radio">
                          <input
                            type="radio"
                            className="status-radio"
                            id="pdf"
                            name="export-type"
                            defaultChecked
                          />
                          <label htmlFor="pdf">Person</label>
                        </div>
                        <div className="people-status-radio">
                          <input
                            type="radio"
                            className="status-radio"
                            id="excel"
                            name="export-type"
                          />
                          <label htmlFor="excel">Organization</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h4 className="mb-3">Filters</h4>
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Fields <span className="text-danger">*</span>
                      </label>
                      <Select
                        options={options.countryOptions}
                        placeholder="Country Options"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        From Date <span className="text-danger">*</span>
                      </label>
                      <div className="cal-icon">
                        <input
                          className="form-control floating datetimepicker"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        To Date <span className="text-danger">*</span>
                      </label>
                      <div className="cal-icon">
                        <input
                          className="form-control floating datetimepicker"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 text-end form-wizard-button">
                    <button
                      className="button btn-lights reset-btn"
                      type="reset"
                      data-bs-dismiss="modal"
                    >
                      Reset
                    </button>
                    <button className="btn btn-primary" type="submit">
                      Export Now
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Export */}
    </div>
  );
};

export default CompaniesModal;
