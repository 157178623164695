import React, { useEffect, useRef, useState } from "react";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import { Table } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddandEditPolicy from "../../../../components/modelpopup/AddandEditPolicy";
import DeleteModal from "../../../../components/modelpopup/deletePopup";

import { formatDateInTimezone } from "../../../../utils/formatDateInTimezone";
import config from "./../../../../config";
import axios from "axios";
import { capitalizeFirstLetter } from "../../../../utils/basicFunctions";
import UploadDocumnetModal from "../../Employees/UploadDocumnetModal";


const UploadDocumnet = () => {

    //------------------ get all requested document ------
    const [requestedData , setRequestedData]= useState([]);
    const [deletedID , setDeletedID] = useState("");
    const [requestedDocumnetID ,setRequestedDocumnetId] = useState("");
    const [employeeID ,setEmployeeID] = useState("");
    
   

    const fetchRequestedDocument = async ()=>{
      const employee_id = localStorage.getItem("employeeID");
    try{
      const request = await axios.get(`${config.API_BASE_URL}hr/requested-document/${employee_id}`);
      if(request?.data?.success == true){
          setRequestedData(request?.data?.result)
      }
    }
    catch(err){
      console.log(err)
    }
  }

    useEffect(()=>{
        fetchRequestedDocument();
    
    },[])

    const documentRequest = requestedData?.map((data , index)=>{
      console.log('Nitesh : ',data?.status, data);
        return({
            id:index + 1,
            name : capitalizeFirstLetter(data?.first_name) + " " + capitalizeFirstLetter(data?.middle_name )+ " " + capitalizeFirstLetter(data?.last_name),
            requested_document : data?.document_type,
            status:data?.status,
            employee_id : data?.employee_id,
            requested_document_id : data?.id,
            document_id : data?.document_id,
            visible : data?.visible,
            document : data?.document,
            document_value:data?.document_value
        })
        
    })

    console.log("documentRequest" , documentRequest)

    const handleDownload = async (filePath) => {
      try {
        const a = document.createElement("a");
        a.href = filePath;
        a.target = '_blank'
        // a.download = "policy_document.jpg";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }

   

  
 
  

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Requested document",
      dataIndex: "document_value",
      sorter: (a, b) =>
        a.document_value.length - b.document_value.length,
    },
    {
        title: "Status",
        dataIndex: "status",
        render: (record) => (
          <span style={{color: record == "Pending" ? "#ffb41b": "#1b9928"}}>{record}</span>
        ),
        // sorter: (a, b) =>
        //   a.status.length - b.status.length,
      },
   
    {
      title: "Action",
      render: (record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">

            {
              (!record?.document_id || (record?.visible ==0 || record?.visible == null ))?
              <>
              <Link
              className="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#upload_document_modal"
              to="#"
              style={{cursor:"pointer"}}
              onClick={() => {
                setEmployeeID(record?.employee_id );
                setRequestedDocumnetId(record?.requested_document_id);
              }}
            >
              <i class="fa-solid fa-upload" ></i> Upload   
            </Link> 
            
              </>
            :
            <>
             <span
              className="dropdown-item"
              style={{cursor:"pointer"}}
              onClick={() =>
                handleDownload(`${config.IMAGE_URL}${record?.document}`)
              }
            >
              <i class="fa-solid fa-eye m-r-5"></i> View 
            </span>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() =>{ 
                setDeletedID(record?.document_id);
                setRequestedDocumnetId(record?.requested_document_id)
              }}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
           
            </>
            }
           
          
          </div>
        </div>
      ),
    },
  ];

  const confirmDelete = async() =>{
   if(deletedID){
     try{
      const  response = await axios.put(`${config.API_BASE_URL}hr/delete-documnet/${deletedID}/${requestedDocumnetID}`,)
      if(response?.data?.success == true){
        console.log("deleted successully ");
        fetchRequestedDocument();
      }
     }
     catch(err){
      console.log(err);
     }
   }
  }

 
  

 
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Requested Document"
            title="Dashboard"
            subtitle="Requested Document"
            // modal="#add_policy"
            name="Requested Document"
            // page='policies'
            // action='create'
          />

          {/* <div className="row">
            {statsData?.map((stat, index) => (
              <div
                className="col-md-4 d-flex"
                key={index}
                onClick={() => filterPolicies(stat?.title)}
              >
                <div
                  className={`stats-info w-100 ${
                    stat.active ? "orange-bg" : ""
                  }`}
                >
                  <h6>{stat?.title}</h6>
                  {stat?.subText ? (
                    <h4>
                      {stat?.value} <span>{stat?.subText}</span>
                    </h4>
                  ) : (
                    <h4>{stat?.value}</h4>
                  )}
                </div>
              </div>
            ))}
          </div> */}

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={documentRequest}
                  rowKey={(record) => record?.id}
                  scroll={{ x: true }} 
                  tableLayout="auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AddandEditPolicy policy={policyEditData} setPolicyData={setPolicyData} selectedDepartmentId= {selectedDepartmentId} selectedDesignationtId ={selectedDesignationtId}/> */}
      <DeleteModal Name="Delete Policy" confirmDelete={confirmDelete} />
      <UploadDocumnetModal requestedDocumnetID={requestedDocumnetID} employeeID={employeeID} fetchRequestedDocument={fetchRequestedDocument}/>
    </>
  );
};

export default UploadDocumnet;
