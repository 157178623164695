import React from "react";
import { Link } from "react-router-dom";

const Error403 = () => {
  const storedCompany = localStorage?.getItem("companyName");
  const storedCompanyName = storedCompany?.toLowerCase()?.replace(/ /g, "-")
  return (
    <>
      {/* Main Wrapper */}
      <div className="error-page">
        <div className="main-wrapper">
          <div className="error-box">
            <h1>403</h1>
            <h3>
            <i class="fa-solid fa-user-lock"/> Oops! Permission denied.
            </h3>
            <p>You do not have sufficient rights to access the requested resource.</p>
            <Link to={`/${storedCompanyName}/`} className="btn btn-custom">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error403;
