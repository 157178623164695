import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

const DropZoneContainer = styled.div`
  width: 100%;
  height: 200px;
  border: 2px dashed grey;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: grey;
  cursor: pointer;
  margin-bottom: 20px;
`;

const DropZone = ({ onDrop }) => {
  const onDropCallback = useCallback(
    (acceptedFiles) => {
      onDrop(acceptedFiles);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropCallback,
    accept: "image/*",
  });

  return (
    <DropZoneContainer {...getRootProps()}>
      <input {...getInputProps()} />
      <p>Drag 'n' drop picture here, or click to select a picture</p>
    </DropZoneContainer>
  );
};

export default DropZone;
