import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import config from "../../../config";
import * as Yup from "yup";
import createCustomSchema from "../../../fieldValidator/fieldValidator";
import PdfPreviewer from "./PDFPreviewer";

const UploadDocumnetModal = ({
  employeeID,
  requestedDocumnetID,
  fetchRequestedDocument,
}) => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [disbaleButton, setDisableButton] = useState(true);
  const [dragging, setDragging] = useState(false);
  const [viewPdfFile , setViewPdfFile] = useState(null);
  console.log("fileInputRef", fileInputRef);

  const validationSchema = Yup.object({
    file: createCustomSchema("file", {
      message: "Upload File !",
    }),
  });

  const uploadDocument = (employeeId, requestedId) => {
    console.log("employeeId", employeeId, requestedId);

    console.log("fileInputRef", fileInputRef);
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Programmatically click the hidden input
    }
  };
  const handleFileChange = async (event) => {
    setFileError("");
    setDisableButton(true);
    setFile(null);
    const file = event.target.files[0];
    setViewPdfFile(file);
    console.log(file);

    if (file) {
      const maxFileSize = 50 * 1024; // 50KB in bytes
      if (file.size > maxFileSize) {
        setFileError("File size exceeds 50KB !");
        setDisableButton(true);
        setFile(file);
        return;
      }
      setFile(file);
      setFileError("");
      setDisableButton(false);
    }
  };

  const handleDelete = () => {
    setFile("");
    setFileError("");
    const selectedfile = document.querySelector("#file");
    console.log("deleted", selectedfile);
    if (selectedfile) {
      console.log("deleted");
      selectedfile.value = null;
      setDisableButton(true);
    }
  };
  //----------------------- submited uploaded documnet ------------------------

  const uploadDocumnetSubmited = async () => {
    const assign_id = localStorage.getItem("employeeID");
    if (disbaleButton == true) {
      return;
    }
    if (file) {
      const formData = new FormData();
      formData.append("documnet", file);
      formData.append("employee_id", employeeID);
      formData.append("requested_document_id", requestedDocumnetID);
      formData.append("assign_id", assign_id);
      try {
        const response = await axios.post(
          `${config.API_BASE_URL}hr/uploadDocument`,
          formData
        );
        if (response?.data?.success == true) {
        console.log("working......")
          fetchRequestedDocument();
          handleDelete();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };


  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer.files[0];
    setViewPdfFile(file);

    if (file) {
      const maxFileSize = 50 * 1024; // 50KB in bytes
      if (file.size > maxFileSize) {
        setFileError("File size exceeds 50KB !");
        setDisableButton(true);
        setFile(file);
        return;
      }
      setFile(file);
      setFileError("");
      setDisableButton(false);
    }
  };

  const trimFileName = (string) => {
    let len = string.length;
    if( len >10){
      let word = string.slice(0, 10) + '...';
      return word;
    }
    return string;
    
  }

  const [openModalHandler , setOpenModalHandler] = useState(false);
  const handleOpenModal = () =>{
    console.log("open modal working...")
    setOpenModalHandler(true);
  }
  const closeModalHandler = () =>{
    setOpenModalHandler(false);
  }

  
  return (
    <>
      <div
        id="upload_document_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Upload Document</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={()=>handleDelete()}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-block  d-flex align-items-center justify-content-araound"
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              style={{
                border: dragging ? "2px dashed #007bff" : "2px dashed transparent",
                padding: "10px",
                borderRadius: "5px",
                // backgroundColor: dragging ? "#f0f8ff" : "#F5F7F8"
              }}
              >
                <button
                  className="btn btn-light w-100"
                  style={{ backgroundColor: "#F5F7F8", color: "black" }}
                  onClick={() =>
                    uploadDocument(employeeID, requestedDocumnetID)
                  }
                >
                  {file?.name ?trimFileName(file?.name) : "Upload"}{" "}
                  <i className="fa-solid fa-upload ml-2"></i>
                </button>

                <input
                  id="file"
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept=".pdf"
                />
                {
                    file && (<div className=" d-flex mr-4 align-items-center">
                        <i
                          className="fa-solid fa-eye mr-4"
                          style={{
                            cursor: "pointer",
                            fontSize: "1.2rem",
                            marginLeft: "20px",
                          }}

                          onClick={()=>handleOpenModal()}
                          
                        ></i>
                        <i
                          className="fa fa-trash"
                          style={{
                            cursor: "pointer",
                            fontSize: "1.2rem",
                            color: "red",
                            marginLeft: "20px",
                          }}
                          onClick={() => handleDelete()}
                        ></i>
                      </div>)
                }
              </div>
              <div className="d-flex flex-column input-block">
                <span className="upload_image_file_status">
                  *Uploaded File must be pdf or 50KB  less
                </span>


                {fileError && (
                  <span className="mb-4 text-danger">{fileError}</span>
                )}
              </div>

              <div
                className="row mt-4"
                style={{ borderTop: "2px solid #d5d9e0" }}
              ></div>

              <div className="row mt-4">
                <div className="col-lg-12 text-end form-wizard-button ">
                  <button
                    className="btn btn-primary mr-3"
                    type="button"
                    style={{ width: "120px", marginRight: "20px" }}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    style={{
                      width: "100px",
                      cursor: disbaleButton ? "not-allowed" : "pointer",
                      backgroundColor: disbaleButton ? "#61639b" : "#1f214b",
                      border: "none",
                    }}
                    data-bs-dismiss= {disbaleButton ?"": "modal"}
                    aria-label={disbaleButton ?"": "Close"}
                    // disabled={disbaleButton == true? true : false}
                    onClick={uploadDocumnetSubmited}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     { openModalHandler && <PdfPreviewer viewPdfFile ={viewPdfFile} closeModalHandler={closeModalHandler }/>}
    </>
  );
};

export default UploadDocumnetModal;
