
import axios from 'axios';
import config from '../config';

const API_URL = 'permissions'; 

export const getUserPermissions = (roleId) => {
  return axios.get(`${config.API_BASE_URL}${API_URL}`, { params: { roleId } })
    .then(response => response.data)
    .catch(error => {
      console.error('Error fetching permissions:', error);
      throw error;
    });
};
