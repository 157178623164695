import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import notifications from "../../assets/json/notifications";
import Profile from "../../assets/img/icons/profile2.png";
import Password from "../../assets/img/icons/password.png";
import Logout from "../../assets/img/icons/logout.png";
import Indiaflag from "../../assets/img/icons/flag1.png";

import message from "../../assets/json/message";
import {
  Applogo,
  Avatar_02,
  headerlogo,
  lnEnglish,
  lnFrench,
  lnGerman,
  lnSpanish,
} from "../../Routes/ImagePath";
import User from "../../assets/img/icons/user3.png";

import { FaRegBell, FaRegComment } from "react-icons/fa";
import { useLocation } from "react-router-dom/dist";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { getEmployeeData } from "../../utils/api";
import {
  formatDateInTimezone,
  formatDateTimeInTimezone,
} from "../../utils/formatDateInTimezone";
import { capitalizeFirstLetter } from "../../utils/basicFunctions";

const Header = (props) => {
  const companyName = localStorage.getItem("companyName") || "Default Company";
  const data = notifications.notifications;
  const navigate = useNavigate();
  const datas = message.message;
  const [notification, setNotifications] = useState(false);
  const [flag, setflag] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [profile, setProfile] = useState(false);
  const [search, setSearch] = useState(false);
  const [flagImage, setFlagImage] = useState(Indiaflag);
  const [employeeProfileData, setEmployeeProfileData] = useState({});
  const [toggleTrue, setToggleTrue] = useState(false);
  const searchRef = useRef(null);

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };

  const onMenuClik = () => {
    setToggleTrue(!toggleTrue);
    console.log(
      "document.body.classList.contains",
      document.body.classList.contains("slide-nav")
    );
    // if (document.body.classList.contains("slide-nav")) {
    // document.body.classList.remove("slide-nav");
    // } else {
    //   console.log("cliched on menuclick")
    document.body.classList.add("slide-nav");
    // }
  };

  const themes = localStorage.getItem("theme");
  const [openNotification, setOpenNotification] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setNotifications(false);
    setProfile(false);
    setflag(false);
  };

  const handleNotification = () => {
    setNotifications(!notification);
    setflag(false);
    setIsOpen(false);
    setProfile(false);
    setOpenNotification(!openNotification);
  };
  const handleProfile = () => {
    setProfile(!profile);
    setNotifications(false);
    setflag(false);
    setIsOpen(false);
  };

  const location = useLocation();
  let pathname = location.pathname;
  const Credencial = localStorage.getItem("credencial");
  const Value = JSON.parse(Credencial);
  const UserName = Value?.email?.split("@")[0];
  const ProfileName = UserName?.charAt(0).toUpperCase() + UserName?.slice(1);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setFlagImage(
      lng === "In"
        ? Indiaflag
        : lng === "fr"
        ? lnFrench
        : lng === "es"
        ? lnSpanish
        : lnGerman
    );
  };

  const searchToggling = () => {
    setSearch(!search);
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearch(false);
    }
  };

  useEffect(() => {
    if (search) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [search]);

  // ------------------logout handling---------------
  const handleLogout = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const companyName = localStorage.getItem("companyName");

    if (!token) {
      console.error("Token not found in local storage.");
      return;
    }

    const configuration = {
      headers: {
        Authorization: `Bearer ${token}`,
        CompanyName: companyName,
      },
    };

    axios
      .post(`${config.API_BASE_URL}auth/logout`, {}, configuration)
      .then((res) => {
        console.log("Logout successful:", res.data.success);
        if (res.data.success === true) {
          localStorage.clear();

          navigate("../");
          window.location.reload();
        }
      })
      .catch((err) => {
        console.error("Error during logout:", err);
      });
  };

  //-----------------------  employee Profiling----------------------------

  useEffect(() => {
    let employeeToken = localStorage.getItem("token");
    const configuration = {
      headers: {
        Authorization: `Bearer ${employeeToken}`,
      },
    };
    try {
      const fetchData = async () => {
        let userEmail = localStorage.getItem("user");
        if (userEmail) {
          let user = JSON.parse(userEmail);
          const email = user?.email;
          console.log("user email is", email);

          try {
            const res = await axios.post(
              `${config.API_BASE_URL}employees/employee_details`,
              { email },
              configuration
            );
            console.log("response from getEmployeeData -->", res);
            if (res.data.success == true) {
              localStorage.setItem(
                "employeeID",
                res?.data?.results?.employee_id
              );
              setEmployeeProfileData(res?.data?.results);
            }
          } catch (error) {
            console.log("error", error);
          }
        }
      };
      console.log("employee Profile Data--->", employeeProfileData);

      fetchData();
    } catch (err) {
      console.log(err);
    }
  }, []);

  //------------------------------- Notification---------------------------

  // const [notify, setNotify] = useState([]);
  // const hrId = localStorage.getItem("employeeID");
  // console.log("hr id is ->", hrId);

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   const configuration = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };

  //   axios
  //     .get(`${config.API_BASE_URL}hr/${hrId}/notifications`, configuration)
  //     .then((response) => {
  //       setNotify(response?.data?.result);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching notifications", error);
  //     });

  //   console.log("ites working------------------>");
  //   const ws = new WebSocket(`ws://localhost:8080?hr_id=${hrId}`);

  //   ws.onmessage = (event) => {
  //     const message = JSON.parse(event.data);
  //     console.log("messaged data", message);
  //     if (
  //       message.type === "NEW_LEAVE_REQUEST" ||
  //       message.type === "NEW_POLICY_REQUEST"
  //     ) {
  //       console.log("message type -->", message.type);
  //       setNotify((prevNotifications) => [...prevNotifications, message.data]);
  //     }
  //     // if(message.type === 'NEW_POLICY_REQUEST'){
  //     //   console.log("message type -->", message.type);
  //     //   setNotify((prevNotifications) => [...prevNotifications, message.data]);
  //     // }
  //   };

  //   return () => {
  //     ws.close();
  //   };
  // }, [hrId]);

  // useEffect(() => {
  //   console.log("notifications2 --->", notify);
  // }, [notify]);

  const [userId, setUserID] = useState("");

  useEffect(() => {
    const employee_id = localStorage.getItem("employeeID");
    setUserID(employee_id);
  }, []);
  const [notificationsData, setNotificationsData] = useState([]);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}hr/${userId}/notifications`
      );
      console.log("fetch notification response", response);
      setNotificationsData(response?.data?.result);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
    // const ws = new WebSocket('ws://localhost:8080');
    const ws = new WebSocket("wss://bk.circulatehealthcare.com:8081");

    ws.onmessage = (event) => {
      console.log("event", event);
      const {
        receiverId,
        type,
        message,
        first_name,
        last_name,
        photo,
        id,
        created_at,
      } = JSON.parse(event.data);
      console.log("websocket notification data:", receiverId, type, message);
      if (receiverId == userId) {
        console.log("Running,....");
        setNotificationsData((prevNotifications) => [
          ...prevNotifications,
          { type, message, first_name, last_name, photo, id, created_at },
        ]);
      }
      console.log("notification Data", notificationsData);
    };

    return () => ws.close();
  }, [userId]);

  console.log("notification Data", notificationsData);

  const clearAllNotification = () => {
    setNotifications(false);
    const data = notificationsData.map((item) => item.id);
    console.log("clear All notification", data);
    axios
      .post(`${config.API_BASE_URL}hr/notification-update`, data)
      .then((res) => console.log("updated notification", res))
      .catch((err) => console.log(err));
  };

  const readNotificationHandler = (id) => {
    console.log("readNotificationHandler", id);
    axios
      .put(`${config.API_BASE_URL}notification-is-read/${id}`)
      .then((res) => {
        console.log("readNotificationHandler", res);
        fetchNotifications();
      })
      .catch((err) => console.log("readNotificationHandler", err));
  };

  return (
    <div className="header" style={{ right: "0px" }}>
      {/* Logo */}
      <div className="header-left">
        <Link
          to="../"
          className="logo"
          style={{ color: "white", fontSize: "22px" }}
        >
          {/* <img src={headerlogo} alt="img" /> */}
          People Connect
        </Link>
        {/* <Link to="/admin-dashboard" className="logo2">
          <img src={Applogo} width={40} height={40} alt="img" />
          SMART SYSTEM
        </Link> */}
        <h2> </h2>
      </div>
      {/* /Logo */}
      <Link
        id="toggle_btn"
        to="#"
        style={{
          display: pathname.includes("tasks")
            ? "none"
            : pathname.includes("compose")
            ? "none"
            : "",
        }}
        onClick={handlesidebar}
      >
        <span className="bar-icon">
          <span />
          <span />
          <span />
        </span>
      </Link>
      {/* Header Title */}
      <div className="page-title-box">
        <h3>{companyName.toUpperCase()}</h3>
      </div>
      {/* /Header Title */}
      <Link
        id="mobile_btn"
        className="mobile_btn"
        to="#"
        onClick={() => onMenuClik()}
        // style={{border:"2px solid red"}}
      >
        <i className="fa fa-bars" />
      </Link>
      {/* Header Menu */}
      <ul className="nav user-menu">
        {/* Search */}
        <li className="nav-item">
          <div className="top-nav-search">
            <Link to="#" className="responsive-search">
              <i className="fa fa-search" />
            </Link>
            {search ? (
              <form ref={searchRef}>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search here"
                />
                <button className="btn" type="submit">
                  <i
                    className="fa fa-search"
                    style={{ marginBottom: "15px" }}
                  />
                </button>
              </form>
            ) : (
              <i
                className="fa fa-search"
                style={{
                  marginLeft: "10px",
                  color: "white",
                  marginTop: "14px",
                }}
                onClick={searchToggling}
              />
            )}
          </div>
        </li>
        {/* /Search */}
        {/* Flag */}

        <li className="nav-item dropdown has-arrow flag-nav">
          <Link
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            to="#"
            role="button"
          >
            <img src={flagImage} alt="Flag" height="20" /> In
            {/* {t(i18n.language)}  */}
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <a
              // to="#"
              className="dropdown-item"
              onClick={() => changeLanguage("In")}
            >
              <img src={Indiaflag} alt="Flag" height="16" /> India
            </a>
            {/* <Link
              to="#"
              className="dropdown-item"
              onClick={() => changeLanguage("fr")}
            >
              <img src={lnFrench} alt="Flag" height="16" /> French
            </Link>
            <Link
              to="#"
              className="dropdown-item"
              onClick={() => changeLanguage("es")}
            >
              <img src={lnSpanish} alt="Flag" height="16" /> Spanish
            </Link>
            <Link
              to="#"
              className="dropdown-item"
              onClick={() => changeLanguage("de")}
            >
              <img src={lnGerman} alt="Flag" height="16" /> German
            </Link> */}
          </div>
        </li>
        {/* /Flag */}

        {/* Notifications */}
        <li className="nav-item dropdown">
          <Link
            to="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
            onClick={handleNotification}
          >
            <i>
              <FaRegBell />
            </i>
            {notificationsData && notificationsData?.length > 0 && (
              <span className="badge badge-pill">
                {notificationsData?.length}
              </span>
            )}
          </Link>
          <div
            className={`dropdown-menu dropdown-menu-end notifications ${
              notification ? "show" : ""
            }`}
          >
            <div className="topnav-dropdown-header">
              <span className="notification-title">Notifications</span>
              <Link
                to="#"
                onClick={() => clearAllNotification()}
                className="clear-noti"
              >
                {" "}
                Clear All{" "}
              </Link>
            </div>
            <div className="noti-content">
              <ul className="notification-list">
                {notificationsData.map((val, index) => {
                  return (
                    <li className="notification-message" key={index}>
                      {/* <Link
                        onClick={
                          () => {
                            localStorage.setItem("minheight", "true")
                          readNotificationHandler(val.id)
                        }
                        }
                        to="#"
                      > */}
                      <div className="media d-flex">
                        <span className="avatar flex-shrink-0">
                          <img
                            alt=""
                            src={`${config.IMAGE_URL}${val?.photo}`}
                          />
                        </span>
                        <div className="media-body">
                          <p className="noti-details">
                            <span className="noti-title">
                              {val?.first_name ? val?.first_name : ""}{" "}
                              {val?.last_name ? val?.last_name : ""}
                            </span>{" "}
                            <span>{val?.message}</span>
                            {/* {val?.type == "NEW_POLICY_REQUEST" && (
                                <span>
                                  Requested the Document{" "}
                                  {val?.document_type
                                    ? val?.document_type
                                    : val?.message}
                                </span>
                              )} */}
                            {/* <span className="noti-title">
                                {val.contents_2}
                              </span> */}
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">
                              {formatDateTimeInTimezone(val?.created_at)}
                            </span>
                          </p>
                        </div>
                        <div
                          onClick={() => readNotificationHandler(val.id)}
                          style={{ marginLeft: "auto" }}
                        >
                          <span
                            style={{
                              padding: "1px",
                              paddingRight: "2px",
                              cursor: "pointer",
                              color: "#f5db1b",
                              textDecoration: "underline",
                            }}
                          >
                            mark as read
                          </span>
                        </div>
                      </div>
                      {/* </Link> */}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="topnav-dropdown-footer">
              <Link
                onClick={() => localStorage.setItem("minheight", "true")}
                to="/app/administrator/activities"
              >
                View all Notifications
              </Link>
            </div>
          </div>
        </li>
        {/* /Notifications */}
        {/* Message Notifications */}
        {/* <li className={`nav-item dropdown ${isOpen ? "show" : ""}`}>
          <Link
            to="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
            onClick={toggleDropdown}
          >
            <i>
              <FaRegComment />
            </i>{" "}
            <span className="badge badge-pill">8</span>
          </Link>
          <div
            className={`dropdown-menu dropdown-menu-end notifications ${
              isOpen ? "show" : ""
            }`}
          >
            <div className="topnav-dropdown-header">
              <span className="notification-title">Messages</span>
              <Link to="#" className="clear-noti">
                {" "}
                Clear All{" "}
              </Link>
            </div>
            <div className="noti-content">
              <ul className="notification-list">
                {datas.map((value, index) => {
                  return (
                    <li className="notification-message" key={index}>
                      <Link
                        onClick={() =>
                          localStorage.setItem("minheight", "true")
                        }
                        to="/conversation/chat"
                      >
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">
                              <img alt="" src={value.image} />
                            </span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">{value.name}</span>
                            <span className="message-time">{value.time}</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              {value.contents}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="topnav-dropdown-footer">
              <Link
                onClick={() => localStorage.setItem("minheight", "true")}
                to="/conversation/chat"
              >
                View all Messages
              </Link>
            </div>
          </div>
        </li> */}
        {/* /Message Notifications */}
        <li className="nav-item dropdown has-arrow main-drop">
          <Link
            to="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
            onClick={handleProfile}
          >
            {" "}
            <span className="user-img me-1">
              <img
                src={
                  employeeProfileData?.photo
                    ? `${config.IMAGE_URL}${employeeProfileData?.photo}`
                    : User
                }
                alt="img"
                style={{ width: "40px", height: "40px" }}
              />
              <span className="status online" />
            </span>
            <span>
              {employeeProfileData
                ? `${capitalizeFirstLetter(employeeProfileData?.first_name)}`
                : "Admin"}
            </span>
          </Link>
          <div
            className={`dropdown-menu dropdown-menu-end ${
              profile ? "show" : ""
            }`}
          >
            <Link
              className="dropdown-item text-black"
              to={`employees/profile/${employeeProfileData?.employee_id}`}
            >
              <img src={Profile} alt="my-profile" /> My Profile
            </Link>
            {/* <Link
              className="dropdown-item text-black"
              to="/settings/companysetting"
            >
              <img src={Password} alt="password" /> Change Password
            </Link> */}
            <Link
              className="dropdown-item text-black"
              to="#"
              onClick={handleLogout}
            >
              {" "}
              <img src={Logout} alt="logout" /> Logout
            </Link>
          </div>
        </li>
      </ul>
      {/* /Header Menu */}
      {/* Mobile Menu */}
      <div className="dropdown mobile-user-menu">
        <Link
          to="#"
          className="nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v" />
        </Link>
        <div className="dropdown-menu dropdown-menu-end dropdown-menu-right">
          <Link
            className="dropdown-item"
            to={`employees/profile/${employeeProfileData?.employee_id}`}
          >
            <i class="fa-solid fa-user"></i> My Profile
          </Link>
          <Link className="dropdown-item" to="#" onClick={handleLogout}>
            Logout
          </Link>
        </div>
      </div>
      {/* /Mobile Menu */}
    </div>
  );
};

export default Header;
