import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import axios from "axios";
import config from "../../../config";
import EmployeeList from "./EmployeeList";
import { RefreshProvider } from "../../../context/RefreshContext";
import EditCandidatesModal from "../../../components/Administration/Jobs/CandidatesModal/EditCandidatesModal";

const AllEmployee = () => {
  const [employeeData, setEmployeeData] = useState([]);

  let token = localStorage.getItem("token");
  const configuration = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    axios
      .get(`${config.API_BASE_URL}employees/`, configuration)
      .then((res) => setEmployeeData(res.data))
      .catch((err) => {
        console.log(err);
      });
    console.log("employee list yfduxydtfy --.", employeeData);
  }, []);

  return (
    <div>
      <RefreshProvider>
        <EmployeeList />
        <AllEmployeeAddPopup />
        <DeleteModal Name="Delete Employee" />
      </RefreshProvider>

      {/* Delete Modal */}
    </div>
  );
};

export default AllEmployee;
