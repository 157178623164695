import axios from "axios";
import React, { useState } from "react";
import config from "../../config";

const DepartmentModal = ({filterDepartment, departmentID , companyID , setRenderState}) => {
  const [deparetmentName , setDepartmentName] = useState("");
  const [closeModal, setCloseModal] = useState(false);
  const [departmentError, setDepartmentError ] = useState(false);
  console.log("department name" , deparetmentName)

  const departmentSubmitHandler = async(e) =>{
    e.preventDefault();
    if( !deparetmentName){
      setDepartmentError(true);
      return;
    }
    setDepartmentError(false);
    try{
      const res = await axios.put(`${config.API_BASE_URL}department/${departmentID}/${companyID}`, {deparetmentName} );
       if(res.data.success == true){
          setCloseModal(true);
          setRenderState(true);
          setDepartmentName("");
       }
    }
    catch(err){
      console.log("error" , err)
    }

  }

  const addDepartmentHandler = async(e)=>{
    e.preventDefault();
    if( !deparetmentName){
      setDepartmentError(true);
      return;
    }
    setDepartmentError(false);
    try{
      const res = await axios.post(`${config.API_BASE_URL}department/add-department/${companyID}`, {deparetmentName} );
      if(res.data.success == true){
        setCloseModal(true);
        setRenderState(true);
        setDepartmentName("");
     }
    }
    catch(err){
      console.log("error" , err)
    }
  }
  return (
    <>
      {/* Add Department Modal */}
      <div
        id="add_department"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Department</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={addDepartmentHandler}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Department Name <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" type="text"  value ={deparetmentName} onChange={(e)=>setDepartmentName(e.target.value)} />
                {departmentError && <span className="text-danger">*Please Enter Department Name !</span>}
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label={closeModal&&"Close"}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Department Modal */}
      {/* Edit Department Modal */}
      <div
        id="edit_department"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Department</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={departmentSubmitHandler}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Department Name <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" type="text"  value ={deparetmentName ? deparetmentName : filterDepartment[0]?.department} onChange={(e)=>setDepartmentName(e.target.value)} />
                  {/* {departmentError && <span className="text-danger">*Please Enter Department Name !</span>} */}
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label={closeModal&&"Close"}
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Department Modal */}
    </>
  );
};

export default DepartmentModal;
