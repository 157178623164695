import React, { useEffect, useState } from "react";
import { postData } from "../../../utils/api";
import axios from "axios";
import config from "../../../config";
import { companyID } from "../../../auth";

const GenerateDocument = ({
  employeeID,
  departmentID,
  editedDocumnet,
  fetchRequestedDocument,
  documnetedData,
  setEditedDocumnet,
  documentType,
  setDocumnetType
}) => {
  const [closeModal, setCloseModal] = useState(false);

  const dummyData = [
    // CREATE TABLE IN DB FOR THIS
    { id: 1, value: "Salary slip" },
    { id: 2, value: "Employment Letter" },
    { id: 3, value: "Releaving Letter" },
    { id: 4, value: "Other Letter" },
  ];
  

  const data = {
    employee_id: employeeID,
    department_id: departmentID,
    document_type: documentType,
  };

  const requestSubmitHandler = async (e) => {
    e.preventDefault();
    if (editedDocumnet?.requested_id) {
      try {
        const document = documentType
          ? documentType
          : editedDocumnet?.requested_document;
        console.log("resposne", document);
        const resposne = await axios.put(
          `${config.API_BASE_URL}hr/update-requested-documnet/${editedDocumnet?.employee_id}/${editedDocumnet?.requested_id}`,
          { document }
        );
        console.log("resposne", resposne);
        if (resposne?.data?.success == true) {
          fetchRequestedDocument();
          setCloseModal(true);
          setDocumnetType("");
          setEditedDocumnet({});
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      const response = await axios.post(
        `${config.API_BASE_URL}hr/document-request`,
        data
      );
      if (response?.data?.success === true) {
        console.log("working....");
        fetchRequestedDocument();
        setCloseModal(true);
        setDocumnetType("");
        setEditedDocumnet({});
      } else {
        console.log(response?.data?.error);
      }
    }
  };

  return (
    <>
      <div
        id="generate_document"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Generate HR Documnet</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={requestSubmitHandler}>
                <div className="input-block mb-5">
                  <label className="col-form-label">Select Document</label>
                  <div className="select-container " style={{maxHeight:"50px" ,overflowY:"auto"}}>
                    <select
                      className="form-control"
                      required
                      placeholder="Select"
                      name="hr_document"
                      value={
                        documentType
                          ? documentType
                          : editedDocumnet?.requested_document
                      }
                      onChange={(e) => setDocumnetType(e.target.value)}
                    >
                      <option value="">Select document</option>
                      {documnetedData?.map((item) => (
                        <option key={item.id} data-id={item.id} value={item?.id}>
                          {item.value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* <div className="submit-section " style={{borderTop:"2px solid red"}}>
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    // aria-label={closeModal&&"Close"}
                    type="submit"
                  >
                    Request
                  </button>
                </div> */}
                <div
                  className="row mt-5"
                  style={{ borderTop: "2px solid #d5d9e0" }}
                >
                  <div className="col-lg-12 text-end form-wizard-button mt-3">
                    <button
                      className="btn btn-primary submit-btn"
                      type="submit"
                      data-bs-dismiss={closeModal&& "modal" }
                      aria-label={closeModal && "Close"}
                    >
                      Request
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateDocument;
