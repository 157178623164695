// import { rolesConfig } from "../rolesConfig";
import { useRole } from "../../context/RoleContext";

const ProtectedButton = ({ page, action, children }) => {
  const { role,rolesConfig } = useRole();
  const rolePermissions = rolesConfig[role]?.pages[page];

  console.log(' rolesConfig[role]?.pages[page] : ',rolePermissions, " : ", rolePermissions ," : ", rolePermissions?.includes(action))

  if (rolePermissions && rolePermissions?.includes(action)) {
    return children;
  }

  return null;
};

export default ProtectedButton;
