import { formatDate } from "../validation";

export const validateDateFields = (date)=>{
    const current_date =   new Date();
    current_date.setHours(0, 0, 0, 0);
    const seletedDate =  new Date(formatDate(date));
    seletedDate.setHours(0, 0, 0, 0);

   if( formatDate(seletedDate)  ===  formatDate(current_date)){
     return false;
   }
   else if (formatDate(seletedDate)  >  formatDate(current_date)) {
   return false;
   } 
   else{
    return true;
   }  
  }


  export function isValidString(str) {
    if (str.includes(' ')) {
        return false;
    }
    const words = str.split('-');

    if (words.length === 1) {
        return true;
    } else {
        for (let i = 0; i < words.length; i++) {
            if (words[i] === '') {
                return false;
            }
        }
        return true;
    }
}