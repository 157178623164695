import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "antd";
import Select from "react-select";
import CompaniesModal from "../../../components/modelpopup/CompaniesModal";
import DateRangePicker from "react-bootstrap-daterangepicker";
import AddNotes from "../../../components/modelpopup/Crm/AddNotes";
import { getAllCompanies } from "../../../services/companyService";
import { formatDateInTimezone } from "../../../utils/formatDateInTimezone";
import axios from "axios";
import config from "../../../config";
import { format } from "date-fns";

const address = {
  address: "",
  city: "",
  state: "",
  pincode: "",
  country: "",
};

const formatDateForSQL = (date) => {
  return format(date, "yyyy-MM-dd");
};

const Companies = () => {
  const [fieldInputs, setFieldInputs] = useState(false);
  const [focused, setFocused] = useState(false);
  const [focusedTwo, setFocusedTwo] = useState(false);
  const [focusedThree, setFocusedThree] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueTwo, setInputValueTwo] = useState("");
  const [inputValueThree, setInputValueThree] = useState("");
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [company, setCompany] = useState({
    company_logo: null,
    company_name: "",
    company_type: "",
    company_registration_number: "",
    date_of_incorporation: null,
    gst_number: "",
    pan: "",
    cin: "",
    tan: "",
    llp: "",
    phone: "",
    email: "",
    updated_by: "",
    created_by: "",
  });
  const [addresses, setAddresses] = useState({
    presentAddress: {
      ...address,
    },
    permanentAddress: {
      ...address,
    },
    isSame: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  console.log(" companies : ", companies);
  console.log(" companies company : ", company);
  console.log(" companies companyAddress : ", addresses);

  const navigate = useNavigate();

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, [isModalOpen]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const data = await getAllCompanies();
        setCompanies(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanies();
  }, []);

  console.log(
    "Companies : ",
    companies,
    " : ",
    "Error : ",
    error,
    " : ",
    " Loading : ",
    loading
  );

  const editData = (id, e) => {
    e.preventDefault();
    setIsModalOpen(true);
    const data = companies.filter((item) => item.id === id);
    const date_of_incorporation = formatDateForSQL(
      data[0].date_of_incorporation
    );
    setCompany({ ...data[0], date_of_incorporation: date_of_incorporation });
    getCompanyAddress(id);
  };

  const deleteCompany = async (e, company_id) => {
    e.preventDefault();
    if (company_id) {
      const res = await axios.put(
        `${config.API_BASE_URL}api/company/${company_id}`
      );
      if (res.status === 200) navigate("../companies"); // fix me
      console.log("Deleted company response", res);
    }
  };

  const getCompanyAddress = async (company_id) => {
    if (company_id) {
      const response = await axios.get(
        `${config.API_BASE_URL}api/companyAdditionalInfo/${company_id}`
      );
      console.log(" Company Address response", response.data[0]);

      if (response.data.length > 0) {
        const info1 = response.data[0].information_one.split(", ");
        const info2 = response.data[0].information_two.split(", ");
        console.log(" info1 : ", info1);
        console.log(" info2 : ", info2);
        if (info1.length >= 4) {
          console.log("PresentAddress : ", {
            address: `${info1[0]}`,
            city: info1[1],
            state: info1[2],
            pinCode: info1[3] || "",
            country: info1[4],
          });
          setAddresses((prev) => ({
            ...prev,
            presentAddress: {
              address: `${info1[0]}`,
              city: info1[1],
              state: info1[2],
              pincode: info1[3] || "",
              country: info1[4],
            },
          }));
        }

        if (info2.length >= 4) {
          console.log("PermanentAddress : ", {
            address: `${info2[0]}`,
            city: info2[1],
            state: info2[2],
            pinCode: info2[3] || "",
            country: info2[4],
          });
          setAddresses((prev) => ({
            ...prev,
            permanentAddress: {
              address: `${info2[0]}`,
              city: info2[1],
              state: info2[2],
              pincode: info2[3] || "",
              country: info2[4],
            },
          }));
        }
      }
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "company_name",
      render: (text, record) => (
        <h2 className="table-avatar d-flex align-items-center">
          {/* <Link to="/company-details" className="company-img">
                <img src={record.image} alt="UserImage" />
              </Link> */}
          <span  className="profile-split">
            {record.company_name}
          </span>
        </h2>
      ),
      sorter: (a, b) => a.company_name.length - b.company_name.length,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },

    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Company type",
      dataIndex: "company_type",
      sorter: (a, b) => a.company_type.length - b.company_type.length,
    },

    {
      title: "Date of incorporation",
      dataIndex: "date_of_incorporation",
      sorter: (a, b) =>
        a.date_of_incorporation.length - b.date_of_incorporation.length,
      render: (text, record) => (
        <>{formatDateInTimezone(record.date_of_incorporation)}</>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <div className="dropdown action-label">
          <Link
            to="#"
            className={
              text === 0
                ? "btn btn-white btn-sm badge-outline-danger status-color-dg"
                : "btn btn-white btn-sm badge-outline-success status-color"
            }
          >
            {text === 0 ? "Inactive" : "active"}
          </Link>
        </div>
      ),

      sorter: (a, b) => a.owner.length - b.owner.length,
    },

    {
      title: "Action",
      sorter: "true",
      render: (record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              // data-bs-toggle="modal"
              // data-bs-target="#add_company"

              onClick={(e) => editData(record.id, e)}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              // data-bs-toggle="modal"
              // data-bs-target="#delete_company"
              onClick={(e) => deleteCompany(e, record.id)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const optionSort = [
    { value: "Germany", label: "Germany" },
    { value: "USA", label: "USA" },
    { value: "Canada", label: "Canada" },
    { value: "India", label: "India" },
    { value: "China", label: "China" },
  ];

  const optionsSortValue = [
    { value: "sortAlphabet", label: "Sort By Alphabet" },
    { value: "ascending", label: "Ascending" },
    { value: "descending", label: "Descending" },
    { value: "recentlyViewed", label: "Recently Viewed" },
    { value: "recentlyAdded", label: "Recently Added" },
  ];

  const handleLabelClick = () => {
    setFocused(true);
  };

  const handleInputBlur = () => {
    if (inputValue === "") {
      setFocused(false);
    }
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value !== "" && !focused) {
      setFocused(true);
    }
  };

  const handleLabelClickTwo = () => {
    setFocusedTwo(true);
  };

  const handleInputBlurTwo = () => {
    if (inputValueTwo === "") {
      setFocusedTwo(false);
    }
  };
  const handleInputChangeTwo = (e) => {
    const value = e.target.value;
    setInputValueTwo(value);
    if (value !== "" && !focusedTwo) {
      setFocusedTwo(true);
    }
  };

  const handleLabelClickThree = () => {
    setFocusedThree(true);
  };

  const handleInputBlurThree = () => {
    if (inputValueThree === "") {
      setFocusedThree(false);
    }
  };
  const handleInputChangeThree = (e) => {
    const value = e.target.value;
    setInputValueThree(value);
    if (value !== "" && !focusedThree) {
      setFocusedThree(true);
    }
  };

  const initialSettings = {
    endDate: new Date("2020-08-11T12:30:00.000Z"),
    ranges: {
      "Last 30 Days": [
        new Date("2020-07-12T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last 7 Days": [
        new Date("2020-08-04T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last Month": [
        new Date("2020-06-30T18:30:00.000Z"),
        new Date("2020-07-31T18:29:59.999Z"),
      ],
      "This Month": [
        new Date("2020-07-31T18:30:00.000Z"),
        new Date("2020-08-31T18:29:59.999Z"),
      ],
      Today: [
        new Date("2020-08-10T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      Yesterday: [
        new Date("2020-08-09T04:57:17.076Z"),
        new Date("2020-08-09T04:57:17.076Z"),
      ],
    },
    startDate: new Date("2020-08-04T04:57:17.076Z"), // Set "Last 7 Days" as default
    timePicker: false,
  };

  const [isFullScreen, setFullScreen] = useState(false);
  const maximizeBtnRef = useRef(null);

  useEffect(() => {
    const handleClick = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        setFullScreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          setFullScreen(false);
        }
      }
    };

    const cleanup = () => {
      if (isFullScreen && document.exitFullscreen) {
        document.exitFullscreen();
        setFullScreen(false);
      }
    };

    const maximizeBtn = maximizeBtnRef.current;
    maximizeBtn.addEventListener("click", handleClick);

    // Cleanup function to remove the event listener and exit fullscreen on component unmount
    return () => {
      maximizeBtn.removeEventListener("click", handleClick);
      cleanup();
    };
  }, [isFullScreen]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  return (
    <div className="page-wrapper">
      {/* Page Content */}
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col-md-4">
              <h3 className="page-title">Companies</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="../admin-dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Companies</li>
              </ul>
            </div>
            <div className="col-md-8 float-end ms-auto">
              <div className="d-flex title-head">
                <div className="view-icons">
                  {/* <Link to="#" className="grid-view btn btn-link">
                    <i className="las la-redo-alt" />
                  </Link> */}
                  <Link
                    to="#"
                    className="list-view btn btn-link"
                    id="collapse-header"
                    ref={maximizeBtnRef}
                  >
                    <i className="las la-expand-arrows-alt" />
                  </Link>
                  {/* <Link
                    to="#"
                    className={
                      fieldInputs
                        ? "list-view btn btn-link active-filter"
                        : "list-view btn btn-link"
                    }
                    id="filter_search"
                    onClick={() => setFieldInputs(!fieldInputs)}
                  >
                    <i className="las la-filter" />
                  </Link> */}
                </div>
                {/* <div className="form-sort">
                  <Link
                    to="#"
                    className="list-view btn btn-link"
                    data-bs-toggle="modal"
                    data-bs-target="#export"
                  >
                    <i className="las la-file-export" />
                    Export
                  </Link>
                </div> */}
                <Link
                  to="#"
                  className="btn add-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsModalOpen(true);
                    setCompany({
                      company_logo: null,
                      company_name: "",
                      company_type: "",
                      company_registration_number: "",
                      date_of_incorporation: null,
                      gst_number: "",
                      pan: "",
                      cin: "",
                      tan: "",
                      llp: "",
                      phone: "",
                      email: "",
                      updated_by: localStorage.getItem("employeeID"),
                      created_by: localStorage.getItem("employeeID"),
                    });
                    setAddresses({
                      presentAddress: {
                        ...address,
                      },
                      permanentAddress: {
                        ...address,
                      },
                      isSame: false,
                    });
                  }}
                >
                  <i className="la la-plus-circle" /> Add Company
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        {/* Search Filter */}
        {/* <div
          className="filter-filelds"
          id="filter_inputs"
          style={{ display: fieldInputs ? "block" : "none" }}
        >
          <div className="row filter-row">
            <div className="col-xl-2">
              <div
                className={
                  focused || inputValue !== ""
                    ? "input-block form-focus focused"
                    : "input-block form-focus"
                }
              >
                <input
                  type="text"
                  className="form-control floating"
                  value={inputValue}
                  onFocus={handleLabelClick}
                  onBlur={handleInputBlur}
                  onChange={handleInputChange}
                  required
                  minLength="3"
                />
                <label className="focus-label" onClick={handleLabelClick}>
                  Company Name
                </label>
              </div>
            </div>
            <div className="col-xl-2">
              <div
                className={
                  focusedTwo || inputValueTwo !== ""
                    ? "input-block form-focus focused"
                    : "input-block form-focus"
                }
              >
                <input
                  type="text"
                  className="form-control floating"
                  value={inputValueTwo}
                  onFocus={handleLabelClickTwo}
                  onBlur={handleInputBlurTwo}
                  onChange={handleInputChangeTwo}
                  required
                  minLength="3"
                />
                <label className="focus-label" onClick={handleLabelClickTwo}>
                  Email
                </label>
              </div>
            </div>
            <div className="col-xl-2">
              <div
                className={
                  focusedThree || inputValueThree !== ""
                    ? "input-block form-focus focused"
                    : "input-block form-focus"
                }
              >
                <input
                  type="text"
                  className="form-control floating"
                  value={inputValueThree}
                  onFocus={handleLabelClickThree}
                  onBlur={handleInputBlurThree}
                  onChange={handleInputChangeThree}
                  required
                  maxLength="10"
                  minLength="10"
                />
                <label className="focus-label" onClick={handleLabelClickThree}>
                  Phone Number
                </label>
              </div>
            </div>
            <div className="col-xl-2">
              <div className="input-block mb-3 form-focus focused">
                <DateRangePicker initialSettings={initialSettings}>
                  <input
                    className="form-control  date-range bookingrange"
                    type="text"
                    required
                  />
                </DateRangePicker>
                <label className="focus-label">From - To Date</label>
              </div>
            </div>
            <div className="col-xl-2">
              <div className="input-block mb-3 form-focus select-focus">
                <Select
                  options={optionSort}
                  placeholder="--Select--"
                  className="select floating"
                  isSearchable={false}
                  styles={customStyles}
                  required
                />
                <label className="focus-label">Location</label>
              </div>
            </div>
            <div className="col-xl-2">
              <Link to="#" className="btn btn-success w-100">
                {" "}
                Search{" "}
              </Link>
            </div>
          </div>
        </div>
        <hr /> */}
        {/* /Search Filter */}
        {/* <div className="filter-section filter-bottom">
          <ul>
            <li>
              <div className="form-sort value-contain">
                <i className="las la-sort-alpha-up-alt" />
                <Select
                  options={optionsSortValue}
                  placeholder="Sort By Alphabet"
                  className="select w-100"
                  isSearchable={false}
                  styles={customStyles}
                  required
                />
              </div>
            </li>
            <li>
              <div className="search-set">
                <div className="search-input">
                  <Link to="#" className="btn btn-searchset">
                    <i className="las la-search" />
                  </Link>
                  <div className="dataTables_filter">
                    <label>
                      {" "}
                      <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder="Search"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div> */}

        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <Table
                className="table-striped"
                columns={columns}
                dataSource={companies}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
      </div>
      <CompaniesModal
        company={company}
        setCompany={setCompany}
        addresses={addresses}
        setAddresses={setAddresses}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
      />
      <AddNotes />
      {isModalOpen && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default Companies;
