import React from "react";
import { Select, Table } from "antd";
import { Link } from "react-router-dom";
import "../assets/css/paySlips.css";

const { Option } = Select;

function PaySlips() {
  const columns = [
    {
      title: "Month",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "View",
      key: "payslip",
      render: (_, record) => (
        <Link to="#" className="btn btn-sm btn-primary" onClick={() => {}}>
          Generate Slip
        </Link>
      ),
    },
  ];

  return (
    <>
      {" "}
      <div className="pay-slips">
        <h2>Pay slips for 2024 - 2025</h2>
        <div className="financialYearDropdown">
          <label>Select Financial Year:</label>
          <Select defaultValue="2024-25" style={{ width: 120 }}>
            {/* <Option value="2023-24">2023-24</Option> */}
            <Option value="2024-25">2024-25</Option>
            {/* <Option value="2025-26">2025-26</Option> */}
          </Select>
        </div>
      </div>
      <div className="row">
        <div className="table-responsive">
          <Table dataSource={[]} columns={columns} rowKey="employee_id" />
        </div>
      </div>
    </>
  );
}

export default PaySlips;
