import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SalaryTable from './SalaryTable';
import config from '../../../../../config';

const EmployeeSalary = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectTwo, setSelectTwo] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateTwo, setSelectedDateTwo] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [employees, setEmployees] = useState([]);
  const [showSalarySlip, setShowSalarySlip] = useState(false);
  const [salarySlipData, setSalarySlipData] = useState(null);

  useEffect(() => {
    const fetchEmployeeSalaries = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}api/employee-salaries`);
        setEmployees(response.data);
      } catch (error) {
        console.error('Error fetching employee salaries:', error);
      }
    };

    fetchEmployeeSalaries();
  }, []);

  const generateSalarySlip = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}api/generate-salary-slip`, {
        params: {
          employeeId: selectedOption.value,
          fromDate: selectedDate,
          toDate: selectedDateTwo,
        },
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `salary_slip_${selectedOption.value}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error generating salary slip:', error);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Employee Salary"
            title="Dashboard"
            subtitle="Salary"
            modal="#add_salary"
            name="Add Salary"
          />
          <div className="row filter-row">
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className={isFocused || inputValue !== '' ? 'input-block form-focus focused' : 'input-block form-focus'}>
                <input
                  type="text"
                  className="form-control floating"
                  value={inputValue}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(inputValue !== '')}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <label className="focus-label" onClick={() => setIsFocused(true)}>
                  Employee Name
                </label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="input-block mb-3 form-focus select-focus">
                <Select
                  placeholder="--Select--"
                  onChange={setSelectedOption}
                  options={employees.map(employee => ({ value: employee.employee_id, label: `${employee.first_name} ${employee.last_name}` }))}
                  className="select floating"
                />
                <label className="focus-label">Employee</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className={`input-block mb-3 form-focus ${isFocused ? 'focused' : ''}`}>
                <div className="cal-icon">
                  <DatePicker
                    className="form-control floating datetimepicker"
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <label className="focus-label">From</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className={`input-block mb-3 form-focus ${isFocused ? 'focused' : ''}`}>
                <div className="cal-icon">
                  <DatePicker
                    className="form-control floating datetimepicker"
                    selected={selectedDateTwo}
                    onChange={(date) => setSelectedDateTwo(date)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <label className="focus-label">To</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-1 col-12">
              <a href="#" className="btn btn-success btn-block" onClick={generateSalarySlip}>
                Search
              </a>
            </div>
          </div>
          <div className="row">
            <SalaryTable employees={employees} />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeSalary;
