import React, { useEffect, useState } from "react";
import "../assets/css/grossProration.css";
import axios from "axios";
import config from "../config";

const GrossProration = ({ salary, salaryConfig }) => {
  const [grossProration, setGrossProration] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  // const [salaryConfig, setSalaryConfig] = useState({
  //   id: 1,
  //   company_id: 1,
  //   basic_percentage: "50.00",
  //   hra_percentage: "40.00",
  //   medical_allowance_percentage: "5.00",
  //   statutory_bonus_percentage: "25.00",
  //   other_allowance_percentage: "30.00",
  // });

  const { company } = JSON.parse(localStorage.getItem("user"));

  const employeeId = localStorage.getItem("employeeID");

  console.log(" grossProration : ", grossProration);
  console.log(" salary from props : ", salary);
  console.log(" salaryConfig in props : ", salaryConfig);

  const calculateBreakdown = (salary, config) => {
    const basic = salary * (config.basic_percentage / 100);
    const hra = basic * (config.hra_percentage / 100);
    const medicalAllowance =
      basic * (config.medical_allowance_percentage / 100);
    const statutoryBonus = basic * (config.statutory_bonus_percentage / 100);
    const otherAllowance = basic * (config.other_allowance_percentage / 100);
    const totalPay =
      basic + hra + medicalAllowance + statutoryBonus + otherAllowance;

    return {
      basic,
      hra,
      medicalAllowance,
      statutoryBonus,
      otherAllowance,
      totalPay,
    };
  };

  useEffect(() => {
    if (salary) {
      setGrossProration(calculateBreakdown(salary, salaryConfig));
    } else {
      axios
        .get(
          `${config.API_BASE_URL}employees/companies/${company.company_id}/employees/${employeeId}/salary-breakdown`
        )
        .then((response) => {
          console.log("response config ", response.data.config);
          // setSalaryConfig(response.data.config);
          setGrossProration(response.data.breakdown);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, []);

  return (
    <div className="gross-proration-container">
      <div className="gross-proration">
        <h2>Annual & Monthly Gross Salary</h2>
        <table>
          <thead>
            <tr>
              <th>Salary Components</th>
              <th>Amount Monthly</th>
            </tr>
          </thead>
          {grossProration && (
            <tbody>
              <tr>
                <td>Basic: </td>
                <td> {grossProration?.basic?.toFixed(2)}</td>
              </tr>
              <tr>
                <td>HRA: </td>
                <td>{grossProration?.hra?.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Medical Allowance: </td>
                <td>{grossProration?.medicalAllowance?.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Statutory Bonus: </td>
                <td>{grossProration?.statutoryBonus?.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Other Allowance: </td>
                <td>{grossProration?.otherAllowance.toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bolder" }}>Total Pay: </td>
                <td style={{ fontWeight: "bolder" }}>
                  {grossProration?.totalPay?.toFixed(2)}
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default GrossProration;
