import axios from "axios"
import config from "../config"

let token = localStorage.getItem("token");
const configuration = {
    headers: {
        Authorization: `Bearer ${token}`
      },
}

export const getEmployeeData = async(path)=>{
    let userEmail = localStorage.getItem("user");
    let user = JSON.parse(userEmail)
    const email = user.email;
    console.log("user email is" , email);

   const res = await axios.post(`${config.API_BASE_URL}${path}`,{email}, configuration );
   console.log("response from getEmployeeData -->" , res);
   localStorage.setItem("employeeID" ,res?.data?.results?.employee_id );
   return res;

}

export const postData = async(path , data) =>{
    const res = await axios.post(`${config.API_BASE_URL}${path}`, data )
    console.log("policy response--->" , res);
    return res;

}

export const getData = async(path) =>{
    const res = await axios.get(`${config.API_BASE_URL}${path}` , configuration)
    console.log("policy response--->" , res);
    return res;

}