export const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
};

export const validatePhoneNumber = (phoneNumber) => {
    const re = /^[0-9]{10}$/;
    return re.test(phoneNumber);
};

export const validatorName = (name) => {
    if(name.length >=3){
        return true;
    }
    return false;
};

export const formatDate = (dateString) => {
  if(dateString== null || dateString == undefined){
    return;
  }
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};


export const getTimeInAMPMFormat = (dateTimeString) => {
    const dateObj = new Date(dateTimeString);
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    const timeFormatted = new Intl.DateTimeFormat('en-US', options).format(dateObj);
    return timeFormatted;
  };
export const formatDate2 = (dateString) => {
  console.log("data string" , dateString)
    const dateObj = new Date(dateString);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(dateObj);
    return formattedDate;
  };
  export const formatDate3 = (dateString) => {
    console.log("date string", dateString);
    
    // Create a Date object from the input date string
    const dateObj = new Date(dateString);
    
    // Manually subtract one year from the date
    dateObj.setFullYear(dateObj.getFullYear());
    
    // Format the date as 'YYYY-MM-DD'
    const formattedDate = `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')}`;
    
    return formattedDate;
  };
  

export const formatDateFullMonth = (timeString) => {
  const [hours, minutes] = timeString.split(':');

  // Create a Date object for today's date
  const today = new Date();

  // Set the time on the today's date object
  today.setHours(hours);
  today.setMinutes(minutes);
  today.setSeconds(0); // Set seconds to 0 as it's not relevant for this format

  // Format the time using Intl.DateTimeFormat with hour12 option enabled
  const formattedTime = today.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return formattedTime;
  };
  

  export const convertTimeFormat = (time) => {
    console.log("time" , time);
    const [hours, minutes] = time.split(':');
    let period = 'AM';
    let hour = parseInt(hours, 10);
  
    if (hour >= 12) {
      period = 'PM';
      if (hour > 12) {
        hour -= 12;
      }
    } else if (hour === 0) {
      hour = 12;
    }
  
    const formattedTime = `${hour}.${minutes} ${period}`;
    return formattedTime;
  };
  
  const time = "09:59:59";
  const formattedTime = convertTimeFormat(time);
  console.log(formattedTime);  // Outputs: "9.59 AM"


  const parseTimeToHours = (time) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours + minutes / 60 + seconds / 3600;
  };
  
  const formatTotalHours = (totalHours) => {
    const hours = Math.floor(totalHours);
    const minutes = Math.floor((totalHours % 1) * 60);
    const seconds = Math.round(((totalHours * 60) % 1) * 60);
  
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };
  

  export const calculateOvertime = (totalHoursStr , estimatedWorkingHours) => {
    const totalHours = parseTimeToHours(totalHoursStr);
    const overtime = totalHours - estimatedWorkingHours;
  
    if (overtime < 0 ) {
      return formatTotalHours(overtime);
    } else {
      return "00:00:00"; // No overtime
    }
  };
  

  export const convertToDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  export const  convertIntoHRMMSS = (totalWorkTime)=>{
    let seconds = Math.floor((totalWorkTime / 1000) % 60);
    let minutes = Math.floor((totalWorkTime / (1000 * 60)) % 60);
    let hours = Math.floor((totalWorkTime / (1000 * 60 * 60)) % 24);

const formattedHours = hours.toString().padStart(2, '0');
const formattedMinutes = minutes.toString().padStart(2, '0');
const formattedSeconds = seconds.toString().padStart(2, '0');
return  `${formattedHours}:${formattedMinutes}:${formattedSeconds}`

  }

  export const  convertIntoHRMMSSOverTime = (totalWorkTime)=>{
    let seconds = Math.floor((totalWorkTime / 1000) % 60);
    let minutes = Math.floor((totalWorkTime / (1000 * 60)) % 60);
    let hours = Math.floor((totalWorkTime / (1000 * 60 * 60)) % 24);

    let overTime1 = 0;
   if(hours > 2){
    overTime1 = hours - 2;
    hours = 2
   }

const formattedHours = hours.toString().padStart(2, '0');
const formattedMinutes = minutes.toString().padStart(2, '0');
const formattedSeconds = seconds.toString().padStart(2, '0');
const formattedOvertimeHours = overTime1.toString().padStart(2, '0');
return  `${formattedOvertimeHours}:${formattedMinutes}:${formattedSeconds}`


  }
  
export const formatDateToFullDateString = (dateString) => {
  // Convert the date string to a Date object
  const date = new Date(dateString);

  // Format the date using Intl.DateTimeFormat with the desired options
  const options = {
    weekday: 'short', // "Mon"
    month: 'short',   // "Aug"
    day: 'numeric',   // "12"
    year: 'numeric',  // "2024"
  };

  const formattedDate = date.toLocaleDateString('en-US', options);

  return formattedDate;
};

export function isDateMatch(date) {
  if(date === null|| date === undefined){
    return true;
  }
  // Get the current date and time
  const currentDate = new Date();

  // Define the date you want to match
  const targetDate = new Date(date);

  // Compare the two dates
  if (
    currentDate.getFullYear() === targetDate.getFullYear() &&
    currentDate.getMonth() === targetDate.getMonth() &&
    currentDate.getDate() === targetDate.getDate() 
  ) {
    return true; // Dates match
  }

  return false; // Dates do not match
}

