import React, { useEffect, useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { differenceInDays, format } from "date-fns";
import Form from "react-bootstrap/Form";
import { message } from "antd";
import axios from "axios";
import config from "../../config";
import * as Yup from "yup";
import createCustomSchema from "../../fieldValidator/fieldValidator";
import { useNavigate } from "react-router-dom";
import { companyID } from "../../auth";

const EmployeeLeaveModelPopup = ({
  leave,
  setLeave,
  isModalOpen,
  setIsModalOpen,
  editedID,
  previousLeaveDays
}) => {
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  console.log("previousLeaveDays 2", editedID);


  // const [leave, setLeave] = useState({
  //   employee_id: "",
  //   start_date: "",
  //   end_date: "",
  //   leave_type: "",
  //   message: "",
  //   leave_reason: "",
  //   leave_days: "",
  //   leave_apply_period: "1",
  // });
  const [daysError, setDaysError] = useState("");
  const [halfDay, setHalfDay] = useState(false);
  // const [modalClose, setModalClose] = useState(false);
  const [showReportees, setShowReportees] = useState(false);
  const [reportingData, setReportingData] = useState([]);
  const [leaveError, setLeaveError] = useState({});
  const [balanceDays , setBalanceDays] = useState(null);
  const [extraLeaveError , setExtraLeaveError] = useState(false)
  const [leaveAppliedInAdvanceError, setLeaveAppliedInAdvanceError] =
    useState("");

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  // console.log("reportingData : ", reportingData);
  console.log("leaveAppliedInAdvanceError : ", leaveAppliedInAdvanceError);
   
 

  const employeeID = localStorage.getItem("employeeID");

  const validationSchema = Yup.object({
    leave_type: createCustomSchema("dropdown", {
      message: "Select leave type",
    }),
    start_date: createCustomSchema("date", {
      message: "Enter start date",
    }),
    end_date: createCustomSchema("date", {
      message: "Enter end date",
    }),
    leave_reason: createCustomSchema("dropdown", {
      message: "Select leave reason",
    }),
  });

  useEffect(() => {
    const fetchReportingData = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}reportees/${employeeID}`
        );
        const reportees = response.data.map((reportee, index) => ({
          value: reportee.employee_id,
          label:
            reportee.employee_first_name + " " + reportee.employee_last_name,
        }));
        setReportingData(reportees);
      } catch (error) {
        console.error("Error fetching reporting data:", error);
      }
    };

    fetchReportingData();
  }, [employeeID]);

  useEffect(() => {
    setLeave({ ...leave, employee_id: employeeID });
  }, []);

  // console.log(" halfDay : ", halfDay);

  console.log(" leave modal state: ", leave);

  useEffect(() => {
    if (leave.end_date) {
      calculateDays();
      getLeaveDates(leave.start_date, leave.end_date);
      
    }
  }, [selectedDate1, selectedDate2]);

  const calculateDays = () => {
    const fromDate = leave.start_date;
    const toDate = leave.end_date;
    if (fromDate && toDate) {
      if (toDate < fromDate) {
        setDaysError("Start date cannot be earlier than end date.");
      } else {
        // const daysBetween =
        //   differenceInDays(new Date(toDate), new Date(fromDate)) + 1;
        setDaysError("");
        // setLeave({ ...leave, leave_days: daysBetween });
      }
    }
  };

  function getLeaveDates(startDateStr, endDateStr) {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const leaveDates = [];
    const isStartWeekday = startDate.getDay() !== 6 && startDate.getDay() !== 0;
    const isEndWeekday = endDate.getDay() !== 6 && endDate.getDay() !== 0;
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();
      if (isStartWeekday && isEndWeekday) {
        leaveDates.push(new Date(currentDate));
      } else {
        if (dayOfWeek !== 6 && dayOfWeek !== 0) {
          leaveDates.push(new Date(currentDate));
        }
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    // console.log("leaveDates.length : ", leaveDates.length);
    // return leaveDates;
    checkBalanceLeave(leaveDates.length);
    setLeave({ ...leave, leave_days: leaveDates.length });
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setLeave({ ...leave, [name]: value });
  };

  const isLeaveAppliedInAdvance = (startDate) => {
    const currentDate = new Date();

    const startDateMillis = startDate.getTime();
    const currentDateMillis = currentDate.getTime();
    const differenceMillis = Math.abs(currentDateMillis - startDateMillis);

    const differenceDays = Math.ceil(differenceMillis / (1000 * 60 * 60 * 24));
    if (!(differenceDays > 7)) {
      setLeaveAppliedInAdvanceError("Apply before 1 week");
    }
    return differenceDays > 7;
  };

  const fetchBalanceLeavesDays = async(id) =>{
    try{
      const employee_id = localStorage.getItem("employeeID");
      const company_id = companyID()
        const  response = await axios.get(`${config.API_BASE_URL}balance-leave/${employee_id}/${id}/${company_id}`)
        console.log("balance days" , response); 
        if( response?.data?.success === true){
          response?.data?.result[0]?.total_days ? setBalanceDays(response?.data?.result[0]?.total_days): response?.data?.result[0]?.remaining_days ?  setBalanceDays(response?.data?.result[0]?.remaining_days) : setBalanceDays(0);
        }
        console.log("Levae type Id response" , response);
    }
    catch(err){
      console.log("Levae type Id response" , err);
    }
  }



  useEffect(()=>{ 
    fetchBalanceLeavesDays(leave?.leave_type);
   
  },[editedID , leave?.id , selectedDate2])
  

  console.log("prevous levae day" ,leave );

  const handleDropDownChange = async(key, selectedOption) => {
    console.log("leave Selected Option" , selectedOption);
    setLeave((prevState) => ({
      ...prevState,
      [key]: selectedOption.value,
    }));
    fetchBalanceLeavesDays(selectedOption.value);
   
  };

  console.log("balance days" , balanceDays); 
 
  const [balanceLeave , setBalanceLeave] = useState(0);

  const checkBalanceLeave =(leave_days )=>{
    console.log("checkBalanceLeave", balanceDays + previousLeaveDays, 'nit balanceDays',balanceDays );
    if(leave_days != null && balanceDays != null && !editedID){
      if( balanceDays < leave_days ){
       alert(`You do not have a sufficient leave , Only ${balanceDays} leaves are balance ! `);
       setBalanceLeave(balanceDays);
       setExtraLeaveError(true);
       return; // open modal for extra Leave taken;
      }
      else {
       setExtraLeaveError(false);
      }
     
    }
    else if(leave_days != null && balanceDays != null && editedID){
      if(balanceDays + previousLeaveDays <  leave_days){
        alert(`You do not have a sufficient leave , Only ${balanceDays + previousLeaveDays} leaves are balance ! `);
        setBalanceLeave(balanceDays + previousLeaveDays);
        setExtraLeaveError(true);
        return;
      }
    }
  }

  // useEffect(()=>{

   
  // },[  leave?.end_date])

  const formatDateForSQL = (date) => {
    return format(date, "yyyy-MM-dd");
  };

  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
    const start_date = formatDateForSQL(date);
    setLeave({ ...leave, start_date: start_date });
  };
  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
    const end_date = formatDateForSQL(date);
    setLeave({ ...leave, end_date: end_date });
    fetchBalanceLeavesDays(editedID)
  };

  const leaveReason = [
    { value: 1, label: "Illness" },
    { value: 2, label: "Family Emergency" },
    { value: 3, label: "Childcare" },
    { value: 4, label: "Vacation Travel" },
    { value: 5, label: "Personal Reasons" },
    { value: 6, label: "Medical Appointment" },
  ];

  const leavetype = [
    { value: 1, label: "Sick Leave" },
    { value: 2, label: "Casual Leave" },
    { value: 3, label: "Vacation Leave" },
    { value: 4, label: "Unpaid Leave" },
  ];

  const reportees = [
    { value: 1, label: "Jishan" },
    { value: 2, label: "Roshan" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const handleHalfDaySwitch = (e) => {
    setHalfDay(e.target.checked);
  };

  const handlePeriodChange = (e) => {
    setLeave({ ...leave, leave_apply_period: e.target.value });
  };



  const onSubmit = (e) => {
    e.preventDefault();

    if(extraLeaveError == true){
      alert(`You do not have a sufficient leave , Only ${balanceLeave} leaves are balance ! `);
      console.log("balance days" , "extra levaes days", extraLeaveError); 
      return ; // open Modal for extra Leave taken
    }

    console.log("leave state during submit fun: ", leave);
    console.log("daysError! : ", !daysError, "daysError : ", daysError);
    let eligibleForCasualAndVacationLeave = true;
    const start_date = new Date(leave.start_date);
    if (leave.leave_type == 2 || leave.leave_type == 3) {
      eligibleForCasualAndVacationLeave = isLeaveAppliedInAdvance(start_date);
    }
    console.log(
      "daysError! : ",
      !daysError,
      "eligibleForCasualAndVacationLeave : ",
      eligibleForCasualAndVacationLeave,
      " : ",
      !daysError && eligibleForCasualAndVacationLeave
    );
    if (!daysError && eligibleForCasualAndVacationLeave) {
      setLeaveAppliedInAdvanceError("");
      if(editedID){
        const leave_data = {...leave, company_id:companyID(), previousLeaveDays : previousLeaveDays};
        axios
        .put(`${config.API_BASE_URL}applyLeave/${editedID}`, leave_data)
        .then((response) => {
          console.log("Leave updated successfully:", response.data);
          // setModalClose(true);
          setIsModalOpen(false);
          navigate("../leaves-employee");
        })
        .catch((error) => {
          console.error("There was an error creating the leave!", error);
        }); 
      }
      else{
        console.log("Leave created successfully:", "calling...." , leave);
        const leave_data = {...leave, company_id:companyID()};
        console.log("leave_data" , leave_data);
        axios
        .post(`${config.API_BASE_URL}applyLeave`, leave_data)
        .then((response) => {
          console.log("Leave created successfully:", response);
          console.log("Leave created successfully:", response.data);
          // setModalClose(true);
          setIsModalOpen(false);
          navigate("../leaves-employee");
        })
        .catch((error) => {
          console.error("There was an error creating the leave!", error);
        }).finally(()=>{
          //  axios.post(`${config.API_BASE_URL}deduct-leave/`, leave)
        })
      }
    } else {
      console.log("submit fail");
    }
  };

  // const selectedLeaveReason = leaveReason.find(
  //   (option) => option.value === leave.leave_reason
  // );

  const getSelectedOption = (options, value) => {
    return options.find((option) => option.value === value);
  };

  const onSubmitYup = async (e) => {
    e.preventDefault();
   
    try {
      await validationSchema.validate(leave, { abortEarly: false });
      console.log("Form submit : ", leave);
      console.log("Form submit error: ", leaveError);
      setLeaveError({});
      onSubmit(e);
    } catch (error) {
      const newError = {};

      // console.log("yup error : ", error.inner);

      error.inner.forEach((err) => {
        newError[err.path] = err.message;
      });
      setLeaveError(newError);
      console.log("yup error : ", newError);
    }
  };

  return (
    <>
      {/* <div id="add_leave" className="modal custom-modal fade" role="dialog"> */}
      <div
        id="add_leave"
        className={`modal custom-modal fade ${
          isModalOpen ? "show d-block" : ""
        }`}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{editedID ? "Edit Leave " : "Add Leave" }</h5>
              <button type="button" className="btn-close" onClick={closeModal}>
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {/* <form onSubmit={onSubmit}> */}
              <form onSubmit={onSubmitYup}>
                <div className="input-block mb-3">
                  <Form.Check
                    inline
                    label="For myself"
                    name="group1"
                    type="radio"
                    id="radio1"
                    checked={!showReportees}
                    onChange={() => {
                      setShowReportees(false);
                      setLeave({ ...leave, employee_id: employeeID });
                    }}
                  />
                  {reportingData.length > 0 && (
                    <Form.Check
                      inline
                      label="For other"
                      name="group1"
                      type="radio"
                      id="radio2"
                      checked={showReportees}
                      onChange={() => setShowReportees(true)}
                    />
                  )}
                </div>
                {showReportees && (
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Select Employee <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={reportingData}
                      placeholder="Select"
                      styles={customStyles}
                      onChange={(selectedOption) =>
                        handleDropDownChange("employee_id", selectedOption)
                      }
                    />
                  </div>
                )}
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={leavetype}
                    placeholder="Select"
                    styles={customStyles}
                    value={getSelectedOption(leavetype, leave.leave_type)}
                    onChange={(selectedOption) =>
                      handleDropDownChange("leave_type", selectedOption)
                    }
                    isDisabled={editedID ? true : false}
                  />
                  {leaveError.leave_type && (
                    <span className="text-danger">
                      *{leaveError.leave_type}
                    </span>
                  )}
                </div>
                {(leave.leave_type === 1 || leave.leave_type === 4) && (
                  <div className="input-block mb-3 d-flex gap-2">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Half day"
                      onChange={handleHalfDaySwitch}
                    />

                    {halfDay && (
                      <>
                        <Form.Check
                          inline
                          label="First half"
                          name="group2"
                          type="radio"
                          id="radio3"
                          value={2}
                          checked={leave.leave_apply_period === "2"}
                          onChange={handlePeriodChange}
                        />
                        <Form.Check
                          inline
                          label="Second half"
                          name="group2"
                          type="radio"
                          id="radio4"
                          value={3}
                          checked={leave.leave_apply_period === "3"}
                          onChange={handlePeriodChange}
                        />
                      </>
                    )}
                  </div>
                )}
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    From <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      selected={
                        selectedDate1 ||
                        (leave.start_date ? new Date(leave.start_date) : null)
                      }
                      onChange={handleDateChange1}
                      className="form-control datetimepicker"
                      type="date"
                      dateFormat="dd-MM-yyyy"
                    />
                    {leaveError.start_date && (
                      <span className="text-danger">
                        *{leaveError.start_date}
                      </span>
                    )}
                    {leaveAppliedInAdvanceError && (
                      <span className="text-danger">
                        *{leaveAppliedInAdvanceError}
                      </span>
                    )}
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    To <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      selected={
                        selectedDate2 ||
                        (leave.end_date ? new Date(leave.end_date) : null)
                      }
                      onChange={handleDateChange2}
                      className="form-control datetimepicker"
                      type="date"
                      dateFormat="dd-MM-yyyy"
                    />
                    {leaveError.end_date && (
                      <span className="text-danger">
                        *{leaveError.end_date}
                      </span>
                    )}
                  </div>
                  {daysError && (
                    <span className="text-danger">* {daysError}</span>
                  )}
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Number of days <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    readOnly
                    type="text"
                    value={leave.leave_days}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave reason <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={leaveReason}
                    placeholder="Select"
                    styles={customStyles}
                    value={getSelectedOption(leaveReason, leave.leave_reason)}
                    onChange={(selectedOption) =>
                      handleDropDownChange("leave_reason", selectedOption)
                    }
                  />
                  {leaveError.leave_reason && (
                    <span className="text-danger">
                      *{leaveError.leave_reason}
                    </span>
                  )}
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">Message</label>
                  <textarea
                    name="message"
                    rows={4}
                    className="form-control"
                    value={leave.message}
                    onChange={handleOnChange}
                  />
                </div>
                <div className="submit-section">
                  <button
                    // disabled={modalClose}
                    className="btn btn-primary submit-btn"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div id="edit_leave" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Leave</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={leavetype}
                    placeholder="Select"
                    styles={customStyles}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    From <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      selected={selectedDate2}
                      onChange={handleDateChange2}
                      className="form-control datetimepicker"
                      type="date"
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    To <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      selected={selectedDate2}
                      onChange={handleDateChange2}
                      className="form-control datetimepicker"
                      type="date"
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Number of days <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    readOnly
                    type="text"
                    defaultValue={2}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Remaining Leaves <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    readOnly
                    defaultValue={12}
                    type="text"
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Reason <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows={4}
                    className="form-control"
                    defaultValue={"Going to hospital"}
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="reset"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeLeaveModelPopup;
