import { fromUnixTime, parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { DateTime } from 'luxon';


export const formatDateInTimezone = (dateInput) => {
  if (dateInput === null || dateInput === undefined ) return;
  const timeZone = "Asia/Kolkata";
  const date =
    typeof dateInput === "number"
      ? fromUnixTime(dateInput)
      : parseISO(dateInput);

  return formatInTimeZone(date, timeZone, "yyyy-MM-dd");
};


export const formatDateTimeInTimezone = (dateInput) => {
  if (dateInput === null || dateInput === undefined ) return;

  const timeZone = "Asia/Kolkata";
  const date =
    typeof dateInput === "number"
      ? fromUnixTime(dateInput)
      : parseISO(dateInput);

  return formatInTimeZone(date, timeZone, "yyyy-MM-dd HH:mm:ss");
};
// let currentDateTime = null;

// export const formatDateTimeInTimezone = (initialDateTimeString, secondsIncrement) => {
//   if (!currentDateTime) {
//     // Initialize currentDateTime if it's not already set
//     currentDateTime = DateTime.fromISO(initialDateTimeString, { zone: 'utc' });
//   } else {
//     // Increment currentDateTime by secondsIncrement seconds
//     currentDateTime = currentDateTime.plus({ seconds: secondsIncrement });
//   }

//   // Format currentDateTime in the desired format
//   const formattedDate = currentDateTime.toFormat("yyyy-MM-dd HH:mm:ss");
  
//   // Construct the final formatted string with the dynamic "seconds" part
//   const formattedDateTime = `${formattedDate} ${secondsIncrement} seconds`;

//   return formattedDateTime;
// };


export const getFirstDateOfCurrentMonth = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1; // getMonth() returns 0-based index
  const day = '01'; // First day of the month

  // Format the date as YYYY-MM-DD
  const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day}`;
  return formattedDate;
};

export const getMonthOfCurrentMonth = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1; // getMonth() returns 0-based index
  const day = '01'; // First day of the month

  // Format the date as YYYY-MM-DD
  const formattedDate = `${month < 10 ? '0' : ''}${month}`;
  return formattedDate;
};


export const formatTo12HourTime = (dateString) => {
  // Convert the date string to a Date object
  const date = new Date(dateString);

  // Extract hours and minutes
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  // Determine AM/PM suffix
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Combine into the desired format
  const formattedTime = `${hours}:${minutes} ${ampm}`;

  return formattedTime;
};


export function formatCustomDate2(dateString) {
  const options = { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' };
  
  // Convert the string into a Date object
  const date = new Date(dateString);
  
  // Format the date using toLocaleDateString
  return date.toLocaleDateString('en-GB', options);
}

// Example usage
// const formattedDate = formatDate('2024-10-31T18:30:00.000Z');
// console.log(formattedDate);

export function getTotalWorkingDays(year, holidays) {
  let totalWorkingDays = 0;
  const startDate = new Date(year, 0, 1); // January 1st
  const endDate = new Date(year, 11, 31); // December 31st

  // Convert holiday dates to Date objects for comparison
  const holidayDates = holidays.map(holiday => new Date(holiday.date).toDateString());

  // Loop through each day of the year
  for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
      const currentDay = date.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
      const dateStr = date.toDateString();

      // Check if it's a Sunday or a holiday
      if (currentDay !== 0 && !holidayDates.includes(dateStr)) {
          totalWorkingDays++;
      }
  }

  return totalWorkingDays;
}