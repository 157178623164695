import React from "react";
import { Link } from "react-router-dom";
import config from "../../config";

const ImageModal = ({handleCloseModal , photoPreviewUrl , employeeData}) => {
  return (
    <div className="modal-overlay modal-overlay-handle" onClick={handleCloseModal}>
    <span className="close-button close-button-handler" onClick={handleCloseModal}>
       &times;
     </span>
   <div className="modal-content modal-content-handler" onClick={(e) => e.stopPropagation()}>
    
     <img
       className="modal-image modal-image-handler"
       src={
         photoPreviewUrl
           ? photoPreviewUrl
           : `${config.IMAGE_URL}${employeeData}`
       }
       alt="Employee"
     />
   </div>
 </div>
  );
};

export default ImageModal;
