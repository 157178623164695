import React, { useEffect, useState } from "react";
import Select from "react-select";
import { companyID } from "../../auth";
import axios from "axios";
import config from "../../config";
import { Link, useLocation, useNavigate } from "react-router-dom";

const AddDesingnationModelPopup = ({
  designation,
  company_id,
  filteredData,
}) => {
  const [departmentID, setDepartmentID] = useState("");
  const [designationName, setDesignationName] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [designationERror, setDesignationError] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  console.log("designation /name : ", filteredData);
  // useEffect(()=>{
  //   if(filteredData[0]?.designation_id){
  //     setDepartmentID(filteredData[0]?.designation_id);
  //     setDesignationName(filteredData[0].designation_name)
  //   }
  // },[filteredData])

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const submitDesignation = async (e) => {
    e.preventDefault();
    if (!departmentID) {
      setDesignationError("");
      setDepartmentError("*Please Enter Designation NAme !");
      return;
    }
    if (!designationName) {
      setDepartmentError("")
      setDesignationError("*Please Select the Department Name !");
      return;
    }
    setDesignationError("");
    setDepartmentError("")
    try {
      const res = await axios.post(
        `${config.API_BASE_URL}department/addDesignation/${company_id}`,
        { departmentID, designationName }
      );
      console.log("response from designation", res);
      if (res?.data?.success === true) {
        navigate("../designations");
        setDesignationName("");
        setDepartmentID("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateDesignationHandler = async (e) => {
    e.preventDefault();
    const department_id = departmentID
      ? departmentID
      : filteredData[0]?.department_id;
    const designation_name = designationName
      ? designationName
      : filteredData[0]?.designation_name;
    const designation_id = filteredData[0]?.designation_id;
    console.log(
      "department_id",
      department_id,
      "designation_name",
      designation_name,
      "designation_id",
      designation_id
    );
    if (!department_id) {
      setDepartmentError("Department is Required !");
      setDesignationError("");
      return;
    }
    if (!designation_name) {
      setDepartmentError("");
      setDesignationError("Designation is Required!");
      return;
    }
    setDepartmentError("");
    setDesignationError("")
    try {
      console.log("runinhp/.........................");
      const res = await axios.put(
        `${config.API_BASE_URL}department/designation-update`,
        { department_id, designation_name, designation_id }
      );
      console.log("response from Updated  designation", res);
      if (res?.data?.success === true) {
        navigate("../designations");
        setDesignationName("");
        setDepartmentID("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div
        id="add_designation"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Designation</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={submitDesignation}>
                <div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Department <span className="text-danger">*</span>
                    </label>

                    <Select
                      options={designation}
                      placeholder="Select Department"
                      styles={customStyles}
                      onChange={(department) =>
                        setDepartmentID(department.value)
                      }
                    />
                    {departmentError && (
                      <span className="text-danger">{departmentError}</span>
                    )}
                  </div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Designation Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      value={designationName}
                      onChange={(e) => setDesignationName(e.target.value)}
                    />
                    {designationERror && (
                      <span className="text-danger">{designationERror}</span>
                    )}
                  </div>

                  <div className="submit-section">
                    <button
                      className="btn btn-primary submit-btn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        id="edit_designation"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Designation</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={updateDesignationHandler}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Department <span className="text-danger">*</span>
                  </label>

                  {/* <Select
                    options={designation}
                    placeholder="Select Department"
                    styles={customStyles}
                    value={departmentID ? departmentID : filteredData[0]?.departmentID}
                    onChange={(department)=>setDepartmentID(department.value)}
                  /> */}

                  <select
                    className="form-select"
                    styles={customStyles}
                    placeholder="Select"
                    name="department"
                    value={
                      departmentID
                        ? departmentID
                        : filteredData[0]?.department_id
                    }
                    onChange={(e) => {
                      setDepartmentID(e.target.value);
                    }}
                  >
                    <option value="">Select Department</option>
                    {designation.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  {departmentError && (
                    <span className="text-danger">{departmentError}</span>
                  )}
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Designation Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={
                      designationName
                        ? designationName
                        : filteredData[0]?.designation_name
                    }
                    onChange={(e) => setDesignationName(e.target.value)}
                  />
                  {departmentError && (
                    <span className="text-danger">{departmentError}</span>
                  )}
                </div>

                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDesingnationModelPopup;
