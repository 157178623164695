import React, { useState } from "react";
import config from "../config";
import ImageCropper from "./ImageCropper";
import "./ImageEditor.css";
import { FiArrowLeft } from "react-icons/fi";
import { RiImageAddLine } from "react-icons/ri";

function ImageEditor({
  photo,
  dummyPhoto,
  setIsMainModalOpen,
  isMainModalOpen,
  employeeId,
  setEditedTrue,
  editTrue,
}) {
  //   const [isMainModalOpen, setIsMainModalOpen] = useState(true);
  const [isAddImageModalOpen, setIsAddImageModalOpen] = useState(false);
  const [isCropView, setIsCropView] = useState(true);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showUploadOptions, setShowUploadOptions] = useState(false);

  const handleAddImageClick = () => {
    setIsMainModalOpen(false);
    setIsAddImageModalOpen(true);
    setIsCropView(true);
    setShowUploadOptions(true);
  };

  const handleBackClick = () => {
    setIsCropView(true);
    setCroppedImage(null);
  };

  const handleCloseAddImageModal = () => {
    setIsAddImageModalOpen(false);
    // setIsMainModalOpen(true);
    setIsMainModalOpen(false);
    setCroppedImage(null);
  };

  const handleBackButtonAddImageModal = () => {
    setIsAddImageModalOpen(false);
    setIsMainModalOpen(true);
    // setIsMainModalOpen(false);
    setCroppedImage(null);
  };

  return (
    <>
      {isMainModalOpen && (
        <div
          className="modal show"
          id="staticBackdrop"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          style={{ display: "block", background: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Profile Picture
                </h5>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  aria-label="Close"
                  onClick={() => {
                    setIsMainModalOpen(false);
                  }}
                  style={{ borderRadius: "50%" }}
                >
                  X
                </button>
              </div>
              <div className="modal-body d-flex justify-content-center">
                <img
                  style={{
                    height: "280px",
                    width: "280px",
                    borderRadius: "50%",
                  }}
                  src={photo ? `${config.IMAGE_URL}${photo}` : dummyPhoto}
                  alt="Profile"
                />
              </div>
              <div
                className="modal-footer d-flex justify-content-center"
                style={{ borderTop: "0px" }}
              >
                <button
                  type="button"
                  className="btn btn-primary d-flex flex-grow-1 gap-2 justify-content-center"
                  onClick={handleAddImageClick}
                >
                  <RiImageAddLine size={22} />
                  Add new profile picture
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {isAddImageModalOpen && (
        <div
          className="modal show"
          id="addImageModal"
          tabIndex="-1"
          aria-labelledby="addImageModalLabel"
          aria-hidden="true"
          style={{ display: "block", background: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header d-flex justify-space-between">
                <FiArrowLeft
                  size={24}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!showUploadOptions && isCropView) {
                      setShowUploadOptions(true);
                    } else if (!isCropView) {
                      handleBackClick();
                    } else if (isCropView && showUploadOptions) {
                      handleBackButtonAddImageModal();
                    }
                  }}
                />
                <h5 className="modal-title" id="addImageModalLabel">
                  {showUploadOptions
                    ? "Upload a profile picture"
                    : isCropView
                    ? "Crop profile picture"
                    : "Preview profile picture"}
                </h5>
                {/* {!isCropView && (
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={handleBackClick}
                  >
                    Back
                  </button>
                )} */}
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  aria-label="Close"
                  onClick={handleCloseAddImageModal}
                  style={{ borderRadius: "50%" }}
                >
                  X
                </button>
              </div>
              <div className="modal-body">
                <ImageCropper
                  setIsCropView={setIsCropView}
                  isCropView={isCropView}
                  setCroppedImage={setCroppedImage}
                  croppedImage={croppedImage}
                  employeeId={employeeId}
                  setEditedTrue={setEditedTrue}
                  editTrue={editTrue}
                  setShowUploadOptions={setShowUploadOptions}
                  showUploadOptions={showUploadOptions}
                />
              </div>
              {/* <div className="modal-footer d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary flex-grow-1"
                  onClick={handleAddImageClick}
                >
                  Preview profile
                </button>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ImageEditor;
