import React from "react";
import { Link, useNavigate } from "react-router-dom";

const FindCompanyModal = ({findCompanyName , setDoneNavigation}) => {
    const navigate = useNavigate();

    const handleUrlNavigation  = () =>{
        setDoneNavigation(true);
        navigate(`../${findCompanyName}`)
    }
  return (
    <>
      {/* Delete Performance Indicator Modal */}
      <div className="modal custom-modal fade" id="find_company" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header">
                <h3>This Is Your Company URL -</h3>
                <p>Are you sure want to Continue ?</p>
                <h5>{window.location.host}/{findCompanyName?findCompanyName :"" }</h5>
              </div>
              <div className="modal-btn delete-action">
                <div className="row">
                  <div className="col-6">
                    <span className="btn btn-primary continue-btn"  data-bs-dismiss="modal" onClick={handleUrlNavigation}>
                      Continue
                    </span>
                  </div>
                  <div className="col-6">
                    <Link
                      to="#"
                      data-bs-dismiss="modal"
                      className="btn btn-primary cancel-btn"
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Delete Performance Indicator Modal */}
    </>
  );
};

export default FindCompanyModal;
