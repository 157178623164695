import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ element: Component, ...rest }) => {
  const token = localStorage.getItem("token");
  console.log("token in private route", token);

  return token ? <Component {...rest} /> : <Navigate to="/" />;
};

export default PrivateRoute;