import React, { useEffect, useState } from "react";
import { Select, Table } from "antd";
import "../assets/css/paySlips.css";
import { Link } from "react-router-dom";
import { getAllIncreament } from "../services/myPayServices";
import { formatDateInTimezone } from "../utils/formatDateInTimezone";

const { Option } = Select;

function PayPackage({ setSalary, setActiveHeader }) {
  const [payPackage, setPayPackage] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log(" payPackage ", payPackage);

  const columns = [
    {
      title: "When was the change?",
      dataIndex: "increment_date",
      key: "increment_date",
    },
    // {
    //   title: "Currency",
    //   dataIndex: "first_name",
    //   key: "first_name",
    // },
    {
      title: "Monthly fixed gross salary",
      dataIndex: "current_salary",
      key: "current_salary",
    },
    {
      title: "Annual Gross salary",
      dataIndex: "annual_salary",
      key: "annual_salary",
    },
    // {
    //   title: "Annual/monthly",
    //   dataIndex: "first_name",
    //   key: "first_name",
    // },

    {
      title: "Gross Proration",
      key: "payslip",
      render: (_, record) => (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            console.log("record record : ", typeof record.current_salary);
            setSalary(Number(record.current_salary.replace(/,/g, "")));
            setActiveHeader("grossProration");
          }}
        >
          <i class="fa-solid fa-list"></i>
        </Link>
      ),
    },
  ];

  const employeeId = localStorage.getItem("employeeID");

  useEffect(() => {
    const fetchLeaves = async () => {
      try {
        setLoading(true);
        const data = await getAllIncreament(employeeId);
        const increament = data.map((increament, index) => ({
          id: index + 1,
          increment_date: formatDateInTimezone(1721388430),
          current_salary: Number(increament.current_salary).toLocaleString(
            "en-IN"
          ), // fix me
          annual_salary: (increament.current_salary * 12).toLocaleString(
            "en-IN"
          ),
        }));
        console.log(" increament ", increament);
        setPayPackage(increament);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLeaves();
  }, []);

  return (
    <>
      <div className="pay-slips">
        <h2>Pay Package</h2>
        <div className="financialYearDropdown">
          <label>Select Financial Year:</label>
          <Select defaultValue="2024-25" style={{ width: 120 }}>
            {/* <Option value="2023-24">2023-24</Option> */}
            <Option value="2024-25">2024-25</Option>
            {/* <Option value="2025-26">2025-26</Option> */}
          </Select>
        </div>
      </div>
      <div className="row">
        <div className="table-responsive">
          <Table dataSource={payPackage} columns={columns} rowKey="" />
        </div>
      </div>
    </>
  );
}

export default PayPackage;
