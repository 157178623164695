// hooks/useCompanyValidation.js

import axios from "axios";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import config from "../config";

const useCompanyValidation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathCompanyName = decodeURIComponent(location.pathname.split('/')[1]);
    console.log("pathCompanyName:->" , pathCompanyName)
    const storedCompany = localStorage?.getItem("companyName");
    const storedCompanyName = storedCompany?.toLowerCase()?.replace(/\s+/g, "-")
    console.log("storedCompanyName:->" , storedCompanyName);
    const token = localStorage.getItem("token");
    const companyName = localStorage.getItem("companyName");
    if( !token || !companyName){
      navigate(`../`);
      return;
    }

    if (token) {
      const expirationTime = localStorage.getItem('token_expiration');
      const currentTime = new Date().getTime();
      if (currentTime > parseInt(expirationTime)) {
        console.log("yes expiration running------>");
        localStorage.removeItem('token');
        localStorage.removeItem('token_expiration');
        localStorage.removeItem('user');
          localStorage.removeItem('invalidUser');
          localStorage.removeItem('companyName');
          localStorage.removeItem('employeeID');
        navigate(`../`);
        return;
      }
    }
    

    if (storedCompanyName && pathCompanyName && storedCompanyName.toLowerCase() !== pathCompanyName.toLowerCase()) {
      console.log("yes this is ruinnighgfhfjh...........................")
      if (!token) {
        console.error("Token not found in local storage.");
        navigate("../");
        return;
      }
      navigate("/error-404");
  
      // const configuration = {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //     CompanyName: companyName,
      //   },
      // };
  
      // axios
      //   .post(`${config.API_BASE_URL}auth/logout`, {}, configuration)
      //   .then((res) => {
      //     console.log("Logout successful:", res.data);
      //     localStorage.removeItem("credencial");
      //     localStorage.removeItem("token");
      //     localStorage.removeItem("companyName");
      //     localStorage.removeItem("employeeID");
      //     localStorage.removeItem("user");
  
      //     navigate("/");
      //   })
      //   .catch((err) => {
      //     console.error("Error during logout:", err);
      //   });
    }
  }, [location, navigate]);
};

export default useCompanyValidation;
