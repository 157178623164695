import React from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../../../../../config';

const SalaryTable = ({ employees }) => {
  const generateSalarySlip = async (employeeId) => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}api/generate-salary-slip/${employeeId}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `salary_slip_${employeeId}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error generating salary slip:', error);
    }
  };

  const columns = [
    {
      title: 'Employees',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Employee ID',
      dataIndex: 'employee_id',
      key: 'employee_id',
    },
    {
      title: 'Official Email',
      dataIndex: 'offical_email',
      key: 'official_email',
    },
    {
      title: 'Joining date',
      dataIndex: 'joining_date',
      key: 'joining_date',
    },
    {
      title: 'Salary',
      dataIndex: 'salary',
      key: 'salary',
    },
    {
      title: 'Designation ID',
      dataIndex: 'designation_id',
      key: 'designation_id',
    },
    {
      title: 'Payslip',
      key: 'payslip',
      render: (_, record) => (
        <Link
          to="#"
          className="btn btn-sm btn-primary"
          onClick={() => generateSalarySlip(record.employee_id)}
        >
          Generate Slip
        </Link>
      ),
    },
  ];

  return <Table dataSource={employees} columns={columns} rowKey="employee_id" />;
};

export default SalaryTable;
